import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IMG01 from "../../../images/user.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faCalendar,
  faCalendarAlt,
  faClock,
  faColumns,
  faComment,
  faEquals,
  faFileMedicalAlt,
  faFilePrescription,
  faListAlt,
  faSignOutAlt,
  faUser,
  faUserCog,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import SSEHandler from "../../../lib/SSEHandler";
import WebrtcCallHandler from "../../../lib/WebrtcCallHandler";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  authentication,
  bookAppointmentDoctor,
  callbackAppointment,
  loadWhichVersion,
  selectAppointmentType,
  selectedService,
  selectPaymentProvider,
  setProblem,
  setUser,
} from "../../../redux/actions/userActions";
import * as AuthService from "../../../services/auth";
import store, { history } from "store";
import { UPDATEDPOST } from "services/common.api";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


export const DashboardSidebar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer.user);
  const [userInfo, setUserInfo] = useState({});
  const pathname = window.location.pathname;

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  const logout = () => {
     AuthService.logout();
     store.clearAll();
    dispatch(authentication(false));
    WebrtcCallHandler.getInstance().cleanUp();
    SSEHandler.getInstance().cleanUp();
    dispatch(setUser([]));
    // dispatch(selectPaymentProvider([]));
    // dispatch(selectAppointmentType([]));
    // dispatch(bookAppointmentDoctor([]));
    // dispatch(selectedService([]));
    // dispatch(loadWhichVersion([]));
    // dispatch(callbackAppointment([]));
    // dispatch(setProblem([]));
  };

  return (
    <div className="profile-sidebar" style={{ minWidth: 200 }}>
      {/* <div className={props.title ? 'nav-menu active' : 'nav-menu'}> */}
      <div>
        {/* <div className="widget-profile pro-widget-content">
          <div className="profile-info-widget">
            <a href="#0" className="booking-doc-img">
              {user?.profilepicture != "" && user?.profilepicture != null ? (
                <img
                  className="userProfile"
                  src={user.profilepicture}
                  alt="User"
                />
              ) : (
                <img src={IMG01} alt="User" />
              )}
            </a>
            <div className="profile-det-info">
              <h3>
                {userInfo?.FirstName} {userInfo?.LastName}
              </h3>
            </div>
          </div>
        </div> */}
        <div>
          <div className="dashboard-widget heightDiv">
            <nav className="dashboard-menu" style={{fontSize:16}}>
              <ul>

                <li className={pathname.includes("/dashboard") ? "active_sidebar" : ""}>
                  <Link to="/dashboard">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faColumns} color={pathname.includes("/dashboard") ? "white" : ""} />
                    </i>
                    <span>Dashboard </span>
                  </Link>
                </li>

                <li
                  className={
                    pathname.includes("/medicaldDetails") ? "active_sidebar" : ""
                  }
                >
                   <Link to="/medicaldDetails">
                    <i>
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faFileMedicalAlt}
                        color={pathname.includes("/medicaldDetails") ? "white" : ""}
                      />
                    </i>
                    <span>Medical History</span>
                  </Link> 
                </li>

                <li className={pathname.includes("/dependent") ? "active_sidebar" : ""}>
                  <Link to="/dependent">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faUsers}
                        color={pathname.includes("/dependent") ? "white" : ""}
                      />
                    </i>
                    <span>Family member</span>
                  </Link>
                </li>

                {/* <li className={pathname.includes("/event") ? "active_sidebar" : ""}>
                  <Link to="/event">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faCalendarAlt} color={pathname.includes("/event") ? "white" : ""} />
                    </i>
                    <span>Events</span>
                  </Link>
                </li> */}

                {/* <li className={pathname.includes("/workshop") ? "active_sidebar" : ""}>
                  <Link to="/workshop">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faCalendarAlt} color={pathname.includes("/workshop") ? "white" : ""} />
                    </i>
                    <span>Event</span>
                  </Link>
                </li> */}

                {/* <li className={pathname.includes("/orders") ? "active_sidebar" : ""}>
                  <Link to="/orders">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faListAlt} color={pathname.includes("/orders") ? "white" : ""} />
                    </i>
                    <span>Billing</span>
                  </Link>
                </li> */}

<li
                  className={
                    pathname.includes("/prescription") ? "active_sidebar" : ""
                  }
                >
                  <Link to="/prescription" >
                    <i>
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faFilePrescription}
                        color={pathname.includes("/[prescription]") ? "white" : ""}
                      />
                    </i>
                    <span>Prescriptions</span>
                  </Link>
                </li>

                <li
                  className={pathname.includes("/view-profile") ? "active_sidebar" : ""}
                >
                  <Link to="/view-profile">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faUserCog} color={pathname.includes("/view-profile") ? "white" : ""} />
                    </i>
                    <span>Profile Settings</span>
                  </Link>
                </li>
                

                

                <li
                  className={
                    pathname.includes("/vitals") ? "active_sidebar" : ""
                  }
                >
                  {/* <Link to="/vitals" onClick={ (event) => event.preventDefault() }>  */}

                  {/* <Link to="/vitals" >
                    <i>
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faFileMedicalAlt}
                        color={pathname.includes("/vitals") ? "white" : ""}
                      />
                    </i>
                    <span>Vitals</span>
                  </Link> */}
                  
                </li>

                

                {/* <li
                  className={
                    pathname.includes("/securitypin") ? "active_sidebar" : ""
                  }
                > */}
                  {/* <Link to="/securitypin" >
                    <i>
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faFilePrescription}
                        color={pathname.includes("/[securitypin]") ? "white" : ""}
                      />
                    </i>
                    <span>Set Security Pin</span>
                  </Link> */}
                {/* </li> */}

                {/* <li
                  className={
                    pathname.includes("/medicaldDetails") ? "active_sidebar" : ""
                  }
                >
                  <Link to="/chatDoctor">
                    <i>
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faComment}
                        color={pathname.includes("/chatDoctor") ? "white" : ""}
                      />
                    </i>
                    <span>Chat</span>
                  </Link>
                </li> */}
                <li
                  className={
                    pathname.includes("/ChangePassword") ? "active_sidebar" : ""
                  }
                >
                  <Link to="/ChangePassword">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faClock} color={pathname.includes("/ChangePassword") ? "white" : ""} />
                    </i>
                    <span>Change Password</span>
                  </Link>
                </li>

                <li onClick={logout}>
                  <Link to="/">
                    <i>
                      <FontAwesomeIcon className="mr-1" icon={faSignOutAlt} />
                    </i>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardSidebar;
