/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { TopBar } from "components";
import { DashboardSidebar } from "./sidebar/sidebar.jsx";
import { Tab, Tabs, Button } from "react-bootstrap";
import EyeIcon from "../../images/eye_new.png";
import handshake from "../../images/handshake (1).png";
import moment from "moment";
import {
  Spin,
  Modal,
  notification,
  Table,
  Space,
  Drawer,
  Empty,
  Tooltip,
  Tag,
  Typography,
  Button as Buttons,
} from "antd";
import { useHistory } from "react-router-dom";
import { MessageOutlined, EyeFilled, EyeOutlined } from '@ant-design/icons'
import Footer from "components/footerFix";
import style from "./style.module.css";
import SSEHandler from "../../lib/SSEHandler";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import { IMG01, IMG011, IMG012, RINGER } from "./img";
import IMG02 from "../../images/user.png";
import Man from "../../images/man.png";
import Woman from "../../images/woman.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CommentOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCross,
  faEquals,
  faEye,
  faFile,
  faFileAlt,
  faFileInvoiceDollar,
  faNotesMedical,
  faShare,
  faVideo,
  faArrowRight,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { PlusOutlined } from "@ant-design/icons";
import { RemoveRedEyeOutlined } from "@material-ui/icons";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, FileIcon } from "@react-pdf-viewer/default-layout";
import { base64toBlob } from "../../utils/common";
import PatientDetail from "../../components/patientDetail";
import { AppointmentRequestType, setAppConfig, setChatHead, setChatUserData, setCurrentChat, setFamilyDoctor, setInsurance, setProfileComplate, setSelectedFamilyMember } from "../../redux/actions/userActions";
import AddFamilyMember from "components/addFamilyMember";
import { v4 as uuid } from "uuid";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import * as queries from '../../graphql/queries';
import awsconfig from '../../aws-exports';
import animated from "@amcharts/amcharts4/themes/animated.js";
Amplify.configure(awsconfig);

const Dashboard = () => {
  // All For chat
  const { Text, Title } = Typography;

  const messagesEndRef = useRef(null)
  const [allMessages, setAllMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [chatData, setCurrentChatData] = useState({} as any);
  const [isSending, setIsSending] = useState(false);
  const selectedChatHead = useSelector((state: any) => state.chat.chathead);
  const selectedChatUser = useSelector((state: any) => state.chat.chatuser);
  const [conversations, setConversations] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin="anonymous"

  const history = useHistory();
  const dispatch = useDispatch();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderFamily, setLoaderFamily] = useState(false);
  const [priscriptionsDetails, setPriscriptionsDetails] = useState([] as any);
  const [eventWorkshop, setEventWorkshop] = useState([] as any);
  const [message, setMessage] = useState(null);
  const [notifications, setNotification] = useState(null);
  const [particluarEvent, setParticularEvent] = useState({} as any);
  const [eventModalVisible, setEventModalVisisble] = useState(false);
  const [callBackDetails, setCallBackDetails] = useState([]);
  const [callBackSchedule, setCallBackSchedule] = useState([]);

  const [selectAppointmentId, setSelectAppointmentId] = useState({} as any);
  const [doctorList, setdoctorList] = useState([] as any);
  const [previousDoctorList, setPreviousDoctorList] = useState([] as any);
  const [PdfString, setPdfString] = useState("");
  // const [prescription, setPrescription] = useState([]);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [type, setType] = useState();
  const [selectedCard, setSelectedCard] = useState("CallBack");
  const [familyMembers, setFamilyMembers] = useState([] as any);
  const [showFamily, setShowFamily] = useState(false);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState({} as any);
  const [familyModel, setFamilyModel] = useState(false);
  const [click, setClick] = useState(false);
  const [addClick, setAddClick] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMemberr] = useState(null);
  const [noteModal, setNoteModal] = useState(false);
  let user = useSelector((state: any) => state.userReducer.user);
  const [pdfUrl, setPdfUrl] = useState(null)
  const [isOpenPdfModal, setIsOpenPdfModal] = useState(false)

  const [subscription, setSubscription] = useState(null)
  const userData = useSelector((state: any) => state.userReducer.user);
  const [userAgent, setUserAgent] = useState('');
  console.log('userData)&&^^&%^%^', userData);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY', userAgent);
    setUserAgent(userAgent);
  }, []);

  let config = useSelector((state: any) => state.AppConfig.config);
  const isProfileComplate = useSelector(
    (state: any) => state.userReducer.IsProfileComplate
  );
  const currentAppointmentState: any = useSelector(
    (state: any) => state?.curentAppointment
  );
  const [showEdit, setShowEdit] = useState(false);



  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3';
    audio.play()
      .catch(error => {
        // Handle any playback errors
        console.log(error, "audio play error");
      });
  }

  useEffect(() => {

    scrollToBottom()
  }, [allMessages]);

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch (e: any) {
      console.log(e, "ERRORRR")
    }
  }


  const getConversations = async (value) => {
    let rawlogs = {
      "userIdentity": {
        "loggedinuser": `${userData?.Email}`,
        "role": `${userData?.role}`,
        "createdate": new Date().toISOString()
      },
      "action": "ACCESS",
      "source": "Dashboard Page",
      "sourceIPAddress": "3.99.184.26",
      "userAgent": userAgent,
      "ipAddress": localStorage.getItem('ipAddress'),
      "apiendpoint": ``,
      "requestParameters": {

      },
      // "responsestatus": `${changedPasswordResponse?.status}`,
      // "response": JSON.stringify(response?.data)
    }

    let body = {
      // message: `User - ${userData.id} (${userData?.sub}) - Fetch Chat Conversation - Dashboard Page`,
      message: rawlogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Dashboard Page`,
    }
    logEvents(body);

    setAllMessages([]);
    let convo;
    console.log("OKOK CHE STARTED",  value?.appointment_id, value?.id);
    convo = await API.graphql({
      query: queries.getConversationById,
      // variables: { id: selectedChatUser?.id }
      variables: { id: value?.appointment_id || value?.id }
      // variables: { id: value?.patientid }
    });

    console.log("OKOK CHE");
    console.log(convo);
    console.log("OKOK CHE OK NAI");

    if (Array.isArray(convo?.data?.getConversationById) && convo?.data?.getConversationById.length !== 0) {
      console.log("convo?.data?.getConversationById", convo?.data?.getConversationById);
      getMessagesByConversation(convo?.data?.getConversationById[0]?.id);
      dispatch(setChatHead(convo?.data?.getConversationById[0]));
      message?.unsubscribe();
      getLiveMessage(convo?.data?.getConversationById[0]?.id);
      setConversations(convo?.data?.getConversationById);
    } else {
      createConversation(value);
    }
  }
  const createConversation = async (value) => {
    console.log(value, "value value")
    const conversionObj = {
      createdAt: new Date(),
      doctor: value?.doctorid ? value?.doctorid : value?.providerid,
      doctorName: value?.providername,
      id: value?.appointment_id ? value?.appointment_id : value?.id,
      patient: value?.patientid,
      patientName: value?.patientname,
    }

    const conversationItem = await API.graphql(
      graphqlOperation(mutations.createConversation, conversionObj),
    )

    console.log(conversationItem['data'], "conversation item Che Ashi")
    message?.unsubscribe();
    getMessagesByConversation(conversationItem['data'].createConversation?.id);
    dispatch(setChatHead(conversationItem['data']?.createConversation));
    getLiveMessage(conversationItem['data']?.createConversation?.id);
  }

  console.log('callBackSchedule1111', callBackSchedule);


  const getLiveMessage = async (id) => {
    console.log(id, "id id id id")
    const subscription = (API.graphql(
      graphqlOperation(subscriptions.subscribeToNewMessage, { conversationId: id })
    ) as any).subscribe((eventData) => {
      console.log(eventData, "evevevevevveveveentntnntntnt", allMessages);

      if (eventData?.value?.data?.subscribeToNewMessage?.author != user?.id) {
        // openNotification()
        // audio.play();
        playAudio()
      }

      setAllMessages(allMessages => [...allMessages, eventData?.value?.data?.subscribeToNewMessage]);
    });

    console.log(subscription);

    setMessage(subscription);
  }

  // const getLiveNotification = async (id) => {
  //   console.log(id, "id id id id")
  //   const subscription = (API.graphql(
  //     graphqlOperation(subscriptions.subscribeToNewNotification, { userId: id })
  //   ) as any).subscribe((eventData) => {
  //     console.log(eventData,"notification reserv");

  //     //  openNotification()
  //     if(parseInt(eventData?.value?.data?.subscribeToNewNotification?.conversationId) == parseInt(localStorage.getItem("chatHead"))){}else{
  //       notification.open({
  //         message: 'New Message',
  //         description: eventData?.value?.data?.subscribeToNewNotification?.message,
  //         duration: 5, // Set duration to 0 to keep the notification open until manually closed
  //         icon: <MessageOutlined style={{ color: '#1890ff' }} />,
  //         placement: 'topRight',
  //       });
  //     }
  //     //  audio.play();

  //   });

  //   console.log(subscription,"notification ");
  //   setNotification(subscription);

  // }

  const getTime = (timestampp) => {
    return moment(timestampp).format("hh:mm a");
  }

  const getMessagesByConversation = async (cid) => {
    console.log(cid, "conversation id")
    let messages;
    messages = await API.graphql({
      query: queries.allMessage,
      variables: { conversationId: cid }
    });

    console.log("Message", messages?.data?.allMessage);
    setAllMessages(messages?.data?.allMessage);
  }

  const sendMessage = async (e) => {

    console.log(chatData, "chatData chatData")

    console.log(selectedChatHead, "selectedChatHead")

    e.preventDefault();

    if (isSending || messageInput === '') {
      return;
    }
    setIsSending(true);
    console.log(messageInput, "setMessageInput");

    try {
      const messageObj = {
        author: user?.id,
        authorName: chatData?.patientname,
        // reciver: selectedChatHead?.doctor ,
        authorType: 'PATIENT',
        content: messageInput,
        conversationId: chatData.appointment_id ? chatData.appointment_id : chatData?.id,
        createdAt: new Date(),
        id: uuid()
      }

      console.log(messageObj, "messageObj messageObj 1")
      const messageItem = await API.graphql(graphqlOperation(mutations.createMessage, messageObj)); // equivalent to above example
      console.log(messageItem);

      console.log(chatData?.EmployeeID, "chatData?.EmployeeID chatData?.EmployeeID")

      const notificationObj = {
        userId: chatData?.EmployeeID.toString(),
        message: "You have a new message from " + `${user.FirstName}` + "",
        createdAt: new Date(),
        conversationId: chatData.appointment_id ? chatData.appointment_id : chatData?.id
      }

      console.log(notificationObj, "notificationObj notificationObj")

      const notificationItem = await API.graphql(graphqlOperation(mutations.createNotification, notificationObj)); // equivalent to above example
      console.log(notificationItem, "notificationItem notificationItem");

      let messagesAll: any;
      messagesAll = await API.graphql({
        query: queries.allMessage,
        variables: { conversationId: chatData.appointment_id ? chatData.appointment_id : chatData?.id }
      });


      if (messagesAll?.data?.allMessage?.length !== 0) {
        if (messagesAll?.data?.allMessage?.length == 1 && messagesAll?.data?.allMessage[0].authorType == 'PATIENT') {

          // console.log("if if if if if if","Thanks for reaching out to " + `${currentAppointmentState?.bookAppointmentDoctor?.doctor?.clinicName}` + " someone from the clinic will get back to you with details in some time")

          // let Name =`${currentAppointmentState?.bookAppointmentDoctor?.doctor?.FirstName}  ${currentAppointmentState?.bookAppointmentDoctor?.doctor?.LastName}`

          const messageObj = {
            author: chatData?.EmployeeID,
            authorName: chatData?.providername,
            // reciver: selectedChatHead?.doctor ,
            authorType: 'DOCTOR',
            content: "Thanks for reaching out to " + `${chatData?.clinicname}` + " someone from the clinic will get back to you with details in some time",
            conversationId: chatData.appointment_id ? chatData.appointment_id : chatData?.id,
            createdAt: new Date(),
            id: uuid()
          }

          console.log(messageObj, "messageObj messageObj 2")
          const messageItem = await API.graphql(graphqlOperation(mutations.createMessage, messageObj));

        }
      }



      setMessageInput('');
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsSending(false);
    }
  }


  const getFamilyMember = async () => {



    try {
      setLoaderFamily(true);
      const data = await UPDATEDGET(`userfamilymembers?id=${user.id}`);
      console.log(data, "FamilyMembers_)_)_)_");

      let rawlogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `userfamilymembers?id=${user.id}`,
        "requestParameters": {

        },
        "responsestatus": `${data?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Family Members of Logged In User - Dashboard Page`,
        message: rawlogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);

      if (data?.data?.body?.FamilyMembers) {
        setLoaderFamily(true);
        setFamilyMembers(data?.data?.body?.FamilyMembers);
        setLoaderFamily(false);
      } else {
        setLoaderFamily(true);
        setFamilyMembers([]);
        setLoaderFamily(false);
      }
    } catch (err) {
      setLoaderFamily(false);
      console.log("err: ", err);
    }
  };

  const getAllEventAndWorkShops = async () => {




    try {
      const eventData = await UPDATEDGET(`events`);
      console.log("eventData: ", eventData);

      let rawlogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `events`,
        "requestParameters": {

        },
        "responsestatus": `${eventData?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Events & Workshops - Dashboard Page`,
        message: rawlogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);

      setEventWorkshop(eventData.data.body);
    } catch (error) {
      console.log(error);
    }
  };

  const patientDetailClose = () => {
    setIsDetailVisible(false);
  };

  const addFamilyModelOpen = () => {
    setFamilyModel(true);
    setSelectedFamilyMemberr({});
    setAddClick(true);
  };

  const familyModelClose = () => {
    setFamilyModel(false);
  };

  const getFamilyDoctor = () => {



    try {
      UPDATEDGET(`patient/familydoc/${user.id}`).then((response) => {

        let rawlogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role": `${userData?.role}`,
            "createdate": new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "Dashboard Page",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `patient/familydoc/${user.id}`,
          "requestParameters": {

          },
          "responsestatus": `${response?.status}`,
          // "response": JSON.stringify(response?.data)
        }


        let body = {
          // message: `User - ${userData.id} (${userData?.sub}) - Fetch Family Doctor - Dashboard Page`,
          message: rawlogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Dashboard Page`,
        }
        logEvents(body);

        console.log(
          "family member...: ",
          response?.data?.body?.CompanyEmployee?.CompanyEmployee
        );
        let N = response?.data?.body?.CompanyEmployee?.CompanyEmployee;
        if (N !== null && N !== "") {
          if (N?.FirstName !== undefined && N?.LastName !== undefined) {
            dispatch(setFamilyDoctor(`Dr. ${N?.FirstName} ${N?.LastName}`));
          } else {
            dispatch(setFamilyDoctor(""));
          }
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };


  useEffect(() => {

    if (user.id !== undefined) {
      getDoctortList({ showloader: false }, user?.id);
      getpriscriptionList(user.id);
      getcallBackList(user.id);
      getFamilyMember();
      getFamilyDoctor();
      getAppConfig();
      getAllEventAndWorkShops();
      getInsuranceDetailForUser();
    }
    // setNoteModal(true)
    setNoteModal(true);

    // notifications?.unsubscribe();
    // getLiveNotification(user.id + "")



  }, [user.id]);


  useEffect(() => {
    if (user?.id) {
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }

        const newSubscription = (API.graphql(graphqlOperation(subscriptions.subscribeToNewNotification, { userId: user?.id + "" })) as any).subscribe({
          next: (notificationData) => {
            const newNotification = notificationData.value.data.subscribeToNewNotification;
            console.log('New notification received:', newNotification);

            console.log(localStorage.getItem("chatHead"), "localStorage.getItem", newNotification?.conversationId);
            if (parseInt(newNotification?.conversationId) == parseInt(localStorage.getItem("chatHead"))) { } else {
              notification.open({
                message: 'New Message',
                description: newNotification?.message,
                duration: 5,
                icon: <MessageOutlined style={{ color: '#1890ff' }} />,
                placement: 'topRight',
              });
              // audio.play();
              playAudio()

            }

          },
          error: (error) => {
            console.log(user)
            console.error('Error subscribing to new notification:', error);
          },
        });

        setSubscription(newSubscription);
      };

      subscribeToNotifications();

      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null);
        }
      };
    }

  }, [subscription, user?.id]);

  const getAppConfig = async () => {



    try {
      await UPDATEDGET("config").then((response) => {
        let rawlogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role": `${userData?.role}`,
            "createdate": new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "Dashboard Page",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `config`,
          "requestParameters": {

          },
          "responsestatus": `${response?.status}`,
          // "response": JSON.stringify(response?.data)
        }

        let body = {
          // message: `User - ${userData.id} (${userData?.sub}) - Fetch Appointment Configuration - Dashboard Page`,
          message: rawlogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Dashboard Page`,
        }
        logEvents(body);
        console.log("config...: ", response?.data[0]);
        dispatch(setAppConfig(response?.data[0]));

        setShowEdit(true);
      });
    } catch (error) {
      setShowEdit(false);
      notification.error({
        message: error.message,
      });
    }
  };



  useEffect(() => {

    // if (store.get('auth_token_exp') != null) {

    //   const date = moment.unix(store.get('auth_token_exp'))
    //   const now = moment()
    //   const end = date

    //   const duration = moment.duration(end.diff(now))
    //   console.log(duration.asMinutes(), 'durationnnn')

    //   if (duration.asMinutes() <= 0) {
    //     AuthService.GetAuthenticationToken().then((res) => {
    //       console.log(res, "token res in deshboard first time")
    //       store.set('auth_token_refresh', res?.idToken?.jwtToken);
    //       store.set('auth_token_exp', res?.idToken?.payload?.exp);
    //     })
    //   }
    // }
    localStorage.setItem("chatHead", null);

    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    SSEHandler.getInstance().eventEmitter.on("updatedAppointmentList", () => {
      console.log("appointment updated successfully");
      getDoctortList({ showloader: false }, user?.id);
    });
    message?.unsubscribe();
    // getLiveMessage();

    window.scrollTo(0, 0);
  }, []);



  const getpriscriptions = (item, type) => {
    console.log("btn click", item, type);
    setSelectAppointmentId(item);
    setType(type)
    setIsDetailVisible(true);
  };

  const getpriscriptionList = (id) => {



    async function fetchMyAPI() {
      console.log(`patientprescriptions?patientid=` + id, "getpriscriptionList ma id check")
      var prescription = await UPDATEDGET(`patientprescriptions?patientid=` + id);

      let rawlogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `patientprescriptions?patientid=` + id,
        "requestParameters": {

        },
        "responsestatus": `${prescription?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Prescriptions - Dashboard Page`,
        message: rawlogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);

      console.log(prescription?.data, "prescription list data .........");

      if (prescription?.data) {
        //   var finalPresctiptions = prescription?.data.reduce((unique, o) => {
        //   if(!unique.some(obj => obj.prescription_id === o.prescription_id)) {
        //     unique.push(o);
        //   }
        //   return unique;
        // },[]);
        // console.log(finalPresctiptions, "========");

        // finalPresctiptions?.sort(function (a, b) {
        //     var dateA = undefined;
        //     var dateB = undefined;

        //     const astartdate = moment(a.createdate,"YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");
        //     const bstartdate = moment(b.createdate,"YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");


        //     dateA = new Date(astartdate);
        //     dateB = new Date(bstartdate);
        //     return dateB - dateA
        //   });
        // }
        prescription?.data?.sort(function (a, b) {
          var dateA = undefined;
          var dateB = undefined;

          const astartdate = moment(a.createdate, "YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");
          const bstartdate = moment(b.createdate, "YYYY-MM-DDTHH:mm:ss.SSSS").format("YYYY-MM-DD hh:mm a");


          dateA = new Date(astartdate);
          dateB = new Date(bstartdate);
          return dateB - dateA
        });
        setPriscriptionsDetails(prescription?.data);
        // setPriscriptionsDetails(finalPresctiptions);
      }


    }
    fetchMyAPI();
  };

  // const getcallBackList = async (id) => {
  //   // async function fetchMyAPI() {
  //   const callback = await UPDATEDGET(`callbackrequests/patient/${id}`);
  //   console.log(callback, "callback list")

  //   let allCallback = callback?.data?.body?.data;

  //   var CurrentDate = moment().format('YYYY-MM-DD HH:mm A');
  //   console.log('CurrentDate', CurrentDate);


  //   let callbackdata = []
  //   let callbackcoming = []

  //   const currentTime = moment().format('YYYY-MM-DD h:mm A');

  //   // allCallback.forEach(record => {
  //   //   const isAssigned = record.callbackstatus === 'ASSIGNED';
  //   //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
  //   //   const isTodayOrTomorrow =
  //   //     moment().isSame(appointmentDate, 'day') ||
  //   //     moment().add(1, 'day').isSame(appointmentDate, 'day');



  //   //   if (isAssigned && isTodayOrTomorrow) {
  //   //     callbackcoming.push(record)
  //   //   } else {
  //   //     callbackdata.push(record)
  //   //   }

  //   // });
  //   const datesBeforeCurrent = [];
  //   const datesAfterCurrent = [];

  //   allCallback.forEach((record) => {
  //     const appointmentDate = moment(record.callbackend, 'YYYY-MM-DD h:mm A');
  //     console.log('appointmentDate', appointmentDate);

  //     const isTodayOrTomorrow =
  //       moment().isSame(appointmentDate, 'day') ||
  //       moment().add(1, 'day').isSame(appointmentDate, 'day');
  //     const currentTimeMoment = moment();
  //     console.log('++++++++', appointmentDate, "---------", currentTimeMoment);


  //     if (appointmentDate.isAfter(currentTimeMoment)) {
  //       callbackcoming.push(record)
  //       console.log("iffffffffffffff 444444", record.callbackend);
  //       console.log("iffffffffffffff 444444-----≥", record);

  //       // datesBeforeCurrent.push(dateTimeString);
  //     } else {
  //       callbackdata.push(record);
  //       console.log("elsee elseee555555555", record.callbackend);

  //       // datesAfterCurrent.push(dateTimeString);
  //     }

  //     console.log(datesAfterCurrent, 'datesAfterCurrent');
  //     console.log(datesBeforeCurrent, 'datesBeforeCurrent');

  //     // if(appointmentDate.isBefore(currentTime)){
  //     //     console.log('ifffffffffff',appointmentDate);

  //     // }else {
  //     //   console.log('ellssssssss',appointmentDate);
  //     // }

  //     // if (isTodayOrTomorrow) {
  //     //   callbackcoming.push(record);


  //     // } else {
  //     //   const isTimeLessThanCurrent = appointmentDate.isSameOrAfter(currentTime);
  //     //   if (isTimeLessThanCurrent) {
  //     //     callbackdata.push(record);

  //     //   }
  //     // }
  //   });
  //   console.log('callbackcoming', callbackcoming);


  //   // allCallback.forEach((record) => {
  //   //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
  //   //   const today = moment().startOf('day');
  //   //   const tomorrow = moment().add(1, 'day').startOf('day');
  //   //   const currentTime = moment();

  //   //   if (appointmentDate.isBetween(today, tomorrow)) {
  //   //     // Appointment is for today or tomorrow
  //   //     callbackcoming.push(record);
  //   //   } else if (appointmentDate.isSameOrBefore(currentTime)) {
  //   //     // Appointment is in the future (beyond tomorrow)
  //   //     callbackdata.push(record);
  //   //   }
  //   // });

  //   // allCallback.forEach(record => {
  //   //   const isAssigned = record.callbackstatus === 'ASSIGNED';
  //   //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
  //   //   const isTodayOrTomorrow =
  //   //     moment().isAfter(appointmentDate, 'hour') 
  //   //     moment().add(1, 'day').isAfter(appointmentDate, 'day');



  //   //   if (isAssigned && isTodayOrTomorrow) {
  //   //     callbackcoming.push(record)
  //   //   } else {
  //   //     callbackdata.push(record)
  //   //   }

  //   // });

  //   // const currentTime = moment(); // Current time
  //   //   var currentTime = moment().set({ 'hour': 18, 'minute': 0, 'second': 0 }).format('YYYY-MM-DD HH:mm:ss');
  //   //   console.log('CurrentDate', currentTime);



  //   // allCallback.forEach((record) => {
  //   //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');

  //   //   // Compare appointment date with current date
  //   //   if (appointmentDate.isSameOrAfter(currentTime,'hour')) {
  //   //     callbackcoming.push(record); // Future or current appointments
  //   //     console.log('callbackcoming===>',callbackcoming);

  //   //   } else {
  //   //     callbackdata.push(record); // Past appointments
  //   //     console.log('callbackData===>',callbackdata);

  //   //   }
  //   // });

  //   // const currentTimeMoment = moment();


  //   // allCallback.forEach((record) => {
  //   //   const appointmentDate = moment(record.callbackend, 'YYYY-MM-DD h:mm A');

  //   //   if (appointmentDate.isAfter(currentTimeMoment)) {
  //   //     callbackcoming.push(record);
  //   //   } else {
  //   //     callbackdata.push(record);
  //   //   }
  //   // });



  //   // // Get the next 6 upcoming appointments
  //   // const upcomingCount = Math.min(callbackcoming.length, 6);
  //   // const upcomingAppointments = callbackcoming.slice(0, upcomingCount);

  //   // // Get the last 6 past appointments
  //   // const pastCount = Math.min(callbackdata.length, 6);
  //   // const pastAppointments = callbackdata.slice(0, pastCount);

  //   // console.log('Upcoming Appointments:', upcomingAppointments);
  //   // console.log('Past Appointments:', pastAppointments);




  //   // setCallBackDetails(pastAppointments);
  //   // setCallBackSchedule(upcomingAppointments);

  //   // setCallBackDetails(callbackdata)
  //   // setCallBackSchedule(callbackcoming)


  //   // }
  //   // fetchMyAPI();
  // };


  const getcallBackList = async (id) => {



    // async function fetchMyAPI() {
    const callback = await UPDATEDGET(`callbackrequests/patient/${id}`);
    console.log(callback, "callback list")

    let rawlogs = {
      "userIdentity": {
        "loggedinuser": `${userData?.Email}`,
        "role": `${userData?.role}`,
        "createdate": new Date().toISOString()
      },
      "action": "ACCESS",
      "source": "Dashboard Page",
      "sourceIPAddress": "3.99.184.26",
      "userAgent": userAgent,
      "ipAddress": localStorage.getItem('ipAddress'),
      "apiendpoint": `callbackrequests/patient/${id}`,
      "requestParameters": {

      },
      "responsestatus": `${callback?.status}`,
      // "response": JSON.stringify(response?.data)
    }

    let body = {
      // message: `User - ${userData.id} (${userData?.sub}) - Fetch Callback Appoinment Request - Dashboard Page`,
      message: rawlogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Dashboard Page`,
    }
    logEvents(body);

    let allCallback = callback?.data?.body?.data;

    var CurrentDate = moment().format('YYYY-MM-DD HH:mm A');
    console.log('CurrentDate', CurrentDate);


    let callbackdata = []
    let callbackcoming = []

    const currentTime = moment().format('YYYY-MM-DD h:mm A');

    // allCallback.forEach(record => {
    //   const isAssigned = record.callbackstatus === 'ASSIGNED';
    //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
    //   const isTodayOrTomorrow =
    //     moment().isSame(appointmentDate, 'day') ||
    //     moment().add(1, 'day').isSame(appointmentDate, 'day');



    //   if (isAssigned && isTodayOrTomorrow) {
    //     callbackcoming.push(record)
    //   } else {
    //     callbackdata.push(record)
    //   }

    // });
    const datesBeforeCurrent = [];
    const datesAfterCurrent = [];

    allCallback.forEach((record) => {
      const appointmentDate = moment(record.callbackend, 'YYYY-MM-DD h:mm A');
      console.log('appointmentDate', appointmentDate);

      const isTodayOrTomorrow =
        moment().isSame(appointmentDate, 'day') ||
        moment().add(1, 'day').isSame(appointmentDate, 'day');
      const currentTimeMoment = moment();
      console.log('++++++++', appointmentDate, "---------", currentTimeMoment);


      if (appointmentDate.isAfter(currentTimeMoment)) {
        callbackcoming.push(record)
        console.log("iffffffffffffff 444444", record.callbackend);
        console.log("iffffffffffffff 444444-----≥", record);

        // datesBeforeCurrent.push(dateTimeString);
      } else {
        callbackdata.push(record);
        console.log("elsee elseee555555555", record.callbackend);

        // datesAfterCurrent.push(dateTimeString);
      }

      console.log(datesAfterCurrent, 'datesAfterCurrent');
      console.log(datesBeforeCurrent, 'datesBeforeCurrent');

      // if(appointmentDate.isBefore(currentTime)){
      //     console.log('ifffffffffff',appointmentDate);

      // }else {
      //   console.log('ellssssssss',appointmentDate);
      // }

      // if (isTodayOrTomorrow) {
      //   callbackcoming.push(record);


      // } else {
      //   const isTimeLessThanCurrent = appointmentDate.isSameOrAfter(currentTime);
      //   if (isTimeLessThanCurrent) {
      //     callbackdata.push(record);

      //   }
      // }
    });
    console.log('callbackcoming', callbackcoming);


    // allCallback.forEach((record) => {
    //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
    //   const today = moment().startOf('day');
    //   const tomorrow = moment().add(1, 'day').startOf('day');
    //   const currentTime = moment();

    //   if (appointmentDate.isBetween(today, tomorrow)) {
    //     // Appointment is for today or tomorrow
    //     callbackcoming.push(record);
    //   } else if (appointmentDate.isSameOrBefore(currentTime)) {
    //     // Appointment is in the future (beyond tomorrow)
    //     callbackdata.push(record);
    //   }
    // });

    // allCallback.forEach(record => {
    //   const isAssigned = record.callbackstatus === 'ASSIGNED';
    //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
    //   const isTodayOrTomorrow =
    //     moment().isAfter(appointmentDate, 'hour') 
    //     moment().add(1, 'day').isAfter(appointmentDate, 'day');



    //   if (isAssigned && isTodayOrTomorrow) {
    //     callbackcoming.push(record)
    //   } else {
    //     callbackdata.push(record)
    //   }

    // });

    // const currentTime = moment(); // Current time
    //   var currentTime = moment().set({ 'hour': 18, 'minute': 0, 'second': 0 }).format('YYYY-MM-DD HH:mm:ss');
    //   console.log('CurrentDate', currentTime);



    // allCallback.forEach((record) => {
    //   const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');

    //   // Compare appointment date with current date
    //   if (appointmentDate.isSameOrAfter(currentTime,'hour')) {
    //     callbackcoming.push(record); // Future or current appointments
    //     console.log('callbackcoming===>',callbackcoming);

    //   } else {
    //     callbackdata.push(record); // Past appointments
    //     console.log('callbackData===>',callbackdata);

    //   }
    // });

    // const currentTimeMoment = moment();


    // allCallback.forEach((record) => {
    //   const appointmentDate = moment(record.callbackend, 'YYYY-MM-DD h:mm A');

    //   if (appointmentDate.isAfter(currentTimeMoment)) {
    //     callbackcoming.push(record);
    //   } else {
    //     callbackdata.push(record);
    //   }
    // });



    // // Get the next 6 upcoming appointments
    // const upcomingCount = Math.min(callbackcoming.length, 6);
    // const upcomingAppointments = callbackcoming.slice(0, upcomingCount);

    // // Get the last 6 past appointments
    // const pastCount = Math.min(callbackdata.length, 6);
    // const pastAppointments = callbackdata.slice(0, pastCount);

    // console.log('Upcoming Appointments:', upcomingAppointments);
    // console.log('Past Appointments:', pastAppointments);




    // setCallBackDetails(pastAppointments);
    // setCallBackSchedule(upcomingAppointments);

    setCallBackDetails(callbackdata)
    setCallBackSchedule(callbackcoming)


    // }
    // fetchMyAPI();
  };


  console.log(callBackSchedule, 'callBackSchedule1221212');


  const getDoctortList = (options?: any, id?: any) => {




    console.log(id, "My id");
    async function fetchMyAPI() {
      if (!options || (options && options.showloader)) {
        // setLoader(true);
      }
      setLoader(true);
      const patientlist = await UPDATEDGET(`appointment?patientid=${id}`);
      console.log(patientlist, "new api call dash");
      const AppPatient = patientlist?.data?.Appointments || patientlist?.data || []

      console.log('AppPatient_()()()', AppPatient);

      let rawlogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `appointment?patientid=${id}`,
        "requestParameters": {

        },
        "responsestatus": `${patientlist?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Doctors - Dashboard Page`,
        message: rawlogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);

      if (AppPatient?.hasOwnProperty('errorMessage')) {
        setdoctorList([]);
      } else {
        setLoader(false);
        if (AppPatient?.errorType == "Error") {
          setdoctorList([]);
        } else {

          console.log("else ma avyuyuyuyuyu", patientlist)

          const currentAndFuture = [];
          const pastAppointments = [];
          AppPatient?.forEach((element: any) => {

            console.log("element_)_)_)_)_",element);
            
            const compareDatee = moment(element.startdatetime, "YYYY-MM-DDTHH:mm:ss.SSSS").format("DD-MM-YYYY HH:mm A");
            const compareTodayDate = moment().format("DD-MM-YYYY HH:mm A");

            const todayDate = moment(compareTodayDate, "DD-MM-YYYY HH:mm A");
            const pastDate = moment(compareDatee, "DD-MM-YYYY HH:mm A");
            const dDiff = pastDate.diff(todayDate);

            console.log('Date is not Today', todayDate);
            console.log('Date is not pastDATA', pastDate);
            console.log('Date is not past', dDiff);

            // console.log(element.startdatetime,"element.startdatetime")
            console.log(compareDatee,"compareDateecompareDatee")
            // console.log(compareTodayDate,"compareTodayDatecompareTodayDate")


            // if (dDiff >= 0) {
            //   console.log('Date is not past++++');
              currentAndFuture.push(element);
            // } else {
            //   pastAppointments.push(element);
            // }
          });

          console.log('^^^^^^^^^^^^',currentAndFuture);
          

          currentAndFuture.sort(function (a, b) {
            var dateA = undefined;
            var dateB = undefined;
            dateA = new Date(a.startdatetime);
            dateB = new Date(b.startdatetime);
            // return dateA - dateB
            console.log(a, "dateA-----");
            console.log(b, "dateB-----");
            
            return dateB - dateA
          });

          let walkinData = [];

          let patientId = id ? id : user?.id

          const patientlistAll = await UPDATEDGET(`appointment/walking?patientid=${patientId}`);
          console.log(patientlistAll, "patientlistAllpatientlistAllpatientlistAll");

          let rawlogs = {
            "userIdentity": {
              "loggedinuser": `${userData?.Email}`,
              "role": `${userData?.role}`,
              "createdate": new Date().toISOString()
            },
            "action": "ACCESS",
            "source": "Dashboard Page",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": `appointment/walking?patientid=${id}`,
            "requestParameters": {

            },
            "responsestatus": `${patientlistAll?.status}`,
            // "response": JSON.stringify(response?.data)
          }


          let body = {
            // message: `User - ${userData.id} (${userData?.sub}) - Fetch Walking Appointments - Dashboard Page`,
            message: rawlogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Dashboard Page`,
          }
          logEvents(body);

          // if (patientlistAll?.data.hasOwnProperty('errorMessage')) {
          //   walkinData = pastAppointments;
          // } else {
          //   walkinData = [...pastAppointments, ...patientlistAll?.data];
          // }
          if (patientlistAll?.data.hasOwnProperty('errorMessage')) {
            walkinData = pastAppointments;
          } else if (patientlistAll?.data?.Appointments) {
            walkinData = [...pastAppointments, ...patientlistAll?.data?.Appointments];
          } else {
            walkinData = [...pastAppointments, ...patientlistAll?.data];
          }

          // pastAppointments.sort(function (a, b) {
          //   var dateA = undefined;
          //   var dateB = undefined;
          //   dateA = new Date(a.startdatetime);
          //   dateB = new Date(b.startdatetime);
          //   // return dateA - dateB
          //   return dateB - dateA
          //   // return dateA - dateB
          // });
          let walkinAppointments = walkinData.filter(item => item.appointmenttype === "WALKIN");

          let schedule = currentAndFuture.filter(item => item.requesttype === "SCHEDULED");

          // console.log('walkinAppointments',walkinAppointments);
          
          walkinData.sort(function (a, b) {
            var dateA = undefined;
            var dateB = undefined;
            dateA = new Date(a.startdatetime);
            dateB = new Date(b.startdatetime);
            // return dateA - dateB
            return dateB - dateA
            // return dateA - dateB
          });

          console.log(currentAndFuture, "DOCTOR LIST");
          setdoctorList(schedule);
          console.log(walkinAppointments, "pastconsole.log(currentAndFuture);");
          console.log(currentAndFuture, "currentAndFuture);");
          setPreviousDoctorList(walkinAppointments)
          // setdoctorList(patientlist?.data);
        }
      }
    }
    fetchMyAPI();
  };

  const getDateFormate = (dateData) => {
    let stillUtc = moment.utc(dateData).toDate();
    return moment(stillUtc, ["YYYY-MM-DD h:mm:ss A"]).local().format("h:mm A");
  };

  const showPdf = async (preId) => {


    console.log("preId: ", preId);
    try {
      const paylod = {
        prescriptionId: preId,
      };
      UPDATEDPOST("generatepdf", paylod).then((response) => {
        console.log("response: ", response);

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role": `${userData?.role}`,
            "createdate": new Date().toISOString()
          },
          "action": "INSERT",
          "source": "Dashboard Page",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `generatepdf`,
          "requestParameters": {
            paylod
          },
          "responsestatus": `${response?.status}`,
          // "response": JSON.stringify(response?.data)
        }

        let body = {
          // message: `User - ${userData.id} (${userData?.sub}) - Generate PDF - Dashboard Page`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Dashboard Page`,
        }
        logEvents(body);

        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body));
          console.log(url, "url pdf");
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error while save prescription.",
          });
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  const viewPrescribedNote = async (preId) => {



    console.log("preId: ", preId);
    try {
      const paylod = {
        appointmentId: preId.appoinment_id,
      };
      UPDATEDPOST("generatenotespdf", paylod).then((response) => {
        console.log("response: ", response);

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role": `${userData?.role}`,
            "createdate": new Date().toISOString()
          },
          "action": "INSERT",
          "source": "Dashboard Page",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `generatenotespdf`,
          "requestParameters": {
            paylod
          },
          "responsestatus": `${response?.status}`,
          // "response": JSON.stringify(response?.data)
        }

        let body = {
          // message: `User - ${userData.id} (${userData?.sub}) - Generate Prescribed Note PDF - Dashboard Page`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Dashboard Page`,
        }
        logEvents(body);

        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body));
          console.log(url, "url pdf");
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error while save prescription.",
          });
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  const appointmentListColumn = [
    {
      title: "Doctor",
      dataIndex: ["providername", "clinicname"],
      key: "name",
      render: (text, row) => (
        <a style={{ color: "#1A3045", fontSize: 15, cursor: "unset" }}>
          <div className="row">
            <div>
              {/* <img
                style={{ width: 40, marginLeft: 8 }}
                className="avatar-img rounded-circle"
                // src={IMG01}
                src={
                  row.providerprofilepicture == null || row.providerprofilepicture == ''
                    ? IMG01
                    : getUserImage(row.providerprofilepicture)
                }
              /> */}
            </div>
            <div style={{ paddingLeft: 13 }}>
              Dr. {row["providername"]}
              <p style={{ color: "#009efb", margin: 0 }}>{row["clinicname"]}</p>
            </div>
          </div>
        </a>
      ),
    },
    {
      title: "Appointment Type",
      dataIndex: "appointmenttype",
      key: "appointmenttype",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {text}
        </a>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "startdatetime",
      key: "name",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {/* {moment(text).local().format("DD-MMM-YYYY")}{" "} */}
          {moment(text).utc().format("DD-MMM-YYYY")}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text).utc().format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (tags) => (
        <>
          {tags == "DRAFT" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              PENDING
            </span>
          ) : null}
          {tags == "PENDING" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              CALLBACK
            </span>
          ) : null}
          {tags == "CONFIRMED" ? (
            <span className={`badge badge-success ${style.tag_dashboard}`}>
              CONFIRMED
            </span>
          ) : null}
          {tags == "COMPLETED" ? (
            <span className={`badge  ${style.tag_dashboard_completed}`}>
              COMPLETED
            </span>
          ) : null}
          {tags == "CANCEL" ? (
            <span className={`badge badge-danger  ${style.tag_dashboard}`}>
              CANCELED
            </span>
          ) : null}
          {tags == "REJECTED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard}`}>
              DECLINED
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "appoinment_id",
      render: (status, data) => (
        <Space size="middle">
          <Button
            onClick={event => toChat(data)}
            // to={toChat(data)}
            // to='/chatDoctor'
            className={`${style.btnTransparentNew} ml-3`}
          // target="_blank"
          >
            <CommentOutlined className={style.iconComment} />
          </Button>

          {/* <Link
              onClick={event => toChat(data)}
              // to={toChat(data)}
              to='/chatDoctor'
              className="ml-3"
              target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
            </Link> */}
          <button
            className={style.buttonView}
            role="button"
            onClick={() => getpriscriptions(data, "upcomingAppointment")}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>
          {data.status === "CONFIRMED" ? (
            <>
              {moment(data.startdatetime).format("DD-MMM-YYYY") ==
                moment().format("DD-MMM-YYYY") ? (
                <>
                  {getDateFormate(data.startdatetime) ==
                    moment().format("h:mm A") ? (
                    <button className={style.buttonJoinOriginal} role="button">
                      <FontAwesomeIcon icon={faVideo} className="mr-2" />
                      Join
                    </button>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </Space>
      ),
    },
  ];

  const handleCancelChatModal = () => {
    // dispatch(setCurrentChat(null))
    localStorage.setItem("chatHead", null);
    setOpenChat(false);
  }

  const toChat = (dataa) => {
    console.log(dataa.EmployeeID, 'dataaaaa ---=-=-=------------', selectedChatHead, "000", selectedChatUser);
    const value = dataa;
    dispatch(setChatUserData(value));

    // dispatch(setCurrentChat(dataa?.id))
    localStorage.setItem("chatHead", dataa?.id);

    console.log("Everything is OK");

    setCurrentChatData(dataa)
    setOpenChat(true);
    scrollToBottom();
    getConversations(value);

  }

  const viewInvoice = async (dataa) => {



    console.log(dataa, "DATATATA");
    // setLoaderId(index.claimid)
    const requestData = {
      claimId: dataa.claimid,
    }
    try {
      const claimData = await UPDATEDPOST(`invoice`, requestData)

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "INSERT",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `invoice`,
        "requestParameters": {
          requestData
        },
        "responsestatus": `${claimData?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Invoice - Dashboard Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);

      const fileUrl = URL.createObjectURL(base64toBlob(claimData.data.pdf))
      setPdfUrl(fileUrl);

      setIsOpenPdfModal(true)
      // setLoader(true)

    } catch (error) {
      notification.warning({
        message: error.message,
      })
    } finally {
      // setLoader(false)
    }
  }

  const PreviousappointmentListColumn = [
    {
      title: "Doctor",
      dataIndex: ["providername", "clinicname"],
      key: "name",
      render: (text, row) => (
        <a style={{ color: "#1A3045", fontSize: 15, cursor: "unset" }}>
          <div className="row">
            <div>
              {/* <img
                style={{ width: 40, marginLeft: 8 }}
                className="avatar-img rounded-circle"
                // src={IMG01}
                src={
                  row.providerprofilepicture == null || row.providerprofilepicture == ''
                    ? IMG01
                    : getUserImage(row.providerprofilepicture)
                }
              /> */}
            </div>
            <div style={{ paddingLeft: 13 }}>
              Dr. {row["providername"]}
              <p style={{ color: "#009efb", margin: 0 }}>{row["clinicname"]}</p>
            </div>
          </div>
        </a>
      ),
    },
    {
      title: "Appointment Type",
      dataIndex: "appointmenttype",
      key: "appointmenttype",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {text}
        </a>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "startdatetime",
      key: "name",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {/* {moment(text).local().format("DD-MMM-YYYY")}{" "} */}
          {moment(text).utc().format("DD-MMM-YYYY")}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text).utc().format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (tags) => (
        <>
          {tags == "DRAFT" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              PENDING
            </span>
          ) : null}
          {tags == "PENDING" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              CALLBACK
            </span>
          ) : null}
          {tags == "CONFIRMED" ? (
            <span className={`badge badge-success ${style.tag_dashboard}`}>
              CONFIRMED
            </span>
          ) : null}
          {tags == "COMPLETED" ? (
            <span className={`badge  ${style.tag_dashboard_completed}`}>
              COMPLETED
            </span>
          ) : null}
          {tags == "REJECTED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard}`}>
              DECLINED
            </span>
          ) : null}
          {tags == "CANCELLED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard}`}>
              CANCELLED
            </span>
          ) : null}
          {tags == "CANCEL" ? (
            <span className={`badge badge-danger  ${style.tag_dashboard}`}>
              CANCELED
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "appoinment_id",
      render: (status, data) => (
        <Space size="middle">
          <Button
            onClick={event => toChat(data)}
            // to={toChat(data)}
            // to='/chatDoctor'
            className={`${style.btnTransparentNew} ml-3`}
          // target="_blank"
          >
            <CommentOutlined className={style.iconComment} />
          </Button>
          {/* <Link
              onClick={event => toChat(data)}
              // to={toChat(data)}
              // to='/chatDoctor'
              className="ml-3"
              // target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
            </Link> */}
          <button
            className={style.buttonView}
            role="button"
            onClick={() => getpriscriptions(data, "previousAppoinrmentAppointment")}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>
          {data.status === "CONFIRMED" ? (
            <>
              {moment(data.startdatetime).format("DD-MMM-YYYY") ==
                moment().format("DD-MMM-YYYY") ? (
                <>
                  {getDateFormate(data.startdatetime) ==
                    moment().format("h:mm A") ? (
                    <button className={style.buttonJoinOriginal} role="button">
                      <FontAwesomeIcon icon={faVideo} className="mr-2" />
                      Join
                    </button>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}

          {/* <Button
            onClick={event => viewInvoice(data)}
            className={`${style.btnTransparentNew} ml-2`}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} className={style.iconComment} />
          </Button> */}

          {/* {data.claimid != null && data.appointmenttype != 'WALKIN' && data?.isinvoicegen != 0 && data?.isinvoicegen != null ? */}
          {data.claimid != null && data?.isinvoicegen != 0 && data?.isinvoicegen != null ?
            <Button
              onClick={event => viewInvoice(data)}
              className={`${style.btnTransparentNew} ml-2`}
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} className={style.iconComment} />
            </Button> : null}

        </Space>
      ),
    },
  ];

  const priscriptionsListColumn = [
    // {
    //   title: "Appointment Id",
    //   dataIndex: "appoinment_id",
    //   key: "appoinment_id",
    // },
    {
      title: "Doctor",
      dataIndex: "['doctorFirstName','doctorLastName'],",
      key: "doctorFirstName",
      render: (text, row) => (
        <>
          {
            text !== "null null" ? <Text style={{ textTransform: 'capitalize' }}>{row.doctorFirstName} {row.doctorLastName}</Text> : <Text> - </Text>
          }
        </>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "createdate",
      key: "createdate",
      render: (text) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {/* {moment(text).local().format("DD-MMM-YYYY")}{" "} */}
          {moment(text).utc().format("DD-MMM-YYYY")}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text).utc().format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    // {
    //   title: "Drug Name",
    //   dataIndex: "drug_name",
    //   key: "drug_name",
    //   width: 400,
    // },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacy_name",
      key: "pharmacy_name",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (id, data) => (
        <Space size="middle">
          <button
            style={{
              paddingTop: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={style.buttonJoin}
            role="button"
            onClick={() => getpriscriptions(data, "prescription")}
          >
            <FontAwesomeIcon className="mr-2" icon={faCalendar} />
            Appointment
          </button>

          <button
            className={style.buttonView}
            role="button"
            onClick={() => showPdf(data.id)}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            View
          </button>

          {data.appointmentNote != null ?
            <>
              {data.appoinment_id != null ?
                <Button
                  onClick={event => viewPrescribedNote(data)}
                  className={`${style.btnTransparentNew} ml-2`}
                >
                  <FontAwesomeIcon icon={faNotesMedical} className={style.iconComment} />
                </Button> : null}
            </> : null
          }
        </Space>
      ),
    },
  ];

  const callBackColumn = [
    // {
    //   title: "Patient Name",
    //   dataIndex: ["patientname", "patientprofilepicture"],
    //   key: "patientname",
    //   render: (text, row) => (
    //     <a style={{ color: "#1A3045", fontSize: 15, cursor: "unset" }}>
    //       <div className="row">
    //         <div>
    //           {/* <img
    //             style={{ width: 40, marginLeft: 8 }}
    //             className="avatar-img rounded-circle"
    //             src={
    //               // row.patientprofilepicture == null
    //               row.providerprofilepicture == null || row.providerprofilepicture == ''
    //                 ? IMG02
    //                 : `${process.env.REACT_APP_ASSET_URL}/${user.profilepicture}`
    //             }
    //           /> */}
    //         </div>
    //         <div style={{ paddingLeft: 13 }}>{row.patientname.charAt(0).toUpperCase() + row.patientname.slice(1)}</div>
    //       </div>
    //     </a>
    //   ),
    // },
    // {
    //   title: 'Payment Type',
    //   key: 'paymenttype',
    //   dataIndex: 'paymenttype',
    //   render: (paymentTag) => (
    //     <>
    //       <>
    //         {paymentTag === 'cash' && <Tag color="green">{paymentTag?.toUpperCase()}</Tag>}
    //         {paymentTag === 'PRIVATE_INSURANCE' && (
    //           <Tag color="red">{paymentTag?.toUpperCase()}</Tag>
    //         )}
    //         {paymentTag === 'card' && <Tag color="blue">{paymentTag?.toUpperCase()}</Tag>}
    //         {paymentTag === 'PRIVATE_PAY' && <Tag color="pink">{paymentTag?.toUpperCase()}</Tag>}
    //         {paymentTag === 'OHIP' && <Tag color="orange">{paymentTag?.toUpperCase()}</Tag>}
    //       </>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Reason',
    //   dataIndex: 'callbackreason',
    //   key: 'callbackreason',
    //   render: (text) => (
    //     <Tooltip title={text}>
    //       <a className="text-blue">
    //         <MessageOutlined />
    //       </a>
    //     </Tooltip>
    //   )

    // },

    {

      title: (<div style={{ fontSize: "18px" }}>
        Appointment Date / Window
      </div>),
      key: "appointmentDetails",
      render: (text, record) => {
        const formattedDate = moment(record.callbackstart, "YYYY-MM-DD HH:mm A").format("ll");
        return (
          <div className={style.appointmentdetails}>
            <div > {formattedDate}</div>
            <div >{record.callbacktime}</div>
          </div>
        );
      }
    },
    {
      title: (<div style={{ fontSize: "18px" }}>
        Status
      </div>),
      dataIndex: "callbackstatus",
      key: "callbackstatus",
      render: (tags) => (
        <>
          {/* {tags == "ON CALL" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              ON CALL
            </span>
          ) : null} */}
          {tags == "REJECTED" ? (
            <span className={`badge badge-danger ${style.tag_dashboard2}`}>
              Cancelled
            </span>
          ) : null}
          {tags == "NEW" ? (
            <span className={`badge badge-warning ${style.tag_dashboard2}`}>
              Upcoming
            </span>
          ) : null}
          {tags == "FULFILLED" || tags == "FULLFILLED" || tags == "COMPLETED" ? (
            <span className={`badge badge-info ${style.tag_dashboard}`}>
              Complete
            </span>
          ) : null}
          {tags == "ASSIGNED" ? (
            <span className={`badge badge-success ${style.tag_dashboard}`}>
              Confirmed
            </span>
          ) : null}
        </>
      ),
    },


    {
      title: (<div style={{ fontSize: "18px" }}>
        Details
      </div>),
      key: "action",
      dataIndex: "id",
      render: (status, data) => (

        <Space size="middle">
          {/* <Link
              onClick={event => toChat(data)}
              // to={toChat(data)}
              to='/chatDoctor'
              className="ml-3"
              target="_blank"
            >
              <CommentOutlined className={style.iconComment} />
          </Link> */}

          {data.appointment_id !== null ? (
            <>

              <Button
                className={`${style.btnTransparentNew} ml-3`}
                onClick={event => toChat(data)}
              >
                <CommentOutlined className={`${style.iconComment, style.iconchat}`} />
              </Button>

              <EyeOutlined style={{ fontSize: '20px' }} onClick={() => getpriscriptions(data, "prescription")} />

            </>



          ) : (
            <div className={`${style.btnTransparentNew} ml-3`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>-</div>
          )}



          {data.paymenttype == 'OHIP' ? null :
            <>
              {data.claimid != null && data?.isinvoicegen != 0 && data?.isinvoicegen != null ?
                <Button
                  onClick={event => viewInvoice(data)}
                  className={`${style.btnTransparentNew} ml-2`}
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} className={style.iconComment} />
                </Button> : null}
            </>
          }
        </Space>
      ),
    },
  ];

  const getUserImage = (imageUrl) => {
    if (imageUrl) {
      if (
        imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/) !=
        null
      ) {
        return imageUrl;
      }
      return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
    } else {
      return null;
    }
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const ClickTab = (e) => {
    console.log(e);
    setSelectedCard(e);
  };

  const viewFamilyMembers = (event: any) => {
    console.log(event, "family member selected");

    // dispatch kryu apde
    if (event) {
      dispatch(setSelectedFamilyMember(event));
    }

    var filtered = familyMembers.filter(function (value, index, arr) {
      return value.id == event.id;
    });
    const data = [
      {
        // userName: `${user?.FirstName} ${user?.MiddleName} ${user?.LastName}`,
        userName: `${user?.FirstName}  ${user?.LastName}`,
        id: user?.id,
        gender: user?.gender && user?.gender != 'undefined' ? user?.gender : '-',
      },
    ];
    filtered = data;
    console.log(filtered, "filtered");
    setFamilyMembers(filtered);
    setSelectedFamilyMembers(event);
    setShowFamily(true);
    getDoctortList(
      { showloader: false },
      event?.familymemberid ? event?.familymemberid : event?.id
    );
    console.log(event?.familymemberid, "viewfamily member ma get prescriptions list")
    getpriscriptionList(event?.familymemberid ? event?.familymemberid : event?.id);

    if (event.familymemberid) {
      dispatch(AppointmentRequestType("familyMember"));
    } else {
      dispatch(AppointmentRequestType("Own"));
    }

    getcallBackList(event?.familymemberid ? event?.familymemberid : event?.id);
    getInsuranceDetails(event?.familymemberid ? event?.familymemberid : event?.id);
    if (event?.id == user?.id) {
      setShowFamily(false);
      getFamilyMember();
    }
  };

  const getInsuranceDetails = async (uid) => {


    try {
      setLoader(true);
      const res = await UPDATEDGET(`insurance/${uid}`);
      console.log(res?.data, "getInsuranceInfo");

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `insurance/${uid}`,
        "requestParameters": {
        },
        "responsestatus": `${res?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Insurance Details - Dashboard Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);

      // setInsuranceData(res?.data);
      const result = res?.data.find(
        (item) => item.insurance_type === 'OHIP'
      );

      if (result) {
        dispatch(setInsurance(result));
      }
      setLoader(false);
    } catch (err) {
      console.log("error", err);
      setLoader(false);
    }
  };

  const getInsuranceDetailForUser = async () => {



    try {
      setLoader(true);
      const res = await UPDATEDGET(`insurance/${user?.id}`);
      console.log(res, "getInsuranceInfo");

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `insurance/${user?.id}`,
        "requestParameters": {
        },
        "responsestatus": `${res?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let body = {
        // message: `User - ${userData.id} (${userData?.sub}) - Fetch Insurance Details - Dashboard Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(body);
      // setInsuranceData(res?.data);
      const result = res?.data.find(
        (item) => item.insurance_type === 'OHIP'
      );

      if (result) { } else {
        console.log("okokokoko")
        dispatch(setProfileComplate("false"));
      }
    } catch (err) {
      console.log("getInsuranceInfo error", err);
    }
  };

  const handleOk = () => {
    setEventModalVisisble(false);
  };

  const handleCancel = () => {
    setEventModalVisisble(false);
  };

  const handleCancelModal = () => {
    setNoteModal(false);
  };

  const EditProfile = () => {
    history.push("/profile")
  }

  console.log(callBackSchedule);



  const handleCardClick = (data) => {
    
    if (data.appointment_id !== null) {

      console.log('if aave che', data);
      getpriscriptions(data, "prescription")
      // toChat(data);

    } else {
      console.log('else aavyu?');

    }
  };

  const openchatopen = (data) => {
    if (data.appointment_id !== null) {

      console.log('if aave che');
      // toChat(data);
      toChat(data);


    } else {
      console.log('else aavyu?');

    }
  }

  const joinMeeting = async (item, from) => {



    console.log(item, "ITEMMMMM")

    const formattedStartTime = moment(item?.callbackstart, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const formattedEndTime = moment(item?.callbackend, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    console.log('formattedTime_____', formattedEndTime);
    console.log('formattedStartTime_____', formattedStartTime);

    try {
      const body = {
        roomid: (item?.appointment_id || item?.appoinment_id) + "",
        name: user?.name,
        ishost: from == 'host' ? 1 : 0,
        canSwitchOnCam: from == 'user' ? 0 : 1,
        canSwitchOnMic: from == 'user' ? 0 : 1,
        meetingStartTime: formattedStartTime,
        meetingEndTime: formattedEndTime,
        // meetingStartTime: currentTime,
        // meetingEndTime: currentTime,
        userdata: {
          id: user?.id,
          Type: user?.role.toUpperCase(),
          title: null,
          FirstName: user?.FirstName,
          LastName: user?.LastName,
          MiddleName: null
        },
        authorized: true
      }
      console.log(body, "body__)_)_)_)_)")
      const result = await UPDATEDPOST('meeting/token', body)

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role": `${userData?.role}`,
          "createdate": new Date().toISOString()
        },
        "action": "INSERT",
        "source": "Dashboard Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `meeting/token`,
        "requestParameters": {
          body
        },
        "responsestatus": `${result?.status}`,
        // "response": JSON.stringify(response?.data)
      }

      let logbody = {
        // message: `User - ${userData.id} (${userData?.sub}) - Join Meeting - Dashboard Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Dashboard Page`,
      }
      logEvents(logbody);
      console.log(result?.data?.jwt, "result result")
      const url = `https://dev613.onrx.ca?token=${result?.data?.jwt}`;
      window.open(url, '_blank')

    } catch (error) {
      notification.error({
        message: error
      })
    }
  }


  return (
    <>
      <div style={{ height: '100vh' }}>
        <TopBar onShow={showDrawer} />

        {/* <VideoContainerWrapper /> */}
        {/* <div className="box">
          <div className={`${style.toggebtn} d-block d-xl-none`}>
            <i>
              <FontAwesomeIcon
                className="mr-1"
                icon={faEquals}
                onClick={showDrawer}
              />
            </i>
          </div>
          <div className="d-block d-xl-none">
            <p className="pageInfoM">Dashboard</p>
          </div>
          <div className="d-none d-xl-block">
            <p className="pageInfo">Dashboard</p>
          </div>
        </div> */}


        {/* <div className="content" style={{ backgroundColor: "#f1f5f9" }}> */}
        <div>
          <div className="content" style={{ height: '100%' }}>
            <div className="container-fluid" style={{ height: '100%' }}>
              <div className="row" style={{ height: '100vh' }} >
                <div
                  className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar`}

                >
                  <DashboardSidebar />
                </div>
                <Drawer
                  closable={false}
                  width={300}
                  placement="left"
                  onClose={onClose}
                  visible={visible}
                  style={{ paddingTop: 0 }}
                >
                  <div className="widget-profile pro-widget-content pt-0 pb-4">
                    <div className="profile-info-widget">
                      <img src="../Images/logo.png" height="50" alt="" />
                    </div>
                  </div>
                  <DashboardSidebar />
                </Drawer>
                {/* {sidebar ? (
                  <div className={`d-block d-xl-none ${style.toggle}`}>
                    <DashboardSidebar />
                  </div>
                ) : null} */}
                <div className="col-lg-12 col-xl-10" >
                  {/* {isProfileComplate == "false" && showEdit ? (
                    <div className="alert alert-warning mb-2" role="alert">
                      Please complete your profile!{" "}
                      setNoteModal(false);
                      <a
                        href="/profile"
                        className="pl-3"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Edit Profile
                      </a>
                    </div>
                  ) : null} */}

                  {/* hide profile details, family members, and profile picture 14-8 new degin start */}

                  {/* <div className={`row ${style.div_row_new}`}>
                    <div className="col-lg-8">
                      <div className="row">
                        <div
                          className={`col-sm-12 col-md-5  col-lg-5 col-xl-5 ${style.profile_div}`}
                        >
                          <div className={style.D_Profile_div}>
                            {showFamily == false ? (
                              <>
                                {user?.profilepicture != "" &&
                                  user?.profilepicture != null ? (
                                  <img
                                    className="profpic"
                                    src={`${process.env.REACT_APP_ASSET_URL}/${user?.profilepicture}`}
                                    alt="User"
                                  />
                                ) : (
                                  <img
                                    className="profpic"
                                    src={IMG02}
                                    alt="User"
                                  />
                                )}
                                <h4 className="py-2 m-0">
                                  {user?.FirstName?.charAt(0).toUpperCase() + user?.FirstName?.slice(1)} {user?.MiddleName !== null ? user?.MiddleName?.charAt(0).toUpperCase() + user?.MiddleName?.slice(1) : ''}{" "}
                                  {user?.LastName?.charAt(0).toUpperCase() + user?.LastName?.slice(1)}
                                </h4>
                                <h5 className="text-secondary">
                                  {user?.Email}
                                </h5>
                              </>
                            ) : (
                              <>
                                {selectedFamilyMembers.gender == "male" ? (
                                  <img className="profpic" src={Man} alt="" />
                                ) : (
                                  <img className="profpic" src={Woman} alt="" />
                                )}
                                <h4 className="py-2 m-0">
                                  {selectedFamilyMembers?.userName}
                                </h4>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className={`col-sm-12 col-md-7  col-lg-7 col-xl-7 ${style.Pro_info_div}`}
                        >
                          {showFamily == false ? (
                            <>
                              <div className={`row pt-3 `}>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 ">
                                  <div className="d-flex flex-column justify-content-start align-items-start">
                                    <div>
                                      <p
                                        className={`${style.lbl_color} m-0 p-0`}
                                      >
                                        Gender
                                      </p>
                                      <p className="m-0 p-0" style={{ textTransform: "capitalize" }}>{user?.gender && user?.gender != 'undefined' ? user?.gender : '-'}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Birthday
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.DOB == null || user?.DOB == "" || user?.DOB == undefined || user?.DOB == "0000-00-00 00:00:00" || user?.DOB == "0000-00-00"
                                        ? "-"
                                        : moment(user?.DOB).utc().format(
                                          "DD-MM-YYYY"
                                        )}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Phone
                                    </p>
                                    <p className="m-0 p-0 ">
                                      {user?.phoneNumber == null ||
                                        user?.phoneNumber == ""
                                        ? "-"
                                        : user?.phoneNumber}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Street Address
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.Address1 == null ||
                                        user?.Address1 == ""
                                        ? "-"
                                        : user?.Address1}{" "}
                                      {user?.Address2}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column  ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      City
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.City == null || user?.City == ""
                                        ? "-"
                                        : user?.City}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column  ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      {" "}
                                      State/Province
                                    </p>
                                    <p className="m-0 p-0">
                                      {user?.State == null || user?.State == ""
                                        ? "-"
                                        : user?.State}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Country
                                    </p>
                                    <p className="m-0 p-0 ">
                                      {user?.Country == null ||
                                        user?.Country == ""
                                        ? "-"
                                        : user.Country}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Zipcode
                                    </p>
                                    <p className="m-0 p-0 ">
                                      {user?.PostalCode == null ||
                                        user?.PostalCode == ""
                                        ? "-"
                                        : user.PostalCode}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Languages
                                    </p>
                                    <p className="m-0 p-0 " style={{ textTransform: "capitalize" }}>
                                      {user?.Languages == null ||
                                        user?.Languages == "" || user?.Languages == 'undefined'
                                        ? "-"
                                        : user?.Languages}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className={`row pt-3 `}>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 ">
                                  <div className="d-flex flex-column justify-content-start align-items-start">
                                    <div>
                                      <p
                                        className={`${style.lbl_color} m-0 p-0`}
                                      >
                                        Gender
                                      </p>
                                      <p className="m-0 p-0" style={{ textTransform: "capitalize" }}>
                                        {selectedFamilyMembers?.gender}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Birthday
                                    </p>
                                    <p className="m-0 p-0">
                                      {moment(
                                        selectedFamilyMembers?.dateOfBirth
                                      ).utc().format("DD-MMM-YYYY")}
                                    </p>
                                  </div>
                                </div>
                           
                              </div>
                              <div className="row pt-3">
                                <div className="col-sm-4 col-md-4 col-xl-4 col-lg-4 d-flex flex-column ">
                                  <div>
                                    <p className={`${style.lbl_color} m-0 p-0`}>
                                      Street Address
                                    </p>
                                    <p className="m-0 p-0">
                                      {selectedFamilyMembers?.address}{" "}
                                    </p>
                                  </div>
                                </div>
                             
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className={style.file_container_div}>
                        {loaderFamily ? (
                          <div className="spinner">
                            <Spin tip="Loading..."></Spin>
                          </div>
                        ) : (
                          <>
                            <div className={style.file_container}>
                              <div>
                                {showFamily == false ? (
                                  <h4 className="font-weight-bold">
                                    Family Members
                                  </h4>
                                ) : (
                                  <h4 className="font-weight-bold">
                                    Primary Member
                                  </h4>
                                )}
                              </div>
                              <div>
                                {familyMembers.length > 0 ? (
                                  ""
                                ) : (
                                  <Buttons
                                    onClick={() => addFamilyModelOpen()}
                                    className="p-0 m-0"
                                    type="primary"
                                    shape="circle"
                                  >
                                    <div className="pb-1">
                                      <PlusOutlined />
                                    </div>
                                  </Buttons>
                                )}
                              </div>
                            </div>
                            <div className={style.inner_div}>
                              {familyMembers.length > 0 ? (
                                <>
                                  {familyMembers.map((el, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`${style.file_in}`}
                                      >
                                        <div className={style.details}>
                                          {el.gender == "male" ? (
                                            <img
                                              className={style.fileIcon}
                                              src={Man}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className={style.fileIcon}
                                              src={Woman}
                                              alt=""
                                            />
                                          )}
                                          <div>
                                            <p
                                              className="pl-3 m-0 text-capitalize"
                                              style={{ width: 160 }}
                                            >
                                              {" "}
                                              {el?.userName}
                                            </p>
                                            <p
                                              className={`${style.lblName} pl-3 m-0 text-capitalize`}
                                            >
                                              {" "}
                                              {el?.relationship}
                                            </p>
                                          </div>
                                        </div>
                                        <div className={style.relationship}>
                                          <p
                                            className="pb-1 mb-1 text-uppercase"
                                            style={{ width: 80 }}
                                          >
                                            {el?.relationship}
                                          </p>
                                        </div>
                                        <div className="">
                                          <button
                                            className={style.buttonView1}
                                            role="button"
                                            onClick={() => {
                                              viewFamilyMembers(el);
                                            }}
                                          >
                                            view
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                      <>
                                        <span>
                                          No Data
                                        </span>
                                       
                                      </>
                                    }
                                  />

                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {callBackSchedule?.length > 0 && 

                  <div className={style.card}>
                    <div
                      className="card-body pt-0 pb-0"
                    >
                      <div className={style.Segment_Div}>
                        <div
                          style={{
                            // backgroundColor: "#eff1f7",
                            display: "flex",
                            padding: 5,
                            borderRadius: 10,
                            cursor: "pointer",
                            width: '100%'
                          }}
                        >

                          <div
                            style={{ width: '100%' }}
                            className="tab_static"
                            onClick={() => ClickTab("CallBack")}
                          >
                            <p className={`${style.text_color} m-0 p-0 h4`} >
                              Upcoming Appointments
                            </p>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <div className="spinner">
                          <Spin tip="Loading..."></Spin>
                        </div>
                      ) : (
                        <>

                          {/* <Table
                            // size="small"
                            // pagination={{
                            //   defaultPageSize: 10,
                            //   pageSizeOptions: ["30", "40"],
                            //   showSizeChanger: false,
                            // }}
                            pagination={false}
                            columns={callBackColumn}
                            dataSource={callBackSchedule}
                            scroll={{ x: 400 }}
                          /> */}
                          {/* <Table
                            pagination={false}
                            columns={callBackColumn}
                            dataSource={callBackSchedule}
                            scroll={{ x: 400 }}
                            // rowClassName={style.highlightedrow}
                            rowClassName={(record) => {
                              if(record.callbackstatus === 'ASSIGNED'){
                                return `${style.highlightedrowgreen}`
                              }else if(record.callbackstatus === "NEW"){
                                return `${style.highlightedrowyellow}`
                              }else if(record.callbackstatus === "REJECTED"){
                                return `${style.highlightedrowred}`
                              }else if(record.callbackstatus === "COMPLETED"){
                                return `${style.highlightedrowblue}`
                              }else{
                                return `${style.highlightedrow}`
                              }
                          }}
                          /> */}
                          {callBackSchedule.map((item) => {
                            return (
                              <div key={item.appointment_id} className={`${style.groupofcard} row justify-content-center mb-4`}
                              // onClick={() => handleCardClick(item)} 
                              >
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12">

                                  {/* <div className="card-body" style={{ backgroundColor: "#134f85", borderRadius: "10px", }}> */}
                                  <div className="card-body" style={{
                                    backgroundColor:
                                      item?.callbackstatus === "REJECTED"
                                        ? "#F5E8E9"
                                        : item?.callbackstatus === "NEW"
                                          ? "#FCF9EF"
                                          : item?.callbackstatus === "ASSIGNED"
                                            ? "#EAF5EA"
                                            : item?.callbackstatus === "COMPLETED" ||
                                              item?.callbackstatus === "FULFILLED" ||
                                              item?.callbackstatus === "FULLFILLED"
                                              ? "#F1F6FD"
                                              : "#cddcea", // Default color if callbackstatus doesn't match any of the conditions
                                    borderRadius: "10px",
                                    border: "1px solid"
                                  }}>
                                    <div className="row">
                                      <div className={`col-12 col-md-3 ${style.div_flex_start}`}>
                                        <div
                                          style={{
                                            backgroundColor:
                                              item?.callbackstatus === "REJECTED"
                                                ? "#D2222D"
                                                : item?.callbackstatus === "NEW"
                                                  ? "#FFBF00"
                                                  : item?.callbackstatus === "ASSIGNED"
                                                    ? "var(--green)"
                                                    : item?.callbackstatus === "COMPLETED" ||
                                                      item?.callbackstatus === "FULFILLED" ||
                                                      item?.callbackstatus === "FULLFILLED"
                                                      ? "#0C6DFD"
                                                      : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                          }}
                                          className={`${style.btn_day}`}
                                        >
                                          <p className={`${style.text_day}`}>{moment(item?.callbackdate).format("ddd")}</p>
                                        </div>

                                        <div>
                                          <h4 style={{ color: "black", fontWeight: "bold", fontSize: 20 }}>
                                            {item?.callbacktime}
                                          </h4>
                                          {/* <h4 style={{ color: "#666666"}}>
                                            {moment(item?.callbackdate).format("DD-MMM-YYYY")}
                                          </h4> */}
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            {moment.utc(item?.callbackdate).format("DD-MMM-YYYY")}
                                          </h4>
                                        </div>

                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        <div>
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            Appointment Type
                                          </h4>
                                          <h4 style={{ color: "black", fontSize: 20 }}>
                                            Callback Request
                                          </h4>
                                        </div>
                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        {item.appointment_id !== null ? (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <span className="mx-3">
                                              <CommentOutlined
                                                className={`${style.iconComment} ${style.iconchat2}`}
                                                onClick={() => openchatopen(item)}
                                              />
                                            </span>
                                            <span>
                                              <img
                                                src={EyeIcon}
                                                className={style.img_eye}
                                                onClick={() => handleCardClick(item)}
                                              />


                                              {/* <FontAwesomeIcon className="mr-2" icon={faHandshake} /> */}
                                              {/* <EyeOutlined className={`${style.iconComment} ${style.iconchat2}`} style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} /> */}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className={`d-flex justify-content-center align-items-center ${style.iconchat2}`}  >
                                            {/* Content for when appointment_id is null */}-
                                          </div>
                                        )}
                                      </div>
                                      <div className={`col-12 col-md-3 text-md-right ${style.div_center}`}>
                                        <div className="row">
                                          <div className="col-md-6">
                                            {item.join_meeting == 1 ?
                                              (



                                                <Button
                                                  style={{

                                                    borderRadius: "30px",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    textTransform: "capitalize"
                                                  }}
                                                  className={style.animated_tada_delay}
                                                  onClick={() => joinMeeting(item, 'user')}
                                                // className={`${style.btn_status}  animate__animated animate__bounce animate__faster`}
                                                >
                                                  JOIN MEETING
                                                </Button>
                                                // <button
                                                // className={style.img_eyee}
                                                // onClick={() => joinMeeting(item, 'user')}
                                                // style={{width: "100%"}}
                                                // >
                                                //   Join Meeting
                                                // </but>
                                              )
                                              :
                                              (<></>)}
                                          </div>
                                          <div className="col-md-6">
                                            <button
                                              style={{
                                                minWidth: '130px',
                                                backgroundColor:
                                                  item?.callbackstatus === "REJECTED"
                                                    ? "#D2222D"
                                                    : item?.callbackstatus === "NEW"
                                                      ? "#FFBF00"
                                                      : item?.callbackstatus === "ASSIGNED"
                                                        ? "var(--green)"
                                                        : item?.callbackstatus === "COMPLETED" ||
                                                          item?.callbackstatus === "FULFILLED" ||
                                                          item?.callbackstatus === "FULLFILLED"
                                                          ? "#0C6DFD"
                                                          : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                                borderRadius: "30px",
                                                fontSize: "15px",
                                                color: "white",
                                                textTransform: "capitalize"
                                              }}
                                              className={`${style.btn_status}`}
                                            >
                                              {item?.callbackstatus}
                                            </button>
                                          </div>
                                        </div>


                                      </div>
                                    </div>


                                    {/* <div className="row">
                                      <div className="col-12 col-md-6"></div>
                                      <div className="col-12 col-md-6 text-md-right d-flex justify-content-end align-items-center mt-3">
                                        {item.appointment_id !== null ? (
                                          <div className="d-flex justify-content-end align-items-center text-white ">
                                            <span className="mx-3 text-white">
                                              <CommentOutlined
                                                className={`${style.iconComment} ${style.iconchat2}`}
                                                onClick={() => openchatopen(item)}
                                              />
                                            </span>
                                            <span>
                                              <EyeOutlined style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} />
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center text-white">
                                          </div>
                                        )}
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                          {/* <Table
                            columns={callBackColumn}
                            dataSource={callBackDetails}
                            scroll={{ x: 400 }}
                            rowClassName={(record) => {
                              const isAssigned = record.callbackstatus === 'ASSIGNED';
                              const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
                              const isTodayOrTomorrow =
                                moment().isSame(appointmentDate, 'day') ||
                                moment().add(1, 'day').isSame(appointmentDate, 'day');

                              return isAssigned && isTodayOrTomorrow ? `${style.highlightedrow}` : null;
                            }}
                          /> */}

                          {/* )} */}

                          {loader ? (
                            <></>
                          ) : (
                            <>
                              <Modal
                                centered
                                visible={pdfUrl?.length > 0 && isOpenPdfModal}
                                width={1000}
                                onCancel={() => {
                                  setPdfUrl(null)
                                  setIsOpenPdfModal(false)
                                }}
                              >
                                <Worker
                                  workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
                                // className={style.pdf_viever_padding}
                                >
                                  <div style={{ height: '750px' }}>
                                    <Viewer fileUrl={pdfUrl} plugins={[
                                      // Register plugins
                                      defaultLayoutPluginInstance,
                                    ]} />
                                  </div>
                                </Worker>
                              </Modal>
                            </>
                          )}

                          <Modal
                            centered
                            visible={isModalVisible}
                            width={1000}
                            onOk={() => setIsModalVisible(false)}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                          >
                            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
                            {/* <div style={{ height: "100%", width: '100%' }}> */}
                            {/* <Viewer fileUrl={PdfString} /> */}
                            {/* </div> */}
                            {/* </Worker> */}

                            <div className="pt-4">
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={PdfString}
                                  plugins={[
                                    // Register plugins
                                    defaultLayoutPluginInstance,
                                  ]}
                                />
                              </Worker>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>

                  }

                  {doctorList?.length > 0 && 

                  <div className={style.card}>
                    <div
                      className="card-body pt-0 pb-0"
                    >
                      <div className={style.Segment_Div}>
                        <div
                          style={{
                            // backgroundColor: "#eff1f7",
                            display: "flex",
                            padding: 5,
                            borderRadius: 10,
                            cursor: "pointer",
                            width: '100%'
                          }}
                        >

                          <div
                            style={{ width: '100%' }}
                            className="tab_static"
                            onClick={() => ClickTab("CallBack")}
                          >
                            <p className={`${style.text_color} m-0 p-0 h4`} >
                              Scheduled Appointments
                            </p>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <div className="spinner">
                          <Spin tip="Loading..."></Spin>
                        </div>
                      ) : (
                        <>

                       
                          {doctorList.map((item) => {
                            return (
                              <div key={item.appointment_id} className={`${style.groupofcard} row justify-content-center mb-4`}
                              // onClick={() => handleCardClick(item)} 
                              >
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12">

                                  {/* <div className="card-body" style={{ backgroundColor: "#134f85", borderRadius: "10px", }}> */}
                                  <div className="card-body" style={{
                                    backgroundColor:
                                      item?.status === "REJECTED"
                                        ? "#F5E8E9"
                                        : item?.status === "NEW" || item?.status == "DRAFT"
                                          ? "#FCF9EF"
                                          : item?.status === "ASSIGNED" || item?.status == "CONFIRMED"
                                            ? "#EAF5EA"
                                            : item?.status === "COMPLETED" ||
                                              item?.status === "FULFILLED" ||
                                              item?.status === "FULLFILLED"
                                              ? "#F1F6FD"
                                              : "#cddcea", // Default color if callbackstatus doesn't match any of the conditions
                                    borderRadius: "10px",
                                    border: "1px solid"
                                  }}>
                                    <div className="row">
                                      <div className={`col-12 col-md-3 ${style.div_flex_start}`}>
                                        <div
                                          style={{
                                            backgroundColor:
                                              item?.status === "REJECTED"
                                                ? "#D2222D"
                                                : item?.status === "NEW"  || item?.status === "DRAFT"
                                                  ? "#FFBF00"
                                                  : item?.status === "ASSIGNED" || item?.status === "CONFIRMED"
                                                    ? "var(--green)"
                                                    : item?.status === "COMPLETED" ||
                                                      item?.status === "FULFILLED" ||
                                                      item?.status === "FULLFILLED"
                                                      ? "#0C6DFD"
                                                      : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                          }}
                                          className={`${style.btn_day}`}
                                        >
                                          <p className={`${style.text_day}`}>{moment.utc(item?.startdatetime).format("ddd")}</p>
                                        </div>

                                        <div>
                                          <h4 style={{ color: "black", fontWeight: "bold", fontSize: 20 }}>
                                            {item?.callbacktime}
                                          </h4>
                                          {/* <h4 style={{ color: "#666666"}}>
                                          {moment(item?.callbackdate).format("DD-MMM-YYYY")}
                                        </h4> */}
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            {moment.utc(item?.startdatetime).format("DD-MMM-YYYY")}
                                          </h4>
                                        </div>

                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        <div>
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            Appointment Type
                                          </h4>
                                          <h4 style={{ color: "black", fontSize: 20 }}>
                                            Schedule Request
                                          </h4>
                                        </div>
                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        {item.appointment_id !== null ? (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <span className="mx-3">
                                              <CommentOutlined
                                                className={`${style.iconComment} ${style.iconchat2}`}
                                                onClick={() => openchatopen(item)}
                                              />
                                            </span>
                                            <span>
                                              <img
                                                src={EyeIcon}
                                                className={style.img_eye}
                                                onClick={() => handleCardClick(item)}
                                              />


                                              {/* <FontAwesomeIcon className="mr-2" icon={faHandshake} /> */}
                                              {/* <EyeOutlined className={`${style.iconComment} ${style.iconchat2}`} style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} /> */}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className={`d-flex justify-content-center align-items-center ${style.iconchat2}`}  >
                                            {/* Content for when appointment_id is null */}-
                                          </div>
                                        )}
                                      </div>
                                      <div className={`col-12 col-md-3 text-md-right ${style.div_center}`}>
                                        <div className="row">
                                          <div className="col-md-6">
                                            {item.join_meeting == 1 ?
                                              (
                                                <Button
                                                  style={{

                                                    borderRadius: "30px",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    textTransform: "capitalize"
                                                  }}
                                                  className={style.animated_tada_delay}
                                                  onClick={() => joinMeeting(item, 'user')}
                                                // className={`${style.btn_status}  animate__animated animate__bounce animate__faster`}
                                                >
                                                  JOIN MEETING
                                                </Button>
                                                // <button
                                                // className={style.img_eyee}
                                                // onClick={() => joinMeeting(item, 'user')}
                                                // style={{width: "100%"}}
                                                // >
                                                //   Join Meeting
                                                // </but>
                                              )
                                              :
                                              (<></>)}
                                          </div>
                                          <div className="col-md-6">
                                            <button
                                              style={{
                                                minWidth: '130px',
                                                backgroundColor:
                                                  item?.status === "REJECTED"
                                                    ? "#D2222D"
                                                    : item?.status === "NEW" || item?.status === "DRAFT"
                                                      ? "#FFBF00"
                                                      : item?.status === "ASSIGNED" || item?.status === "CONFIRMED"
                                                        ? "var(--green)"
                                                        : item?.status === "COMPLETED" ||
                                                          item?.status === "FULFILLED" ||
                                                          item?.status === "FULLFILLED"
                                                          ? "#0C6DFD"
                                                          : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                                borderRadius: "30px",
                                                fontSize: "15px",
                                                color: "white",
                                                textTransform: "capitalize"
                                              }}
                                              className={`${style.btn_status}`}
                                            >
                                              {item?.status}
                                            </button>
                                          </div>
                                        </div>


                                      </div>
                                    </div>


                                    {/* <div className="row">
                                    <div className="col-12 col-md-6"></div>
                                    <div className="col-12 col-md-6 text-md-right d-flex justify-content-end align-items-center mt-3">
                                      {item.appointment_id !== null ? (
                                        <div className="d-flex justify-content-end align-items-center text-white ">
                                          <span className="mx-3 text-white">
                                            <CommentOutlined
                                              className={`${style.iconComment} ${style.iconchat2}`}
                                              onClick={() => openchatopen(item)}
                                            />
                                          </span>
                                          <span>
                                            <EyeOutlined style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} />
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="d-flex justify-content-center align-items-center text-white">
                                        </div>
                                      )}
                                    </div>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                          {/* <Table
                            columns={callBackColumn}
                            dataSource={callBackDetails}
                            scroll={{ x: 400 }}
                            rowClassName={(record) => {
                              const isAssigned = record.callbackstatus === 'ASSIGNED';
                              const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
                              const isTodayOrTomorrow =
                                moment().isSame(appointmentDate, 'day') ||
                                moment().add(1, 'day').isSame(appointmentDate, 'day');

                              return isAssigned && isTodayOrTomorrow ? `${style.highlightedrow}` : null;
                            }}
                          /> */}

                          {/* )} */}

                          {loader ? (
                            <></>
                          ) : (
                            <>
                              <Modal
                                centered
                                visible={pdfUrl?.length > 0 && isOpenPdfModal}
                                width={1000}
                                onCancel={() => {
                                  setPdfUrl(null)
                                  setIsOpenPdfModal(false)
                                }}
                              >
                                <Worker
                                  workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
                                // className={style.pdf_viever_padding}
                                >
                                  <div style={{ height: '750px' }}>
                                    <Viewer fileUrl={pdfUrl} plugins={[
                                      // Register plugins
                                      defaultLayoutPluginInstance,
                                    ]} />
                                  </div>
                                </Worker>
                              </Modal>
                            </>
                          )}

                          <Modal
                            centered
                            visible={isModalVisible}
                            width={1000}
                            onOk={() => setIsModalVisible(false)}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                          >
                            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
                            {/* <div style={{ height: "100%", width: '100%' }}> */}
                            {/* <Viewer fileUrl={PdfString} /> */}
                            {/* </div> */}
                            {/* </Worker> */}

                            <div className="pt-4">
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={PdfString}
                                  plugins={[
                                    // Register plugins
                                    defaultLayoutPluginInstance,
                                  ]}
                                />
                              </Worker>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>

                  }

                  {previousDoctorList?.length > 0 && 

                  <div className={style.card}>
                    <div
                      className="card-body pt-0 pb-0"
                    >
                      <div className={style.Segment_Div}>
                        <div
                          style={{
                            // backgroundColor: "#eff1f7",
                            display: "flex",
                            padding: 5,
                            borderRadius: 10,
                            cursor: "pointer",
                            width: '100%'
                          }}
                        >

                          <div
                            style={{ width: '100%' }}
                            className="tab_static"
                            onClick={() => ClickTab("CallBack")}
                          >
                            <p className={`${style.text_color} m-0 p-0 h4`} >
                              Previous Walkin Appointments
                            </p>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <div className="spinner">
                          <Spin tip="Loading..."></Spin>
                        </div>
                      ) : (
                        <>

                       
                          {previousDoctorList.map((item) => {
                            return (
                              <div key={item.appointment_id} className={`${style.groupofcard} row justify-content-center mb-4`}
                              // onClick={() => handleCardClick(item)} 
                              >
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12">

                                  {/* <div className="card-body" style={{ backgroundColor: "#134f85", borderRadius: "10px", }}> */}
                                  <div className="card-body" style={{
                                    backgroundColor:
                                      item?.status === "REJECTED"
                                        ? "#F5E8E9"
                                        : item?.status === "NEW" || item?.status == "DRAFT"
                                          ? "#FCF9EF"
                                          : item?.status === "ASSIGNED" || item?.status == "CONFIRMED"
                                            ? "#EAF5EA"
                                            : item?.status === "COMPLETED" ||
                                              item?.status === "FULFILLED" ||
                                              item?.status === "FULLFILLED"
                                              ? "#F1F6FD"
                                              : "#cddcea", // Default color if callbackstatus doesn't match any of the conditions
                                    borderRadius: "10px",
                                    border: "1px solid"
                                  }}>
                                    <div className="row">
                                      <div className={`col-12 col-md-3 ${style.div_flex_start}`}>
                                        <div
                                          style={{
                                            backgroundColor:
                                              item?.status === "REJECTED"
                                                ? "#D2222D"
                                                : item?.status === "NEW"  || item?.status === "DRAFT"
                                                  ? "#FFBF00"
                                                  : item?.status === "ASSIGNED" || item?.status === "CONFIRMED"
                                                    ? "var(--green)"
                                                    : item?.status === "COMPLETED" ||
                                                      item?.status === "FULFILLED" ||
                                                      item?.status === "FULLFILLED"
                                                      ? "#0C6DFD"
                                                      : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                          }}
                                          className={`${style.btn_day}`}
                                        >
                                          <p className={`${style.text_day}`}>{moment(item?.callbackdate).format("ddd")}</p>
                                        </div>

                                        <div>
                                          <h4 style={{ color: "black", fontWeight: "bold", fontSize: 20 }}>
                                            {item?.callbacktime}
                                          </h4>
                                          {/* <h4 style={{ color: "#666666"}}>
                                          {moment(item?.callbackdate).format("DD-MMM-YYYY")}
                                        </h4> */}
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            {moment.utc(item?.callbackdate).format("DD-MMM-YYYY")}
                                          </h4>
                                        </div>

                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        <div>
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            Appointment Type
                                          </h4>
                                          <h4 style={{ color: "black", fontSize: 20 }}>
                                            Schedule Request
                                          </h4>
                                        </div>
                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        {item.appointment_id !== null ? (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <span className="mx-3">
                                              <CommentOutlined
                                                className={`${style.iconComment} ${style.iconchat2}`}
                                                onClick={() => openchatopen(item)}
                                              />
                                            </span>
                                            <span>
                                              <img
                                                src={EyeIcon}
                                                className={style.img_eye}
                                                onClick={() => handleCardClick(item)}
                                              />


                                              {/* <FontAwesomeIcon className="mr-2" icon={faHandshake} /> */}
                                              {/* <EyeOutlined className={`${style.iconComment} ${style.iconchat2}`} style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} /> */}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className={`d-flex justify-content-center align-items-center ${style.iconchat2}`}  >
                                            {/* Content for when appointment_id is null */}-
                                          </div>
                                        )}
                                      </div>
                                      <div className={`col-12 col-md-3 text-md-right ${style.div_center}`}>
                                        <div className="row">
                                          <div className="col-md-6">
                                            {item.join_meeting == 1 ?
                                              (
                                                <Button
                                                  style={{

                                                    borderRadius: "30px",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    textTransform: "capitalize"
                                                  }}
                                                  className={style.animated_tada_delay}
                                                  onClick={() => joinMeeting(item, 'user')}
                                                // className={`${style.btn_status}  animate__animated animate__bounce animate__faster`}
                                                >
                                                  JOIN MEETING
                                                </Button>
                                                // <button
                                                // className={style.img_eyee}
                                                // onClick={() => joinMeeting(item, 'user')}
                                                // style={{width: "100%"}}
                                                // >
                                                //   Join Meeting
                                                // </but>
                                              )
                                              :
                                              (<></>)}
                                          </div>
                                          <div className="col-md-6">
                                            <button
                                              style={{
                                                minWidth: '130px',
                                                backgroundColor:
                                                  item?.status === "REJECTED"
                                                    ? "#D2222D"
                                                    : item?.status === "NEW" || item?.status === "DRAFT"
                                                      ? "#FFBF00"
                                                      : item?.status === "ASSIGNED" || item?.status === "CONFIRMED"
                                                        ? "var(--green)"
                                                        : item?.status === "COMPLETED" ||
                                                          item?.status === "FULFILLED" ||
                                                          item?.status === "FULLFILLED"
                                                          ? "#0C6DFD"
                                                          : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                                borderRadius: "30px",
                                                fontSize: "15px",
                                                color: "white",
                                                textTransform: "capitalize"
                                              }}
                                              className={`${style.btn_status}`}
                                            >
                                              {item?.status}
                                            </button>
                                          </div>
                                        </div>


                                      </div>
                                    </div>


                                    {/* <div className="row">
                                    <div className="col-12 col-md-6"></div>
                                    <div className="col-12 col-md-6 text-md-right d-flex justify-content-end align-items-center mt-3">
                                      {item.appointment_id !== null ? (
                                        <div className="d-flex justify-content-end align-items-center text-white ">
                                          <span className="mx-3 text-white">
                                            <CommentOutlined
                                              className={`${style.iconComment} ${style.iconchat2}`}
                                              onClick={() => openchatopen(item)}
                                            />
                                          </span>
                                          <span>
                                            <EyeOutlined style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} />
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="d-flex justify-content-center align-items-center text-white">
                                        </div>
                                      )}
                                    </div>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                          {/* <Table
                            columns={callBackColumn}
                            dataSource={callBackDetails}
                            scroll={{ x: 400 }}
                            rowClassName={(record) => {
                              const isAssigned = record.callbackstatus === 'ASSIGNED';
                              const appointmentDate = moment(record.callbackstart, 'YYYY-MM-DD HH:mm A');
                              const isTodayOrTomorrow =
                                moment().isSame(appointmentDate, 'day') ||
                                moment().add(1, 'day').isSame(appointmentDate, 'day');

                              return isAssigned && isTodayOrTomorrow ? `${style.highlightedrow}` : null;
                            }}
                          /> */}

                          {/* )} */}

                          {loader ? (
                            <></>
                          ) : (
                            <>
                              <Modal
                                centered
                                visible={pdfUrl?.length > 0 && isOpenPdfModal}
                                width={1000}
                                onCancel={() => {
                                  setPdfUrl(null)
                                  setIsOpenPdfModal(false)
                                }}
                              >
                                <Worker
                                  workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
                                // className={style.pdf_viever_padding}
                                >
                                  <div style={{ height: '750px' }}>
                                    <Viewer fileUrl={pdfUrl} plugins={[
                                      // Register plugins
                                      defaultLayoutPluginInstance,
                                    ]} />
                                  </div>
                                </Worker>
                              </Modal>
                            </>
                          )}

                          <Modal
                            centered
                            visible={isModalVisible}
                            width={1000}
                            onOk={() => setIsModalVisible(false)}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                          >
                            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
                            {/* <div style={{ height: "100%", width: '100%' }}> */}
                            {/* <Viewer fileUrl={PdfString} /> */}
                            {/* </div> */}
                            {/* </Worker> */}

                            <div className="pt-4">
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={PdfString}
                                  plugins={[
                                    // Register plugins
                                    defaultLayoutPluginInstance,
                                  ]}
                                />
                              </Worker>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>

                  }

                  {callBackDetails?.length > 0 && 

                  <div className={style.card}>
                    <div
                      className="card-body pt-0"
                    >
                      <div className={style.Segment_Div}>
                        <div
                          style={{
                            // backgroundColor: "#eff1f7",
                            display: "flex",
                            padding: 5,
                            borderRadius: 10,
                            cursor: "pointer",
                            width: '100%'
                          }}
                        >


                          <div
                            style={{ width: '100%' }}
                            className="tab_static"
                            onClick={() => ClickTab("CallBack")}
                          >
                            <p className={`${style.text_color} m-0 p-0 h4`} >
                              Past Appointments
                            </p>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <div className="spinner">
                          <Spin tip="Loading..."></Spin>
                        </div>
                      ) : (
                        <>

                          {/* <Table
                            columns={callBackColumn}
                            dataSource={callBackDetails}
                            scroll={{ x: 400 }}
                            pagination={false}
                            //   rowClassName={(record) => {
                            //     if(record.callbackstatus === 'ASSIGNED'){
                            //       return `${style.highlightedrowblue}`
                            //     }else if(record.callbackstatus === "NEW"){
                            //       return `${style.highlightedrowyellow}`
                            //     }else if(record.callbackstatus === "REJECTED"){
                            //       return `${style.highlightedrowred}`
                            //     }else{
                            //       return `${style.highlightedrow}`
                            //     }
                            // }}
                            rowClassName={(record) => {
                              if (record.callbackstatus === 'ASSIGNED') {
                                return `${style.highlightedrowgreen}`
                              } else if (record.callbackstatus === "NEW") {
                                return `${style.highlightedrowyellow}`
                              } else if (record.callbackstatus === "REJECTED") {
                                return `${style.highlightedrowred}`
                              } else if (record.callbackstatus === "COMPLETED") {
                                return `${style.highlightedrow}`
                              } else {
                                return `${style.highlightedrowblue}`
                              }
                            }}
                          /> */}

                          {/* {callBackDetails.map((item) => {
                            console.log('item1112222', item);


                            return (

                              <div className={`${style.groupofcard} row justify-content-center mb-5`} onClick={handleCardClick}  >
                                <div className="col-6 col-md-6 col-sm-6 col-lg-6">

                                  <div className="card-body" style={{ backgroundColor: "#134f85", borderRadius: "10px", }}>
                                    <div className="row">
                                      <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                                        <h4 style={{ color: "white" }} className="mb-0">{item.patientname}</h4>
                                        <p className="text-white" style={{ color: "white" }}>{userData.Address1}</p>
                                      </div>
                                      <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                        <button
                                          style={{
                                            backgroundColor:
                                              item.callbackstatus === "REJECTED"
                                                ? "rgb(241, 143, 143)"
                                                : item.callbackstatus === "NEW"
                                                  ? "rgb(237, 237, 68)"
                                                  : item.callbackstatus === "ASSIGNED"
                                                    ? "#699a69"
                                                    : item.callbackstatus === "COMPLETED" || item.callbackstatus === "FULFILLED" || item.callbackstatus === "FULLFILLED"
                                                      ? "#89bae7"
                                                      : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                            borderRadius: "10px",
                                            float: "right",
                                            fontSize: "15px",
                                            color: "white",
                                            padding: "5px"
                                          }}
                                        >
                                          {item.callbackstatus}
                                        </button>
                                      </div>
                                    </div><br></br>
                                    <br></br>
                                    <br></br>
                                    <div className="row " style={{ borderBottom: "1px solid white", }}>
                                      <div className="col-6 col-md-6 col-sm-6 col-ld-6 mb-3">
                                        <div className="timedate" style={{ color: "white" }}>
                                          {moment(item?.callbackdate).format(
                                            "DD-MMM-YYYY"
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                        <div className="timedate" style={{ color: "white", float: "right" }}>
                                          <i style={{ color: "#ffffff" }}>
                                            <FontAwesomeIcon
                                              className="mr-1"
                                              icon={faClock}
                                            // onClick={showDrawer}
                                            />
                                          </i>{item.callbacktime}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row" >
                                      <div className="col-10"></div>
                                      <div className="col-2 col-md-2 col-sm-2 col-lg-2, mt-3" style={{ color: "white", float: "right", }}>View More <i>
                                        <FontAwesomeIcon
                                          className=""
                                          icon={faArrowRight}
                                        // onClick={showDrawer}
                                        />
                                      </i>
                                      </div>
                                    </div>


                                  </div>

                                </div>

                              </div>
                            )
                          })} */}

                          {callBackDetails.map((item) => {
                            return (
                              <div key={item.appointment_id} className={`${style.groupofcard} row justify-content-center mb-4`}
                              // onClick={() => handleCardClick(item)} 
                              >
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12">

                                  {/* <div className="card-body" style={{ backgroundColor: "#134f85", borderRadius: "10px", }}> */}
                                  <div className="card-body" style={{
                                    backgroundColor:
                                      item?.callbackstatus === "REJECTED"
                                        ? "#F5E8E9"
                                        : item?.callbackstatus === "NEW"
                                          ? "#FCF9EF"
                                          : item?.callbackstatus === "ASSIGNED"
                                            ? "#EAF5EA"
                                            : item?.callbackstatus === "COMPLETED" ||
                                              item?.callbackstatus === "FULFILLED" ||
                                              item?.callbackstatus === "FULLFILLED"
                                              ? "#F1F6FD"
                                              : "#cddcea", // Default color if callbackstatus doesn't match any of the conditions
                                    borderRadius: "10px",
                                    border: "1px solid"
                                  }}>
                                    <div className="row">
                                      <div className={`col-12 col-md-3 ${style.div_flex_start}`}>
                                        <div
                                          style={{
                                            backgroundColor:
                                              item?.callbackstatus === "REJECTED"
                                                ? "#D2222D"
                                                : item?.callbackstatus === "NEW"
                                                  ? "#FFBF00"
                                                  : item?.callbackstatus === "ASSIGNED"
                                                    ? "var(--green)"
                                                    : item?.callbackstatus === "COMPLETED" ||
                                                      item?.callbackstatus === "FULFILLED" ||
                                                      item?.callbackstatus === "FULLFILLED"
                                                      ? "#0C6DFD"
                                                      : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                          }}
                                          className={`${style.btn_day}`}
                                        >
                                          <p className={`${style.text_day}`}>{moment(item?.callbackdate).format("ddd")}</p>
                                        </div>

                                        <div>
                                          <h4 style={{ color: "black", fontWeight: "bold", fontSize: 20 }}>
                                            {item?.callbacktime}
                                          </h4>
                                          {/* <h4 style={{ color: "#666666" }}>
                                          {moment(item?.callbackdate).format("DD-MMM-YYYY")}
                                        </h4> */}
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            {moment.utc(item?.callbackdate).format("DD-MMM-YYYY")}
                                          </h4>
                                        </div>

                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        <div>
                                          <h4 style={{ color: "#666666", fontSize: 20 }}>
                                            Appointment Type
                                          </h4>
                                          <h4 style={{ color: "black", fontSize: 20 }}>
                                            Callback Request
                                          </h4>
                                        </div>
                                      </div>
                                      <div className={`col-12 col-md-3 ${style.div_center}`} >
                                        {item.appointment_id !== null ? (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <span className="mx-3">
                                              <CommentOutlined
                                                className={`${style.iconComment} ${style.iconchat2}`}
                                                onClick={() => openchatopen(item)}
                                              />
                                            </span>
                                            <span>

                                              <img
                                                src={EyeIcon}
                                                className={style.img_eye}
                                                onClick={() => handleCardClick(item)}
                                              />

                                              {/* <EyeOutlined className={`${style.iconComment} ${style.iconchat2}`} style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} /> */}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className={`d-flex justify-content-center align-items-center ${style.iconchat2}`}  >
                                            {/* Content for when appointment_id is null */}-
                                          </div>
                                        )}
                                      </div>
                                      <div className={`col-12 col-md-3 text-md-right ${style.div_center}`}>
                                        <button
                                          style={{
                                            minWidth: '130px',
                                            backgroundColor:
                                              item?.callbackstatus === "REJECTED"
                                                ? "#D2222D"
                                                : item?.callbackstatus === "NEW"
                                                  ? "#FFBF00"
                                                  : item?.callbackstatus === "ASSIGNED"
                                                    ? "var(--green)"
                                                    : item?.callbackstatus === "COMPLETED" ||
                                                      item?.callbackstatus === "FULFILLED" ||
                                                      item?.callbackstatus === "FULLFILLED"
                                                      ? "#0C6DFD"
                                                      : "transparent", // Default color if callbackstatus doesn't match any of the conditions
                                            borderRadius: "30px",
                                            fontSize: "15px",
                                            color: "white",
                                            textTransform: "capitalize"
                                          }}
                                          className={`${style.btn_status}`}
                                        >
                                          {item?.callbackstatus}
                                        </button>
                                      </div>
                                    </div>


                                    {/* <div className="row">
                                      <div className="col-12 col-md-6"></div>
                                      <div className="col-12 col-md-6 text-md-right d-flex justify-content-end align-items-center mt-3">
                                        {item.appointment_id !== null ? (
                                          <div className="d-flex justify-content-end align-items-center text-white ">
                                            <span className="mx-3 text-white">
                                              <CommentOutlined
                                                className={`${style.iconComment} ${style.iconchat2}`}
                                                onClick={() => openchatopen(item)}
                                              />
                                            </span>
                                            <span>
                                              <EyeOutlined style={{ fontSize: '20px' }} onClick={() => handleCardClick(item)} />
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center text-white">
                                          </div>
                                        )}
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                          {/* )} */}

                          {loader ? (
                            <></>
                          ) : (
                            <>
                              <Modal
                                centered
                                visible={pdfUrl?.length > 0 && isOpenPdfModal}
                                width={1000}
                                onCancel={() => {
                                  setPdfUrl(null)
                                  setIsOpenPdfModal(false)
                                }}
                              >
                                <Worker
                                  workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
                                // className={style.pdf_viever_padding}
                                >
                                  <div style={{ height: '750px' }}>
                                    <Viewer fileUrl={pdfUrl} plugins={[
                                      // Register plugins
                                      defaultLayoutPluginInstance,
                                    ]} />
                                  </div>
                                </Worker>
                              </Modal>
                            </>
                          )}

                          <Modal
                            centered
                            visible={isModalVisible}
                            width={1000}
                            onOk={() => setIsModalVisible(false)}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                          >

                            <div className="pt-4">
                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                <Viewer
                                  fileUrl={PdfString}
                                  plugins={[
                                    defaultLayoutPluginInstance,
                                  ]}
                                />
                              </Worker>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>

                  }


                </div>
              </div>
              {addClick == true ? (
                <AddFamilyMember
                  title="Add Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={{}}
                />
              ) : null}

              {/* {editClick == true ? (
                <AddFamilyMember
                  title="Edit Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={selectedFamilyMember}
                />
              ) : null} */}
              {/* {click !== true ? (
                <AddFamilyMember
                  title="Add Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={{}}
                />
              ) : (
                <AddFamilyMember
                  title="Edit Family Member"
                  open={familyModel}
                  close={familyModelClose}
                  getFamilyMember={getFamilyMember}
                  editFamilyMember={selectedFamilyMember}
                />
              )
              } */}
              <PatientDetail
                // title="Appointment Detail"
                title=""
                open={isDetailVisible}
                close={patientDetailClose}
                appointmentId={selectAppointmentId}
                type={type}
              />
              {/* event modal */}
              <Modal
                title="Workshop Information"
                visible={eventModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={900}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="listinfo">
                      <strong>Faciliator Name :</strong>
                      {particluarEvent?.facilitator_name}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="listinfo">
                      <strong>Date :</strong>
                      {/* {moment(particluarEvent?.start_time).format("ll")} */}
                      {moment(particluarEvent?.start_time).format(
                        "DD-MMM-YYYY"
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="listinfo">
                      <strong>Start Time :</strong>
                      {moment(particluarEvent?.start_time).format("hh:mm A")}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="listinfo">
                      <strong>End Time :</strong>
                      {moment(particluarEvent?.end_time).format("hh:mm A")}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>Workshop Name :</strong>
                      {particluarEvent?.name}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>Description :</strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: particluarEvent?.description,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>What will you learn :</strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: particluarEvent?.what_will_you_learn,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="listinfo">
                      <strong>Recurrance :</strong>
                      {particluarEvent?.recurrence_frequency?.toUpperCase() ||
                        ""}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>

            {/* Chat Modal */}
            {openChat ? (
              <div className={style.chat_popup} id="myForm">
                <div className={style.form_container}>
                  <div className="col-lg-12 col-xl-12" style={{ padding: 0 }}>
                    <div className="chat-window">
                      <div className="chat-cont-right">
                        <div className="chat-header">
                          <a
                            id="back_user_list"
                            href="#0"
                            className="back-user-list"
                          >
                            <i className="material-icons">chevron_left</i>
                          </a>
                          <div className="media">
                            <div className="media-img-wrap">
                              {/* <div className="avatar avatar-online"> */}
                              <div className="avatar">
                                <img
                                  src={IMG011}
                                  alt="User"
                                  className="avatar-img rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <div className="user-name">{selectedChatUser?.providername}</div>
                              {/* <div className="user-name">
                                Appointment with Dr ABC
                              </div> */}
                              {/* <div className="user-status">online</div> */}
                            </div>
                          </div>
                          <div className="chat-options">
                            <Button
                              className={style.btnTransparent}
                              onClick={handleCancelChatModal}
                            >
                              <img
                                src={IMG012}
                                alt="User"
                                className={`${style.cross_img} avatar-img`}
                              />
                            </Button>
                          </div>
                        </div>
                        {/* <div className="chat-body" style={{height : 650}}> */}
                        <div className="chat-body">
                          <div className="chat-scroll">
                            <ul className="list-unstyled">
                              {allMessages?.map((itemm, index) => (
                                <div key={index}>
                                  {parseInt(itemm?.author) === user?.id ? (
                                    <li className="media sent">
                                      <div className="media-body">
                                        <div className="msg-box">
                                          <div>
                                            <h6>
                                              {itemm?.authorName} (
                                              {itemm?.authorType})
                                            </h6>
                                            <p>{itemm?.content}</p>
                                            <ul className="chat-msg-info">
                                              <li>
                                                <div className="chat-time">
                                                  <span>
                                                    {getTime(itemm?.createdAt)}
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ) : (
                                    <li className="media received" style={{ marginTop: 10 }}>
                                      <div className="avatar">
                                        <img
                                          src={IMG011}
                                          alt="User"
                                          className="avatar-img rounded-circle"
                                        />
                                      </div>
                                      <div className="media-body" style={{ marginBottom: 15 }}>
                                        <div className="msg-box">
                                          <div>
                                            <h6>
                                              {itemm?.authorName} (
                                              {itemm?.authorType})
                                            </h6>
                                            <p>{itemm?.content}</p>
                                            <ul className="chat-msg-info">
                                              <li>
                                                <div className="chat-time">
                                                  <span>
                                                    {getTime(itemm?.createdAt)}
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </div>
                              ))}

                              <div ref={messagesEndRef}></div>
                            </ul>
                          </div>
                        </div>
                        <div className="chat-footer">
                          <form name="f2" id="f12">
                            <div className="input-group">
                              {selectedChatUser?.callbackstatus == "FULFILLED" || selectedChatUser?.callbackstatus == "FULLFILLED" ? <>
                                <span>Chat is disabled</span>
                              </> :
                                <>
                                  <input
                                    type="text"
                                    className="input-msg-send form-control"
                                    placeholder="Type something"
                                    value={messageInput}
                                    disabled={selectedChatUser?.callbackstatus == "FULFILLED" || selectedChatUser?.callbackstatus == "FULLFILLED" ? true : false}
                                    onChange={(evt) =>
                                      setMessageInput(evt?.target?.value)
                                    }
                                  />
                                  <div className="input-group-append">
                                    <button
                                      type="submit"
                                      onClick={(e) => {
                                        sendMessage(e);
                                      }}
                                      disabled={isSending}
                                      className=" w-25 btn msg-send-btn"
                                    >
                                      <i>
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faShare}
                                        />
                                      </i>
                                    </button>
                                  </div>
                                </>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <form  >
                  <h1>Chat</h1>

                  <label><b>Message</b></label>
                  <textarea placeholder="Type message.." name="msg" required></textarea>

                  <div className="chat-footer">
                    <form name="f2" id="f12">
                      <button type="submit" className="btn">Send</button>
                    </form>
                  </div>
                </form> */}
              </div>
            ) : null}
          </div>
        </div>

      </div>

      <Modal
        footer={null}
        title="Note"
        visible={
          noteModal &&
          isProfileComplate === "false" &&
          showEdit &&
          (user?.Insurancesvcnum === "" || user?.Insurancesvcnum == null)
        }
        onCancel={handleCancelModal}
        width="1100px"
      >
        <div>Your profile is incomplete</div>
        <br />
        <div>
          An incomplete profile may prevent you from creating appointment with
          doctor
        </div>
        <br />
        <div>
          please update your{" "}
          <span style={{ color: "#1a6ab1" }}>
            Address, Date-of-birth, Gender, PhoneNumber, Ohip Card, City,
            CanadaProvinces
          </span>{" "}
          in your profile.
        </div>
        <br />

        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            style={{ width: "200px" }}
            // loading={btnLoader}
            onClick={() => EditProfile()}
          // htmlType="submit"
          >
            Complete My Profile
          </Button>
        </div>
      </Modal>

      {/* <Modal
        footer={null}
        title="Note"
        style={{ top: 800,right : 10 }}
        visible={openChat}
        onCancel={handleCancelChatModal}
        // width="1100px"
        width="500px"
        
        >
        <div>
          Appointment With Dr Mdoctor
        </div>
      </Modal> */}

      <div className="footer" style={{ backgroundColor: 'yellow' }}>
        <Footer />
      </div>
    </>
  );
};
export default Dashboard;
