import Dropdown from "react-bootstrap/Dropdown";
import style from "./style.module.scss";
import IMG02 from "../../images/user.png";
import UserNotFound from "../../images/user_not_found.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  authentication,
  bookAppointmentDoctor,
  callbackAppointment,
  loadWhichVersion,
  selectAppointmentType,
  selectedService,
  selectPaymentProvider,
  setProblem,
  setUser,
  setMeetingEndData,
  setCallID,
  setEndModal,
  setSelectedFamilyMember,
  AppointmentRequestType,
  setInsurance,
  setChatHead,
} from "../../redux/actions/userActions";
import SSEHandler from "../../lib/SSEHandler";
import WebrtcCallHandler from "../../lib/WebrtcCallHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCalendar,
  faCalendarAlt,
  faEquals,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/action-types";
import * as AuthService from "../../services/auth";
import store from "store";

import { Checkbox, Modal, notification, Spin, Drawer, message } from "antd";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import moment from "moment";
import 'moment-timezone';
// import moment from "moment-timezone";


export const TopBar = (props:any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let userInfo = useSelector((state: any) => state.userReducer.user);
  const userData = useSelector((state: any) => state.userReducer.user);

  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [notificationdata, setNotificationdata] = useState([]);
  const [isnotification, setIsnotification] = useState(false);
  const userAgent = navigator.userAgent;

  useEffect(() => {
    const storedTime = localStorage.getItem("current_time");
    console.log("Stored time:", storedTime);

    fetchNotification(storedTime);
  }, []);


  const logEvents = async (data) => {
    console.log(data, "TOP BARRRR")
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e: any) {
      console.log(e, "ERRORRR")
    }
  }

  const fetchNotification = async (storedTime) => {

    console.log(userData, "USERDATAAAAA", `User - ${userData?.id} - Fetch Notifications - Header Component`)
    
    try {
      const response = await UPDATEDGET(
        `fetchnotifications?userid=${userData.id}&date=${storedTime}`
      );

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Header Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `fetchnotifications?userid=${userData.id}&date=${storedTime}`,
        "requestParameters": {},
        "responsestatus": response?.status
      }
  
      let body = {
        message: rawLogs,
        // message: `User - ${userData?.sub} - Fetch Notifications - Header Component`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Header Component`,
      }
      await logEvents(body);

      console.log(response.data.data, "response.data.date");

      setIsnotification(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    // console.log("click to logout");
    AuthService.logout();
    store.clearAll();
    dispatch(authentication(false));
    WebrtcCallHandler.getInstance().cleanUp();
    SSEHandler.getInstance().cleanUp();
    dispatch(setUser([]));
    dispatch(selectPaymentProvider([]));
    dispatch(selectAppointmentType([]));
    dispatch(bookAppointmentDoctor([]));
    dispatch(selectedService([]));
    dispatch(loadWhichVersion([]));
    dispatch(callbackAppointment([]));
    dispatch(setProblem([]));
    dispatch(setMeetingEndData([]));
    dispatch(setCallID([]));
    dispatch(setEndModal(false));
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));
    dispatch(setChatHead(null));
  };

  const selectType = () => {
    // history.push("/selectType");
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));
    dispatch(selectPaymentProvider({}));
    dispatch(selectAppointmentType({}));
    dispatch(setInsurance({}));
    history.push("/appoiment-type");
  };

  const goToDashboard = () => {
    history.push("/");
  };

  //   let page = 1;
  //   let limit = 10;

  //   try {
  //     await UPDATEDGET(
  //       `notifications?userid=${userData.id}&page=${page}&limit=${limit}`
  //     ).then((data) => {
  //       console.log(data, "notification data");
  //       setNotificationdata(data?.data);
  //     });

  //     setUserAgreementModal(true);

  //     // ?userid=1091&page=2&limit=10
  //     // console.log('notificationget',notificationget);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const LazyLoadingDrawer = () => {
  // const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationData, setNotificationData] = useState([]);
  const pageSize = 10;

  // Your notificationopen function and other code
  // };

  const notificationopen = async () => {

    // Fetch initial data
    try {
      const response = await UPDATEDGET(
        `notifications?userid=${userData.id}&page=${currentPage}&limit=${pageSize}`
      );

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Header Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `notifications?userid=${userData.id}&page=${currentPage}&limit=${pageSize}`,
        "requestParameters": {},
        "responsestatus": response?.status
      }
  
      let body = {
        // message: `User - ${userData?.sub} - Open Notifications - Header Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Header Component`,
      }
      await logEvents(body);

      if (response?.data) {
        setNotificationData(response.data);
        setUserAgreementModal(true);
      }

      setIsnotification(false);
      const currentTime = new Date();
      const formattedTime = currentTime.toISOString();
      localStorage.setItem(
        "current_time",
        moment(formattedTime).format("YYYY-MM-DD HH:mm:ss")
      );
      console.log(
        "Current time stored:",
        moment(formattedTime).format("YYYY-MM-DD HH:mm:ss")
      );
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreData = async () => {

    try {
      const nextPage = currentPage + 1;
      const response = await UPDATEDGET(
        `notifications?userid=${userData.id}&page=${nextPage}&limit=${pageSize}`
      );

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Header Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `notifications?userid=${userData.id}&page=${nextPage}&limit=${pageSize}`,
        "requestParameters": {},
        "responsestatus": response?.status
      }
  
      let body = {
        // message: `User - ${userData?.sub} - Load More Notifications - Header Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Header Component`,
      }
      await logEvents(body);

      if (response?.data) {
        setNotificationData((prevData) => [...prevData, ...response.data]);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDrawerClose = () => {
    setUserAgreementModal(false);
    setNotificationData([]);
    setCurrentPage(1);
  };
  return (
    <>
      <header className="header">
        <nav className={`navbar navbar-expand-lg header-nav`}>
          <div className={style.mainContaner}>
            <div className={style.logoDiv}>
              <div className={`${style.toggebtn} d-block d-xl-none`}>
                <i style={{fontSize:'20px'}}>
                  <FontAwesomeIcon
                    className="mr-3"
                    icon={faEquals}
                    onClick={props.onShow}
                  />
                </i>
              </div>
              <img
                src="../Images/logo.png"
                height="50"
                alt=""
                onClick={goToDashboard}
              />
            </div>
            <div className="row smallDivices">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {
                  !props?.isBookAppointment &&(
                    <button
                  type="submit"
                  className={`btnDashboardBook ${style.btnBookAppointment}`}
                  onClick={selectType}
                >
                  {/* <FontAwesomeIcon className={`mr-3 ${style.iconCal}`} icon={faCalendarAlt} /> */}
                  Book Appointment
                </button>
                  )
                }
                
              </div>

              <div className={style.borderRightSame}></div>
              <div className={style.divIcon}>
              <img src="../Images/bell.png" className={style.iconNoti} onClick={notificationopen} />
                {/* <button onClick={notificationopen}>
                  <img src="../Images/bell.png" className={style.iconNoti} />
                </button> */}
                {isnotification ? (
                  <span
                    style={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      position: "absolute",
                      marginTop: "15px",
                      marginLeft: "15px",
                    }}
                  ></span>
                ) : (
                  <></>
                )}
                {/* <FontAwesomeIcon icon={faBell} className={style.iconNoti} /> */}
              </div>

              {/* <Drawer
                placement="right"
                visible={userAgreementModal}
                width={400}
                closable={false}
                onClose={() => setUserAgreementModal(false)}
                bodyStyle={{
                  padding: "16px",
                  background: "white",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
                headerStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                footer={
                  <div style={{ textAlign: "right" }}>
                    <button onClick={() => setUserAgreementModal(false)}>
                      Close
                    </button>
                  </div>
                }
              >
                <div>
                  <h3 className="ant-modal-header text-center">
                    Notifications
                  </h3>
                </div>

                {notificationdata.map((item) => (
                  <div className={style.card}>
                    <div className="card-body pt-0">
                      <div className={style.Segment_Div}>
                        <div
                          style={{
                            backgroundColor: "#FAF9F6",
                            display: "flex",
                            padding: 5,
                            borderRadius: 10,
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <div style={{ width: "100%" }} className="tab_static">
                            <p className="m-0 p-0 h4">{item.details}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Drawer> */}
              <Drawer
                // placement="right"
                visible={userAgreementModal}
                onClose={handleDrawerClose}
                // Other Drawer props
                placement="right"
                // visible={userAgreementModal}
                width={350}
                closable={false}
                // onClose={() => setUserAgreementModal(false)}
                bodyStyle={{
                  padding: "0px",
                  background: "white",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
                headerStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                footer={
                  <div style={{ textAlign: "right" }}>
                    <button
                      style={{ borderRadius: "5px", padding: "5px" }}
                      onClick={() => setUserAgreementModal(false)}
                    >
                      Close
                    </button>
                  </div>
                }
              >
                {/* Drawer content */}
                <div>
                  <h3 className="ant-modal-header text-center font-weight-bold">
                    Notifications
                  </h3>
                </div>

                {/* {notificationData.map((item, index) => (
        // Render your notification item using `item`
        <div style={{ width: "100%" }} className="tab_static">
                            <p className="m-0 p-0 h4">{item.details}</p>
                          </div>
      ))} */}
                <div style={{ overflowX: "scroll", maxHeight: "85vh" }}>
                  {notificationData.map((item, index) => (
                    <div className={style.card}>
                      {/* <div className="`{style.card-body} pt-0 mx-1 my-1"> */}
                      <div className={style.cardbody}>
                        <div className={style.Segment_Div}>
                          <div
                            style={{
                              backgroundColor: "#FAF9F6",
                              display: "flex",
                              padding: 5,
                              borderRadius: 10,
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: "flex-end",
                              }}
                              // className="tab_static"
                            >
                              <p
                                className="m-0 p-0 h4"
                                style={{ fontSize: "14px", textAlign: "left" }}
                              >
                                {item.details}
                              </p>
                              <div
                                style={{
                                  marginTop: "auto",
                                  textAlign: "right",
                                }}
                              >
                                {/* <p style={{ paddingTop: "20px" }}>
                                  {moment(item.createdate).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </p> */}

                              <p style={{ paddingTop: "20px" }}>
                                {moment(item.createdate)
                                  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {notificationData.length >= pageSize && (
                    <div style={{ textAlign: "center", margin: "20px 0" }}>
                      <button
                        className="p-1"
                        style={{ borderRadius: "5px" }}
                        onClick={loadMoreData}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              </Drawer>

              <div className={style.borderRightSame}></div>
              <Dropdown
                className={`user-drop nav-item dropdown has-arrow logged-item mt-1`}
              >
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{
                    textAlign: "right",
                    marginRight: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                  }}
                >
                  {userData?.profilepicture != "" &&
                  userData?.profilepicture != null ? (
                    <img
                      className={style.image_round}
                      src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                      alt="User"
                    />
                  ) : (
                    <img
                      className={style.image_round}
                      src={UserNotFound}
                      width="20"
                      alt="Darren Elder"
                    />
                  )}
                  <div>
                    <div>
                      <div className="row">
                        <div className="col-sm-12 d-none d-lg-block">
                          <p style={{ margin: 0, marginLeft: 10 }}>
                            {userInfo?.FirstName?.charAt(0).toUpperCase() +
                              userInfo?.FirstName?.slice(1)}{" "}
                            {userInfo?.LastName?.charAt(0).toUpperCase() +
                              userInfo?.LastName?.slice(1)}
                          </p>
                          {/* <p
                            style={{
                              margin: 0,
                              marginLeft: 10,
                              textAlign: "left",
                              fontSize: 13,
                            }}
                          >
                            Patient
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="user-header">
                    <div className="">
                      {userData?.profilepicture != "" &&
                      userData?.profilepicture != null ? (
                        <img
                          className={style.userProfile}
                          src={`${process.env.REACT_APP_ASSET_URL}/${userData.profilepicture}`}
                          alt="User"
                        />
                      ) : (
                        <img
                          src={IMG02}
                          alt="User"
                          className={style.image_round}
                        />
                      )}
                    </div>
                    <div className="user-text">
                      <h6 className={style.userNamee}>
                        {userInfo?.FirstName?.charAt(0).toUpperCase() +
                          userInfo?.FirstName?.slice(1)}{" "}
                        {userInfo?.LastName?.charAt(0).toUpperCase() +
                          userInfo?.LastName?.slice(1)}
                      </h6>
                      {/* <p className={`${style.pspeciality} text-muted mb-0`}>
                        Patient
                      </p> */}
                    </div>
                  </div>
                  {/* <Dropdown.Item href="/dashboard" className={style.item_down}>
                    Dashboard
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item
                    href="/profile"
                    className={style.item_down}
                  >
                    Edit Profile
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    href="/view-profile"
                    className={style.item_down}
                  >
                    Profile Settings
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/"
                    className={style.item_down}
                    onClick={logout}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
