import { TopBar } from "components";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Spin,
  Radio,
  Tooltip,
  RadioChangeEvent,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UPDATEDGET, UPDATEDPOST } from "services/common.api";
import {
  AppointmentRequestType,
  setSelectedFamilyMember,
} from "redux/actions/userActions";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { Line } from 'react-chartjs-2';
import { MessageOutlined } from '@ant-design/icons'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4themes_material from '@amcharts/amcharts4/themes/material'
import am4themes_Datavizl from '@amcharts/amcharts4/themes/dataviz'
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
import { RINGER } from "../img";
import PageHeader from "components/pageHeader";
import Footer from "components/footerFix";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const Vitals = () => {

  const [groupArrays, setGroupArrays] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selFamilyMember, setSelFamilyMember] = useState(null);

  const state = {

    series: [{
      data: groupArrays
    }]
  };

  const optionsApp: any = {
    chart: {
      type: 'candlestick',
      height: 350
    },
    title: {
      text: 'CandleStick Chart',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  }

  const { TextArea } = Input;
  const [formRate] = useForm();
  const [formBP] = useForm();
  const [formPulse] = useForm();
  const [formOxygen] = useForm();
  const [formGlucose] = useForm();
  const [formTemp] = useForm();
  const [formRes] = useForm();
  const [changed, setChanged] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { Option } = Select;
  const userData = useSelector((state: any) => state.userReducer.user);

  const user: any = useSelector((state: any) => state.userReducer.user);

  const [formValues, setFormValues] = useState({} as any);
  const [rateCount, setRateCount] = useState(1);
  const [medi, setMedi] = useState(1);
  const [emojis, setEmojis] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderGet, setLoaderGet] = useState(false);
  const [vitalList, setVitalList] = useState([]);
  const [vitalDetail, setVitalDetails] = useState({} as any);
  const [modalInput, setModalInput] = useState(false);
  const [modalForWhich, setModalForWhich] = useState("Blood Pressure");
  const [modalTitle, setModalTitle] = useState("Blood Pressure");
  const [moodValue, setMoodValue] = useState(1);
  const [errors, setErrors] = useState([]);
  const [lowestValue, setLowetstValue] = useState("Sad");
  const [highestValue, setHighestValue] = useState("Happy");
  const [forHistory, setForHistory] = useState("bp");
  const [dataSetAll, setDataSetAll] = useState([]);
  const [dataSetAllForVitalHistory, setDataForVitalHistory] = useState([]);
  const [subscription, setSubscription] = useState(null)
  const [flip, setFlipvalue] = useState(6); 

  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY',userAgent);
    setUserAgent(userAgent);
    }, []);
  

  const vitalsListColumn = [
    {
      title: "Date/Time",
      dataIndex: "dateeTime",
      width: 300,
      key: "dateeTime",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {moment(text, "DD-MMM-YYYY hh:mm A").format('DD-MMM-YYYY')}{" "}
          <div>
            <p style={{ color: "#009efb", margin: 0 }}>
              {moment(text, "DD-MMM-YYYY hh:mm A").format("hh:mm A")}
            </p>
          </div>
        </a>
      ),
    },
    // {
    //   title: dataSetAll?.length != 0 ? dataSetAll[0]?.vital : 'vital',
    //   dataIndex: ['sysbp','dysbp','vitalvalue'],
    //   key: "bp",
    //   render: (text, row) => (
    //     <>
    //       {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? 
    //         <a style={{ fontSize: 15, cursor: "unset" }}>{row['sysbp'] +"/"+ row['dysbp']+ ' mmHg'}</a> : 
    //         <>
    //           {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'heartrate' ? 
    //             <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']+ ' bpm'}</a>
    //             : <>
    //               {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'spo2' ? 
    //                 <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']+ ' % SpO2'}</a>
    //                 : <>
    //                   {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'temp' ? 
    //                     <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']+ ' F'}</a>
    //                       : <>
    //                       {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'respiratory' ? 
    //                         <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']+ ' Rpm'}</a>
    //                         : <>
    //                           {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'glucose' ? 
    //                             <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']+ ' Mmol/L'}</a>
    //                             :<>
    //                               <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']}</a>
    //                             </>
    //                           }
    //                         </>
    //                       }
    //                     </>
    //                   }
    //                 </>
    //               }
    //             </>
    //           }
    //         </>
    //       }
    //     </>
    //   ),
    // },
    dataSetAll[0]?.vital == 'bp' ? {

      title: "Blood Pressure (mmHg)",
      children: [
        {
          title: "Systolic",
          dataIndex: ['sysbp'],
          key: "sysbp",
          align: "center",
          render: (text, row) => (
            <>
              <a style={{ fontSize: 15, cursor: "unset" }}>{row['sysbp']}</a>
            </>
          ),
        },
        {
          title: "Diastolic",
          dataIndex: ['dysbp'],
          key: "dysbp",
          align: "center",
          render: (text, row) => (
            <>
              <a style={{ fontSize: 15, cursor: "unset" }}>{row['dysbp']}</a>
            </>
          ),
        }
      ]
    } :
      {
        title: dataSetAll?.length != 0 ? (dataSetAll[0]?.vital == 'heartrate' ? 'Heart Rate (bpm)' :
          dataSetAll[0]?.vital == 'spo2' ? 'Oxygen Saturation (% SpO2)' :
            dataSetAll[0]?.vital == 'temp' ? 'Temperature (F)' :
              dataSetAll[0]?.vital == 'respiratory' ? 'Respiratory Rate (Rpm)' :
                dataSetAll[0]?.vital == 'glucose' ? 'Glucose Levels (mmol/L)' : dataSetAll[0]?.vital) : 'vital',
        dataIndex: ['vitalvalue'],
        key: "other",
        render: (text, row) => (
          <>
            {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'heartrate' ?
              <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']}</a>
              : <>
                {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'spo2' ?
                  <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']}</a>
                  : <>
                    {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'temp' ?
                      <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']}</a>
                      : <>
                        {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'respiratory' ?
                          <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']}</a>
                          : <>
                            {dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'glucose' ?
                              <a style={{ fontSize: 15, cursor: "unset" }}>{row['vitalvalue']}</a>
                              : <>
                                <a style={{ fontSize: 15, cursor: "unset" }}><img
                                  src={"../Images/" + row['vitalvalue'] + ".png"}
                                  height="25"
                                  alt=""
                                  style={{ marginLeft: 5 }}
                                /></a>
                              </>
                            }
                          </>
                        }
                      </>
                    }
                  </>
                }
              </>
            }
          </>
        ),
      },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (text, row) => (
        <>
          {row.notes != null && row.notes != 'null' ?
            <Tooltip title={text}>
              <a className="text-blue">
                <MessageOutlined />
              </a>
            </Tooltip>
            : "-"}
        </>
      )
    },
    {
      title: "Updated By",
      width: 250,
      dataIndex: ["FirstName", "LastName"],
      key: "user",
      render: (text, row) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>{row['FirstName'] + " " + row['LastName']}</a>
      ),
    }
  ];

  console.log(dataSetAll,'dataSetAll456');
  
  
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin="anonymous"

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3';
    audio.play()
      .catch(error => {
        // Handle any playback errors
        console.log(error,"audio play error");
      });
  }


  useEffect(() => {
    if(userData?.id){
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }
  
        const newSubscription = (API.graphql(graphqlOperation(subscriptions.subscribeToNewNotification, { userId: userData?.id + "" })) as any).subscribe({
          next: (notificationData) => {
            const newNotification = notificationData.value.data.subscribeToNewNotification;
            console.log('New notification received:', newNotification);
  
            console.log(localStorage.getItem("chatHead"),"localStorage.getItem",newNotification?.conversationId);
            if(parseInt(newNotification?.conversationId) == parseInt(localStorage.getItem("chatHead"))){}else{
              notification.open({
                message: 'New Message',
                description: newNotification?.message,
                duration: 5, 
                icon: <MessageOutlined style={{ color: '#1890ff' }} />,
                placement: 'topRight',
              });
              // audio.play();
              playAudio()
            }
  
          },
          error: (error) => {
            console.error('Error subscribing to new notification:', error);
          },
        });
  
        setSubscription(newSubscription);
      };
  
      subscribeToNotifications();
  
      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null); 
        }
      };
    }
   
  }, [subscription,userData?.id]); 

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // const emojisClick = (em) => {
  //   setModalInput(em);
  //   setEmojis(em);
  //   setModalForWhich("emoji");
  //   setModalTitle("Emotional and Physical Health");
  // };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top' as const,
  //     },
  //     title: {
  //       display: true,
  //       text: 'Vitals Chart',
  //     },
  //   },
  //   scaleShowValues: true,
  //   onAnimationComplete: function () {
  //     this.showTooltip(100, true);
  //   },
  //   tooltipEvents: []
  // };

  // const labels = dataSetAll?.map(o => o.date);

  // const data = {
  //   labels,
  //   datasets: dataSetAll?.length != 0 && dataSetAll[0]?.vital != 'bp'
  //     ? [
  //       {
  //         label: dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? 'Sysbp' : (dataSetAll[0]?.vital ? dataSetAll[0]?.vital : ""),
  //         data: dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? dataSetAll.map(o => parseInt(o.sysbp)) : dataSetAll.map(o => o.vitalvalue),
  //         borderColor: 'rgb(60, 179, 113)',
  //         backgroundColor: 'transparent',
  //       }
  //     ]
  //     :
  //     [
  //       {
  //         label: dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? 'Sysbp' : (dataSetAll[0]?.vital ? dataSetAll[0]?.vital : ""),
  //         data: dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? dataSetAll.map(o => parseInt(o.sysbp)) : dataSetAll.map(o => o.vitalvalue),
  //         borderColor: 'rgb(60, 179, 113)',
  //         backgroundColor: 'transparent',
  //       },
  //       {
  //         label: dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? 'Dysbp' : '',
  //         data: dataSetAll?.length != 0 && dataSetAll[0]?.vital == 'bp' ? dataSetAll.map(o => parseInt(o.dysbp)) : dataSetAll.map(o => o.vitalvalue),
  //         borderColor: 'rgb(53, 162, 235)',
  //         backgroundColor: 'transparent',
  //       },
  //     ]
  // };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    if (user?.id !== undefined) {
      callAllApis();
    }
  }, []);

  const callAllApis = async () => {
    // await getVitalList();
    // await getVitalsHistory('bp');
    await getFamilyMember();
  }

  useEffect(() => {
    if (selFamilyMember !== null) {
      console.log(forHistory, "forHistoryforHistoryforHistoryforHistory")
      // getVitalList();
      getVitalsHistory(forHistory)
    }
  }, [selFamilyMember]);

  const handleChangeMemeber = (value) => {
    setChanged(true);
    setErrors([]);
    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));
  };

  const handleChangeGlucose = (value) => {
    console.log(value, "valueeee");
  };
  const validateMessages = {
    required: "${label} is required!",
  };
  const addVitals = (data) => {

    

    console.log(data, "datatatatat");
    setLoader(true);
    UPDATEDPOST("patient/vitals", data).then(
      (data) => {
        
        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${user?.role}`,
            "createdate":new Date().toISOString()
            },
            "action": "INSERT",
            "source": "Vitals Page",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": `patient/vitals`,
            "requestParameters": {
              data
            },
            "responsestatus": `${data?.status}`,
            // "response": JSON.stringify(response?.data)
        }
    
        let logBody = {
          // message: `User - ${userData?.sub} - Add Vitals - Vitals Page`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Vitals Page`,
        }
    
        logEvents(logBody);

        setLoader(false);
        notification.success({
          message: "Success",
          duration: 2,
          description: "Vitals added Successfully",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
        closeModal();
        getVitalList();
      },
      (err) => {
        setLoader(false);
      }
    );
  };

  const getVitalsHistory = async (vital) => {

    setDataSetAll([]);
    setDataForVitalHistory([]);

    let data = {
      id: selFamilyMember ? selFamilyMember : user?.id,
      vital: vital,
      timestamps: moment().add(-6, 'months').format('x')
    }

    await UPDATEDPOST("patient/vitals/history/" + data.id, data).then(
      (res) => {
        
        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${user?.role}`,
            "createdate":new Date().toISOString()
            },
            "action": "INSERT",
            "source": "Vitals Page",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": "patient/vitals/history/" + data.id,
            "requestParameters": {
              data
            },
            "responsestatus": `${res?.status}`,
            // "response": JSON.stringify(response?.data)
        }
    
        let logBody = {
          // message: `User - ${userData?.sub} - Fetch Vitals - Vitals Page`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Vitals Page`,
        }
        logEvents(logBody);

        console.log(res, "success vitals/history");
        if (res?.data?.hasOwnProperty("errorMessage")) {
          setDataSetAll([]);
          setDataForVitalHistory([]);
        } else {
          res?.data?.forEach(element => {
            var timeStamp = parseInt(element?.timestamps);
            let datee = moment(timeStamp).format("DD-MMM-YYYY");
            let dateeTime = moment(timeStamp).format("DD-MMM-YYYY hh:mm A");

            element.date = datee
            element.dateeTime = dateeTime
          });
          setDataForVitalHistory(res.data);

          let sortedArray = res?.data;
          // let newlatestData = sortedArray.sort(function (a, b) {
          //   return new Date(a.dateeTime).getTime() - new Date(b.dateeTime).getTime()
          // });
          let newlatestData = sortedArray.sort(function (a, b) {
            return parseInt(a.timestamps) - parseInt(b.timestamps)
          });

          console.log(newlatestData, "sortedArraysortedArray")

          // setDataSetAll(res?.data);
          setDataSetAll(newlatestData);
          amchartVitals(newlatestData, vital)
        }
      },
      (err) => {
        console.log(err, "Error in get Vital history");
      }
    );
  };

  const handleFailedSubmitBp = async (event) => {
    console.log(formBP.getFieldError("BloodPressureDys"));
    setFormValues({});
    setErrors([
      ...formBP.getFieldError("BloodPressureSys"),
      ...formBP.getFieldError("BloodPressureDys"),
      ...formBP.getFieldError("medication"),
    ]);
    setChanged(false);
  };
  const handleFailedSubmitPulse = async (event) => {
    setFormValues({});
    setErrors(formPulse.getFieldError("PulseRate"));
    setChanged(false);
    console.log(formPulse.getFieldError("PulseRate"));
  };
  const handleFailedSubmitOxygen = async (event) => {
    setFormValues({});
    setErrors(formOxygen.getFieldError("OxygenLevels"));
    setChanged(false);
  };
  const handleFailedSubmitTemp = async (event) => {
    setFormValues({});
    setErrors(formTemp.getFieldError("Temperature"));
    setChanged(false);
  };
  const handleFailedSubmitRes = async (event) => {
    setFormValues({});
    setErrors(formRes.getFieldError("RespiratoryRate"));
    setChanged(false);
  };
  const handleFailedSubmitGlucose = async (event) => {
    setFormValues({});
    setErrors([
      ...formGlucose.getFieldError("GlucoseLevels"),
      ...formGlucose.getFieldError("cstatus"),
    ]);
    setChanged(false);
  };
  const handleSubmitBp = async (event) => {
    console.log(event, "evevevevevevevevveve bp", validateMessages);
    let bpData = {
      vital: "bp",
      timestamp: Date.now() + "",
      userid: userData?.id,
      notes: event.bpnotes,
      vitalvalue: null,
      mood: null,
      moodvalue: null,
      id: selFamilyMember ? selFamilyMember : user?.id,
      vitaltype: null,
      sysbp: event.BloodPressureSys,
      dysbp: event.BloodPressureDys,
      glucosetype: null,
    };
    addVitals(bpData);
  };
  const handleSubmitPulse = async (event) => {
    console.log(event, "evevevevevevevevveve Pulse");
    let bpData = {
      vital: "heartrate",
      timestamp: Date.now() + "",
      userid: userData?.id,
      notes: event.pulsenotes,
      vitalvalue: parseInt(event.PulseRate),
      mood: null,
      moodvalue: null,
      id: selFamilyMember ? selFamilyMember : user?.id,
      vitaltype: null,
      sysbp: null,
      dysbp: null,
      glucosetype: null,
    };
    addVitals(bpData);
  };
  const handleSubmitOxygen = async (event) => {
    console.log(event, "evevevevevevevevveve o");
    let bpData = {
      vital: "spo2",
      timestamp: Date.now() + "",
      userid: userData?.id,
      notes: event.oxygennotes,
      vitalvalue: parseInt(event.OxygenLevels),
      mood: null,
      moodvalue: null,
      id: selFamilyMember ? selFamilyMember : user?.id,
      vitaltype: null,
      sysbp: null,
      dysbp: null,
      glucosetype: null,
    };
    addVitals(bpData);
  };
  const handleSubmitTemp = async (event) => {
    console.log(event, "evevevevevevevevveve t");
    let bpData = {
      vital: "temp",
      timestamp: Date.now() + "",
      userid: userData?.id,
      notes: event.tempnotes,
      vitalvalue: event.Temperature,
      mood: null,
      moodvalue: null,
      id: selFamilyMember ? selFamilyMember : user?.id,
      vitaltype: null,
      sysbp: null,
      dysbp: null,
      glucosetype: null,
    };
    addVitals(bpData);
  };
  const handleSubmitRes = async (event) => {
    console.log(event, "evevevevevevevevveve re");
    let bpData = {
      vital: "respiratory",
      timestamp: Date.now() + "",
      userid: userData?.id,
      notes: event.resnotes,
      vitalvalue: parseInt(event.RespiratoryRate),
      mood: null,
      moodvalue: null,
      id: selFamilyMember ? selFamilyMember : user?.id,
      vitaltype: null,
      sysbp: null,
      dysbp: null,
      glucosetype: null,
    };
    addVitals(bpData);
  };
  const handleSubmitGlucose = async (event) => {
    console.log(event, "evevevevevevevevveve gh");
    let bpData = {
      vital: "glucose",
      timestamp: Date.now() + "",
      userid: userData?.id,
      notes: event.glunotes,
      vitalvalue: event.GlucoseLevels,
      mood: null,
      moodvalue: null,
      id: selFamilyMember ? selFamilyMember : user?.id,
      vitaltype: null,
      sysbp: null,
      dysbp: null,
      glucosetype: null,
    };
    addVitals(bpData);
  };
  const handleSubmitMood = async (event) => {
    console.log(event, "evevevevevevevevveve handleSubmitMood");
    if (event.rating !== undefined) {
      let moodData = {
        vital: emojis,
        timestamp: Date.now() + "",
        userid: userData?.id,
        notes: event.emojinotes,
        vitalvalue: event?.rating,
        mood: null,
        moodvalue: null,
        id: selFamilyMember ? selFamilyMember : user?.id,
        vitaltype: null,
        sysbp: null,
        dysbp: null,
        glucosetype: null,
      };
      addVitals(moodData);
    } else if (event) {
      setChanged(false);
      setErrors(["Please select your choice"]);
    }
  };
  const getTime = (timestamp) => {
    var timeStamp = parseInt(timestamp);
    let datee = moment(timeStamp).format("DD-MMM-YYYY hh:mm A");
    return datee;
  };
  const handleChange = (event) => {
    console.log(event, "rararartetetet");
    
    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues");
    setChanged(true);
    setRateCount(moodValue);

    console.log(moodValue,"mood valueeee");
  };

  const getVitalList = async () => {
    try {
      setLoaderGet(true);
      console.log(selFamilyMember, "=============")
      let url;
      if (selFamilyMember != null) {
        url = `patient/vitals?patientid=${selFamilyMember}`;

        

      } else {
        setSelFamilyMember(user?.id)
        url = `patient/vitals?patientid=${user?.id}`
      }
      

      await UPDATEDGET(url).then(
        (response) => {
          console.log("Vitals list...: ", response);

          let rawLogs = {
      
            "userIdentity": {
                "loggedinuser": `${userData?.Email}`,
                "role":`${user?.role}`,
                "createdate":new Date().toISOString()
                },
                "action": "ACCESS",
                "source": "Vitals Page",
                "sourceIPAddress": "3.99.184.26",
                "userAgent": userAgent,
                "ipAddress": localStorage.getItem('ipAddress'),
                "apiendpoint": url,
                "requestParameters": {
                  
                },
                "responsestatus": `${response?.status}`,
                // "response": JSON.stringify(response?.data)
          }
    
          let logBody = {
            // message: `User - ${userData?.sub} - Get Vitals - Vitals Page`,
            message: rawLogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Vitals Page`,
          }
          logEvents(logBody);

          if (response?.data?.hasOwnProperty("errorMessage")) {
            setVitalList([]);
            setVitalDetails({});
          } else {
            response?.data?.forEach((element) => {
              var timeStamp = parseInt(element?.timestamp);
              element.createddate = timeStamp;
            });
            setVitalList(response?.data);
            if (response?.data?.length != 0) {
              setVitalDetails(response?.data[0]);
            } else {
              setVitalList([]);
              setVitalDetails({});
            }
          }
          setLoaderGet(false);


        }
      );
    } catch (error) {
      setLoaderGet(false);
      console.log(error, "error in get vitals list");
    }
  };

  console.log(dataSetAll,'dataSetAll45646');
  
  

  const openModal = () => {
    setModalInput(true);
    setChanged(false);
  };

  const BtnClicked = (forWhich) => {
    if (selFamilyMember !== null) {
      setModalForWhich(forWhich);
      if (forWhich == "Blood Pressure") {
        setModalTitle("Blood Pressure");
        getVitalsHistory('bp')
        setForHistory('bp')
        formBP.resetFields();
      } else if (forWhich == "Pulse Rate") {
        setModalTitle("Pulse Rate");
        getVitalsHistory('heartrate')
        setForHistory('heartrate')
        formPulse.resetFields();
      } else if (forWhich == "Oxygen Saturation") {
        setModalTitle("Oxygen Saturation");
        getVitalsHistory('spo2')
        setForHistory('spo2')
        formOxygen.resetFields();
      } else if (forWhich == "Temperature") {
        setModalTitle("Temperature");
        getVitalsHistory('temp')
        setForHistory('temp')
        formTemp.resetFields();
      } else if (forWhich == "Respiratory Rate") {
        setModalTitle("Respiratory Rate");
        getVitalsHistory('respiratory')
        setForHistory('respiratory')
        formRes.resetFields();
      } else if (forWhich == "Glucose Levels") {
        setModalTitle("Glucose Levels");
        getVitalsHistory('glucose')
        setForHistory('glucose')
        formGlucose.resetFields();
      } else if (forWhich == "Mood") {
        setEmojis("Mood");
        setModalForWhich("emoji");
        setModalTitle("Mood");
        setFlipvalue(0);
        setLowetstValue("Sad");
        setHighestValue("Happy");
        getVitalsHistory('Mood');
        setForHistory('Mood');
      } else if (forWhich == "Social") {
        setEmojis("Social");
        setModalForWhich("emoji");
        setModalTitle("Social");
        setFlipvalue(0);
        setLowetstValue("No Interaction");
        setHighestValue("High Interaction");
        getVitalsHistory('Social');
        setForHistory('Social');
      } else if (forWhich == "Anxiety") {
        setEmojis("Anxiety");
        setModalForWhich("emoji");
        setModalTitle("Anxiety");
        setFlipvalue(6);
        setLowetstValue("Mild");
        setHighestValue("Panic");
        getVitalsHistory('Anxiety');
        setForHistory('Anxiety');
      } else if (forWhich == "Mobility") {
        setEmojis("Mobility");
        setModalForWhich("emoji");
        setModalTitle("Mobility");
        setFlipvalue(0);
        setLowetstValue("Low Mobility");
        setHighestValue("High Mobility");
        getVitalsHistory('Mobility');
        setForHistory('Mobility');
      } else if (forWhich == "Pain") {
        setEmojis("Pain");
        setModalForWhich("emoji");
        setFlipvalue(6);
        setModalTitle("Pain");
        setLowetstValue("No Pain");
        setHighestValue("Severe Pain");
        getVitalsHistory('Pain');
        setForHistory('Pain');
      } else if (forWhich == "Appetite") {
        setEmojis("Appetite");
        setModalForWhich("emoji");
        setModalTitle("Appetite");
        setFlipvalue(0);
        setLowetstValue("Low");
        setHighestValue("Normal");
        getVitalsHistory('Appetite');
        setForHistory('Appetite');
      } else if (forWhich == "Sleep") {
        setEmojis("Sleep");
        setModalForWhich("emoji");
        setModalTitle("Sleep");
        setLowetstValue("Awake");
        setFlipvalue(0);
        setHighestValue("Deep Sleep");
        getVitalsHistory('Sleep');
        setForHistory('Sleep');
      } else if (forWhich == "Rashes") {
        setEmojis("Rashes");
        setModalForWhich("emoji");
        setFlipvalue(6);
        setModalTitle("Rashes");
        setLowetstValue("No Rashes");
        setHighestValue("Infectious Rashes");
        getVitalsHistory('Rashes');
        setForHistory('Rashes');
      }
    }

  };

  const closeModal = () => {
    setModalInput(false);
    setRateCount(1);
    formRate.resetFields();
    // setModalForWhich("");
    setErrors([]);
    setFormValues({});
    getVitalsHistory(forHistory);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setErrors([]);
    setChanged(true);
    setMoodValue(e.target.value);
    console.log("flip", flip);
    flip>0?setRateCount(6-e.target.value):setRateCount(e.target.value);
    console.log(moodValue);
  };

  const onChangeMedication = (e: RadioChangeEvent) => {
    setErrors([]);
    setChanged(true);
    setMedi(e.target.value);
  };

  const getFamilyMember = async () => {

    

    try {
      const data = await UPDATEDGET(`userfamilymembers?id=${user?.id}`);
      console.log(data, "FamilyMembers");

      let rawLogs = {
        "userIdentity": {
        "loggedinuser": `${userData?.Email}`,
        "role":`${user?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Vitals Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `url`,
        "requestParameters": {
          
        },
        "responsestatus": `${data?.status}`,
        // "response": JSON.stringify(response?.data)
      }
  
  
      let logBody = {
        // message: `User - ${userData?.sub} - Fetch Family Member Detail - Vitals Page`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Vitals Page`,
      }
      logEvents(logBody);

      if (data?.data?.body?.FamilyMembers) {
        setFamilyMembers(data?.data?.body?.FamilyMembers);
      } else {
        setFamilyMembers([]);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const datasetKeyProvider = () => {
    return Math.random();
  }
  const submitDate = (period,vit) => {
    console.log("submitDate")
    let start, end;
    switch (period) {
      case 7:
        console.log(dataSetAll);
        // getAnalyticsData(moment().subtract(7, 'd').format('YYYY-MM-DD'),
        // 	moment().format('YYYY-MM-DD'))
        start = new Date(moment().subtract(7, 'd').format('YYYY-MM-DD'));
        end = new Date(moment().format('YYYY-MM-DD'));
        console.log(start,end);
        var dat = dataSetAll.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(dataSetAll);
        console.log(dat);
        amchartVitals(dat, vit);
        break;
      case 30:
        console.log(dataSetAll);
        // getAnalyticsData(moment().subtract(1, 'months').format('YYYY-MM-DD'),
        // 	moment().format('YYYY-MM-DD'))
        start = new Date(moment().subtract(1, 'months').format('YYYY-MM-DD'));
        end = new Date(moment().format('YYYY-MM-DD'));
        var dat = dataSetAll.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(start,end);
        console.log(dat);
        amchartVitals(dat, vit);
        break;
        case 90:
          console.log(dataSetAll);
          // getAnalyticsData(moment().subtract(1, 'months').format('YYYY-MM-DD'),
          // 	moment().format('YYYY-MM-DD'))
          start = new Date(moment().subtract(3, 'months').format('YYYY-MM-DD'));
          end = new Date(moment().format('YYYY-MM-DD'));
          var dat = dataSetAll.filter((item: any) => {
            let date = new Date(item.date);
            return date >= start && date <= end;
          });
          console.log(start,end);
          console.log(dat);
          amchartVitals(dat, vit);
          break;
          case 1:
            console.log(dataSetAll);
        start = new Date(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        end = new Date(moment().format('YYYY-MM-DD'));
        var dat = dataSetAll.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(start,end);
        console.log(dat);
        amchartVitals(dat, vit);
        break;
        case 6:
          console.log(dataSetAll);
        start = new Date(moment().subtract(6, 'months').format('YYYY-MM-DD'));
        end = new Date(moment().format('YYYY-MM-DD'));
        var dat = dataSetAll.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(start,end);
        console.log(dat);
        amchartVitals(dat, vit);
        break;
      default:
    }
  }
  const amchartVitals = (dat, vit) => {
    console.log("AMCHART - " , vit);
    const c1 = am4core.create('chartdiv', am4charts.XYChart)
    c1.colors.step = 2
    c1.dateFormatter.dateFormat = "yyyy-MM-dd";

    const xAxis = c1.xAxes.push(new am4charts.DateAxis())
    xAxis.renderer.grid.template.location = 0
    xAxis.baseInterval.timeUnit="hour";
    xAxis.baseInterval.count=1;
    // xAxis.dateFormatter = new am4core.DateFormatter();
    //   xAxis.dateFormatter.dateFormat = "MM-dd";
    
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis())
    var title = c1.titles.create();          
            title.fontSize = 20;
    switch (vit) {
      case "bp":
        {
          yAxis.min = 5;
          yAxis.max = 180;
          xAxis.dateFormats.setKey("day", "MMM dd");
          xAxis.skipEmptyPeriods = true;
          xAxis.periodChangeDateFormats.setKey("day", "MMM dd"); 

         title.text="Blood Pressure (mmHg)"
          var range = yAxis.axisRanges.create();
          range.value = 80;
          range.endValue = 120;
          range.axisFill.fill = am4core.color("#d7f3b5");
          range.axisFill.fillOpacity = 0.2;
          range.grid.strokeOpacity = 0;
          var range = yAxis.axisRanges.create();
          range.value = 80;
          range.grid.stroke = am4core.color("#396478");
          range.grid.strokeWidth = 1;
          range.grid.strokeOpacity = 1;
          range.label.inside = true;
          range.label.text = "[bold]diastolic [/]";
          range.label.fill = range.grid.stroke;
          //range.label.align = "right";
          range.label.verticalCenter = "bottom";

          var range2 = yAxis.axisRanges.create();
          range2.value = 120;
          range2.grid.stroke = am4core.color("#A96478");
          range2.grid.strokeWidth = 1;
          range2.grid.strokeOpacity = 1;
          range2.label.inside = true;
          range2.label.text = "[bold]systolic[/]";
          range2.label.fill = range2.grid.stroke;
          //range2.label.align = "right";
          range2.label.verticalCenter = "bottom";
          c1.data = dat

          // Create series
          // var lineSeries = c1.series.push(new am4charts.LineSeries());
          // lineSeries.dataFields.valueY = "sysbp";
          // lineSeries.dataFields.dateX = "date";
          // lineSeries.name = "systolic";
          // lineSeries.strokeWidth = 2;
          // lineSeries.tensionX = 0.8;
          // lineSeries.tensionY = 1;
          // var lineSeries1 = c1.series.push(new am4charts.LineSeries());
          // lineSeries1.dataFields.valueY = "dysbp";
          // lineSeries1.dataFields.dateX = "date";
          // lineSeries1.name = "DIASTOLIC";
          // lineSeries1.strokeWidth = 2;
          // lineSeries1.tensionX = 0.8;
          // lineSeries1.tensionY = 1;
          
          var colseries = c1.series.push(new am4charts.ColumnSeries());
          colseries.name ="Series 1";
          colseries.xAxis= xAxis;
          colseries.yAxis = yAxis;
          colseries.dataFields.openValueY = "dysbp";
          colseries.dataFields.valueY = "sysbp";
          colseries.dataFields.dateX = "date";
          colseries.columns.template.width=0.5;
          

          var circleBullet = colseries.bullets.push(new am4charts.CircleBullet());
          circleBullet.circle.stroke = am4core.color("#fff");
          circleBullet.circle.strokeWidth = 1;
          circleBullet.circle.scale = 1
          circleBullet.locationY=0
          circleBullet.tooltipText = "BP:{sysbp} / {dysbp}";
          var circleBullet1 = colseries.bullets.push(new am4charts.CircleBullet());
          circleBullet1.circle.strokeWidth = 1;
          circleBullet1.circle.scale = 1
          circleBullet1.locationY=1
        }
        break;
      case "heartrate":
        {
          yAxis.min = 5;
          yAxis.max = 150;
         title.text="Heart Rate (bpm)"
          var range = yAxis.axisRanges.create();
          range.value = 60;
          range.endValue = 90;
          range.axisFill.fill = am4core.color("#d7f3b5");
          range.axisFill.fillOpacity = 0.2;
          range.grid.strokeOpacity = 0;
          var range = yAxis.axisRanges.create();
          range.value = 60;
          range.grid.stroke = am4core.color("#396478");
          range.grid.strokeWidth = 2;
          range.grid.strokeOpacity = 1;
          range.label.inside = true;
          range.label.fill = range.grid.stroke;
          //range.label.align = "right";
          range.label.verticalCenter = "bottom";

          var range2 = yAxis.axisRanges.create();
          range2.value = 90;
          range2.grid.stroke = am4core.color("#A96478");
          range2.grid.strokeWidth = 2;
          range2.grid.strokeOpacity = 1;
          range2.label.inside = true;
          range2.label.fill = range2.grid.stroke;
          //range2.label.align = "right";
          range2.label.verticalCenter = "bottom";


          c1.data = dat

          // Create series
          var lineSeries = c1.series.push(new am4charts.LineSeries());
          lineSeries.dataFields.valueY = "vitalvalue";
          lineSeries.dataFields.dateX = "date";
          lineSeries.name = "Pulse";
          lineSeries.strokeWidth = 2;
          // lineSeries.tensionX = 0.8;
          // lineSeries.tensionY = 1;

          var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
          circleBullet.circle.stroke = am4core.color("#fff");
          circleBullet.circle.strokeWidth = 1;
          circleBullet.circle.scale = 1
          circleBullet.tooltipText = "{date} : {vitalvalue}bpm";

          var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
          //labelBullet.label.text = "{vitalvalue}";
          labelBullet.label.dy = -20;
          //labelBullet.label.fontFamily = 'Roboto'
        }
        break;
      case "respiratory" :
        {
          console.log("raspiratory ++++++++ +++");
         title.text="Respiratory Rate (Rpm)";
          var range = yAxis.axisRanges.create();
          range.value = 12;
          range.endValue = 16;
          range.axisFill.fill = am4core.color("#d7f3b5");
          range.axisFill.fillOpacity = 0.2;
          range.grid.strokeOpacity = 0;
          var range = yAxis.axisRanges.create();
          range.value = 12;
          range.grid.stroke = am4core.color("#396478");
          range.grid.strokeWidth = 2;
          range.grid.strokeOpacity = 1;
          range.label.inside = true;
          range.label.fill = range.grid.stroke;
          //range.label.align = "right";
          range.label.verticalCenter = "bottom";

          var range2 = yAxis.axisRanges.create();
          range2.value = 16;
          range2.grid.stroke = am4core.color("#A96478");
          range2.grid.strokeWidth = 2;
          range2.grid.strokeOpacity = 1;
          range2.label.inside = true;
          range2.label.fill = range2.grid.stroke;
          //range2.label.align = "right";
          range2.label.verticalCenter = "bottom";

          c1.data = dat

          // Create series
          var lineSeries = c1.series.push(new am4charts.LineSeries());
          lineSeries.dataFields.valueY = "vitalvalue";
          lineSeries.dataFields.dateX = "date";
          lineSeries.name = "Raspiration";
          lineSeries.strokeWidth = 2;
          // lineSeries.tensionX = 0.8;
          // lineSeries.tensionY = 1;

          var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
          circleBullet.circle.stroke = am4core.color("#fff");
          circleBullet.circle.strokeWidth = 1;
          circleBullet.circle.scale = 1
          circleBullet.tooltipText = "{date}: {vitalvalue}bpm";

          var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
          //labelBullet.label.text = "{vitalvalue}";
          labelBullet.label.dy = -20;
          //labelBullet.label.fontFamily = 'Roboto'
        }
        break;

      case "spo2":
        {
          yAxis.min = 90;
          yAxis.max = 100;
         title.text="Oxygen Saturation (% SpO2)";

          var range = yAxis.axisRanges.create();
          range.value = 94;
          range.endValue = 100;
          range.axisFill.fill = am4core.color("#d7f3b5");
          range.axisFill.fillOpacity = 0.2;
          range.grid.strokeOpacity = 0;
          var range = yAxis.axisRanges.create();
          range.value = 100;
          range.grid.stroke = am4core.color("#396478");
          range.grid.strokeWidth = 2;
          range.grid.strokeOpacity = 1;
          range.label.inside = true;
          range.label.fill = range.grid.stroke;
          //range.label.align = "right";
          range.label.verticalCenter = "bottom";

          var range2 = yAxis.axisRanges.create();
          range2.value = 94;
          range2.grid.stroke = am4core.color("#A96478");
          range2.grid.strokeWidth = 2;
          range2.grid.strokeOpacity = 1;
          range2.label.inside = true;
          range2.label.fill = range2.grid.stroke;
          //range2.label.align = "right";
          range2.label.verticalCenter = "bottom";
          
          c1.data = dat

          // Create series
          var lineSeries = c1.series.push(new am4charts.LineSeries());
          lineSeries.dataFields.valueY = "vitalvalue";
          lineSeries.dataFields.dateX = "date";
          lineSeries.name = "spo2";
          lineSeries.strokeWidth = 2;
          // lineSeries.tensionX = 0.8;
          // lineSeries.tensionY = 1;

          var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
          circleBullet.circle.stroke = am4core.color("#fff");
          circleBullet.circle.strokeWidth = 1;
          circleBullet.circle.scale = 1
          circleBullet.tooltipText = "{date} : spo2: {vitalvalue}%";

          var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
          //labelBullet.label.text = "{date} : {vitalvalue}";
          labelBullet.label.dy = -20;
          //labelBullet.label.fontFamily = 'Roboto'
        }
        break;
      case "temp":
        {
          // yAxis.min = 50;
           yAxis.max = 102;
         title.text="Temperature (F)";

          // var range = yAxis.axisRanges.create();
          // range.value = 60;
          // range.endValue = 85;
          // range.axisFill.fill = am4core.color("#d7f3b5");
          // range.axisFill.fillOpacity = 0.2;
          // range.grid.strokeOpacity = 0;

          var range2 = yAxis.axisRanges.create();
          range2.value = 98.6;
          range2.grid.stroke = am4core.color("#A96478");
          range2.grid.strokeWidth = 2;
          range2.grid.strokeOpacity = 1;
          range2.label.fill = range2.grid.stroke;
          c1.data = dat

          // Create series
          var lineSeries = c1.series.push(new am4charts.LineSeries());
          lineSeries.dataFields.valueY = "vitalvalue";
          lineSeries.dataFields.dateX = "date";
          lineSeries.name = "Temprature";
          lineSeries.strokeWidth = 2;
          // lineSeries.tensionX = 0.8;
          // lineSeries.tensionY = 1;

          var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
          circleBullet.circle.stroke = am4core.color("#fff");
          circleBullet.circle.strokeWidth = 1;
          circleBullet.circle.scale = 1
          circleBullet.tooltipText = "{date} : {vitalvalue}F ";

          var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
          //labelBullet.label.text = "{vitalvalue}";
          labelBullet.label.dy = -20;
          //labelBullet.label.fontFamily = 'Roboto'
        }
        break;
      case "glucose":
        {
          yAxis.max = 200;
          yAxis.min = 50;
       
         title.text="Glucose Levels (mmol/L)";

         var range2 = yAxis.axisRanges.create();
         range2.value = 6;
         range2.grid.stroke = am4core.color("#A96478");
         range2.grid.strokeWidth = 2;
         range2.grid.strokeOpacity = 1;
         range2.label.fill = range2.grid.stroke;
          c1.data = dat

          // Create series
          var lineSeries = c1.series.push(new am4charts.LineSeries());
          lineSeries.dataFields.valueY = "vitalvalue";
          lineSeries.dataFields.dateX = "date";
          lineSeries.name = "glucose";
          lineSeries.strokeWidth = 2;
          // lineSeries.tensionX = 0.8;
          // lineSeries.tensionY = 1;

          var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
          circleBullet.circle.stroke = am4core.color("#fff");
          circleBullet.circle.strokeWidth = 0.5;
          circleBullet.circle.scale = 0.5
          circleBullet.tooltipText = "{date}: {vitalvalue}mmol/L";

          var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
          //labelBullet.label.text = "{vitalvalue}";
          labelBullet.label.dy = -20;
          //labelBullet.label.fontFamily = 'Roboto'
        }
        break;
    }
  }

  return (
    <div className={`${style.mainDiv} pb-5`}>
      <TopBar  onShow={showDrawer}/>
      {/* <div className="box">
        <div className={`${style.toggebtn} d-block d-xl-none`}>
          <i>
            <FontAwesomeIcon
              className="mr-1"
              icon={faEquals}
              onClick={showDrawer}
            />
          </i>
        </div>
        <div className="d-block d-xl-none">
          <p className="pageInfoM">Vitals</p>
          <h5 className="h5NewM">Dashboard / Vitals</h5>
        </div>
        <div className="d-none d-xl-block">
          <p className="pageInfo">Vitals</p>
          <h5 className="h5New">
            <Link style={{ color: "white" }} to="/dashboard">
              Dashboard
            </Link>{" "}
            / Vitals
          </h5>
        </div>
      </div> */}
      <div style={{height:'100vh'}}>
        <div
          className="content"
          style={{ backgroundColor: "#f1f5f9",height: '100%' }}
        >
          <div className={style.container} style={{ height: '100%' }}>
            <div className="row" style={{ height: '100%' }}>
              <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`}
              >
                <DashboardSidebar />
              </div>
              <Drawer
                closable={false}
                width={300}
                placement="left"
                onClose={onClose}
                visible={visible}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>
              <div className="col-lg-12 col-xl-10">
              <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Vitals", link: "" },
                    
                  ]}
                  pageTitle="Vitals"
                />
                <div className={`row ${style.div_row_new}`}>
                  <div className="col-lg-3">
                    <div className="form-group" style={{ height: "35px" }}>
                      <Select
                        showSearch
                        placeholder="Select Patient"
                        className={style.selectMember}
                        optionFilterProp="children"
                        size="large"
                        style={{ width: "100%" }}
                        defaultActiveFirstOption
                        onChange={(e) => {
                          console.log(e);
                          setSelFamilyMember(e)
                        }}
                        // onChange={handleChangeFamilyMemeber}
                        value={selFamilyMember}
                      >
                        <Option
                          value={user?.id}
                          key='own' 
                        >
                          {user?.FirstName} {user?.LastName}
                        </Option>
                        {familyMembers ? (
                          <>
                            {familyMembers?.length != 0 ? (
                              <>
                                {familyMembers?.map((itemm, index) => (
                                  <>
                                    {itemm.userName != null ? (
                                      <Option
                                        key={itemm?.familymemberid}
                                        value={itemm?.familymemberid}
                                      >
                                        {itemm?.userName}
                                      </Option>
                                    ) : null}
                                  </>
                                ))}
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-3 col-xl-3 ">
                    <div className={style.divList}>
                      {/* {modalForWhich} */}
                      <button
                        className={forHistory == "bp" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Blood Pressure");
                        }}
                      >
                        <img
                          height="25"
                          src="../Images/blood-pressure.png"
                          alt=""
                        />
                        <label className={`${style.lbl_head}`}>
                          Blood Pressure
                        </label>
                      </button>
                      <button
                        className={forHistory == "heartrate" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Pulse Rate");
                        }}
                      >
                        <img
                          height="25"
                          src="../Images/pulse-rate.png"
                          alt=""
                        />
                        <label className={`${style.lbl_head}`}>
                          Pulse Rate
                        </label>
                      </button>
                      <button
                        className={forHistory == "spo2" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Oxygen Saturation");
                        }}
                      >
                        <img
                          height="25"
                          src="../Images/oxygen-saturation.png"
                          alt=""
                        />
                        <label className={`${style.lbl_head}`}>
                          Oxygen Saturation
                        </label>
                      </button>
                      <button
                        className={forHistory == "temp" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Temperature");
                        }}
                      >
                        <img
                          height="25"
                          src="../Images/temperature.png"
                          alt=""
                        />
                        <label className={`${style.lbl_head}`}>
                          Temperature
                        </label>
                      </button>
                      <button
                        className={forHistory == "respiratory" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Respiratory Rate");
                        }}
                      >
                        <img height="25" src="../Images/lungs.png" alt="" />
                        <label className={`${style.lbl_head}`}>
                          Respiratory Rate
                        </label>
                      </button>
                      <button
                        className={forHistory == "glucose" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Glucose Levels");
                        }}
                      >
                        <img
                          height="25"
                          src="../Images/glucose.png"
                          alt=""
                        />
                        <label className={`${style.lbl_head}`}>
                          Glucose Levels
                        </label>
                      </button>
                      <button
                        className={forHistory == "Mood" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Mood");
                        }}
                      >
                        <img height="30" src="../Images/mood.png" alt="" />
                        <label className={`${style.lbl_head}`}>Mood</label>
                      </button>
                      <button
                        className={forHistory == "Social" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Social");
                        }}
                      >
                        <img height="25" src="../Images/social.png" alt="" />
                        <label className={`${style.lbl_head}`}>
                          Social
                        </label>
                      </button>
                      <button
                        className={forHistory == "Anxiety" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Anxiety");
                        }}
                      >
                        <img height="25" src="../Images/anxiety.png" alt="" />
                        <label className={`${style.lbl_head}`}>
                          Anxiety
                        </label>
                      </button>
                      <button
                        className={forHistory == "Mobility" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Mobility");
                        }}
                      >
                        <img height="25" src="../Images/mobility.jpg" alt="" />
                        <label className={`${style.lbl_head}`}>
                          Mobility
                        </label>
                      </button>
                      <button
                        className={forHistory == "Pain" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Pain");
                        }}
                      >
                        <img height="25" src="../Images/pain.png" alt="" />
                        <label className={`${style.lbl_head}`}>Pain</label>
                      </button>
                      <button
                        className={forHistory == "Appetite" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Appetite");
                        }}
                      >
                        <img height="25" src="../Images/appetite.jpg" alt="" />
                        <label className={`${style.lbl_head}`}>
                          Appetite
                        </label>
                      </button>
                      <button
                        className={forHistory == "Sleep" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Sleep");
                        }}
                      >
                        <img height="25" src="../Images/sleep.jpg" alt="" />
                        <label className={`${style.lbl_head}`}>Sleep</label>
                      </button>
                      <button
                        className={forHistory == "Rashes" ? style.div_item_selected : style.div_item}
                        onClick={() => {
                          BtnClicked("Rashes");
                        }}
                      >
                        <img height="25" src="../Images/rashes.jpg" alt="" />
                        <label className={`${style.lbl_head}`}>
                          Rashes
                        </label>
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-9 col-xl-9 ">
                    <div className={`${style.div_btns} mb-2 mt-2`}>
                      <div className={style.div_Blue}>
                        {/* <label>{modalTitle != 'Emotional and Physical Health' ? modalTitle : emojis}</label> */}
                      </div>
                      <Button
                        className={style.div_orange}
                        onClick={() => openModal()}
                        disabled={selFamilyMember == null}
                      >
                        <label>Add New</label>
                      </Button>
                    </div>
                    <div className={style.wrapper}>
                      {forHistory == 'bp' || forHistory == 'heartrate' || forHistory == 'spo2' || forHistory == 'temp' || forHistory == 'respiratory' ||
                        forHistory == 'glucose'
                        ?
                        <div className="card">
                          <div className="card-header ">
                            <button
                              type="button"
                              className="button-Edit-Icon  mr-2 mb-2"
                              onClick={() => {
                                submitDate(1,forHistory)
                              }}
                            >
                              1d
                            </button>
                            <button
                              type="button"
                              className="button-Edit-Icon mr-2 mb-2"
                              onClick={() => {
                                submitDate(7,forHistory)
                              }}
                            >
                              7d
                            </button>
                           
                            <button
                              type="button"
                              className="button-Edit-Icon mr-2 mb-2"
                              onClick={() => {
                                submitDate(90,forHistory)
                              }}
                            >
                              3M
                            </button>
                            <button
                              type="button"
                              className="button-Edit-Icon mr-2 mb-2"
                              onClick={() => {
                                submitDate(6,forHistory)
                              }}
                            >
                              6M
                            </button>
                          </div>

                          <div className="card-body">
                            <div id="chartdiv" style={{ width: '100%', height: '500px' }} />
                          </div>
                        </div> : null}
                    </div>

                    <div className={style.wrapper}>
                      {dataSetAllForVitalHistory?.length != 0 ? (
                        <Table
                          style={{ marginTop: 40 }}
                          size="small"
                          pagination={{
                            defaultPageSize: 10,
                            pageSizeOptions: ["30", "40"],
                            showSizeChanger: false,
                          }}
                          columns={vitalsListColumn}
                          dataSource={dataSetAllForVitalHistory}
                          scroll={{ x: 600 }}
                          rowKey={(obj) => obj.id}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={modalInput}
        footer={null}
        onOk={closeModal}
        onCancel={closeModal}
        width={modalForWhich == "emoji" ? 400 : 350}
        title={modalTitle}
      >
        {modalForWhich == "Blood Pressure" ? (
          <Form
            form={formBP}
            validateTrigger="onSubmit"
            onFinish={handleSubmitBp}
            onFinishFailed={handleFailedSubmitBp}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div>
                  <img
                    style={{ marginBottom: 20 }}
                    height="40"
                    src="../Images/blood-pressure.png"
                    alt=""
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className={`form-group`} style={{ marginBottom: 2 }}>
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="SYS BP"
                        name="BloodPressureSys"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Blood Pressure SYS!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          min={90}
                          max={180}
                          onKeyDown={(e) => {
                            if (
                              (formValues?.BloodPressureSys?.length >= 3 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          type="number"
                          className={`${style.inputbox}`}
                          name="BloodPressureSys"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-1">
                    <div className={`${style.lbl_slash}`}>
                      <label style={{ marginTop: 20 }}>/</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className={`form-group`} style={{ marginBottom: 2 }}>
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="DYS BP"
                        name="BloodPressureDys"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Blood Pressure DYS!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          min={60}
                          max={120}
                          onKeyDown={(e) => {
                            if (
                              (formValues?.BloodPressureDys?.length >= 3 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          type="number"
                          className={`${style.inputbox}`}
                          name="BloodPressureDys"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className={`${style.lbl_slash}`}>
                      <label style={{ marginTop: 20 }}>mmHg</label>
                    </div>
                  </div>
                </div>

                <Form.Item
                  name="medication"
                  style={{ marginBottom: 0 }}
                  label="Medication"
                  rules={[
                    {
                      required: true,
                      message: "Please select medication!",
                    },
                  ]}
                  help=""
                >
                  <Radio.Group value={medi} onChange={onChangeMedication}>
                    <Radio value="taken">Taken</Radio>
                    <Radio value="not taken">Not Taken</Radio>
                  </Radio.Group>
                </Form.Item>

                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                <div className="row">
                  <div className="col-12">
                    <div className={`form-group `}>
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="Notes"
                        name="bpnotes"
                      >
                        <TextArea
                          rows={4}
                          placeholder="Enter Note..."
                          autoComplete="off"
                          className={`${style.inputbox}`}
                          name="bpnotes"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding blood pressure info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        {modalForWhich == "Pulse Rate" ? (
          <Form
            form={formPulse}
            validateTrigger="onSubmit"
            onFinish={handleSubmitPulse}
            onFinishFailed={handleFailedSubmitPulse}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div className="row">
                  <div className="col-2">
                    <div className={`${style.div_icon_white}`}>
                      <img
                        style={{ marginBottom: 20 }}
                        height="35"
                        src="../Images/pulse-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-4 pr-0">
                    <div className={`form-group `}>
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="Pulse Rate"
                        name="PulseRate"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Pulse Rate!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          min={40}
                          max={120}
                          onKeyDown={(e) => {
                            if (
                              (formValues?.PulseRate?.length >= 3 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          type="number"
                          autoComplete="off"
                          className={`${style.inputbox}`}
                          name="PulseRate"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-6 pl-0">
                    <div
                      className={`${style.lbl_slash}`}
                      style={{ alignItems: "flex-start" }}
                    >
                      <label style={{ marginTop: 20 }}>bpm</label>
                    </div>
                  </div>
                </div>

                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}

                <div className={`form-group`}>
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Notes"
                    name="pulsenotes"
                  >
                    <TextArea
                      rows={4}
                      placeholder="Enter Note..."
                      autoComplete="off"
                      className={`${style.inputbox}`}
                      name="pulsenotes"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding Pulse Rate info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        {modalForWhich == "Oxygen Saturation" ? (
          <Form
            form={formOxygen}
            validateTrigger="onSubmit"
            onFinish={handleSubmitOxygen}
            onFinishFailed={handleFailedSubmitOxygen}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div className="row">
                  <div className="col-2">
                    <div className={`${style.div_icon_white}`}>
                      <img
                        style={{ marginBottom: 20 }}
                        height="40"
                        src="../Images/oxygen-saturation.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-5 pr-0">
                    <div className="form-group">
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="Oxygen Saturation"
                        name="OxygenLevels"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Oxygen Saturation!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          min={90}
                          max={100}
                          onKeyDown={(e) => {
                            if (
                              (formValues?.OxygenLevels?.length >= 3 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          type="number"
                          className={`${style.inputbox}`}
                          name="OxygenLevels"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div
                      className={`${style.lbl_slash}`}
                      style={{ alignItems: "flex-start" }}
                    >
                      <label style={{ marginTop: 20 }}>% SpO2</label>
                    </div>
                  </div>
                </div>
                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                <div className={`form-group `}>
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Notes"
                    name="oxygennotes"
                  >
                    <TextArea
                      rows={4}
                      autoComplete="off"
                      className={`${style.inputbox}`}
                      name="oxygennotes"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding oxygen saturation info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        {modalForWhich == "Temperature" ? (
          <Form
            form={formTemp}
            validateTrigger="onSubmit"
            onFinish={handleSubmitTemp}
            onFinishFailed={handleFailedSubmitTemp}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div className="row">
                  <div className="col-2">
                    <div className={`${style.div_icon_white}`}>
                      <img
                        style={{ marginBottom: 20 }}
                        height="40"
                        src="../Images/temperature.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-5 pr-0">
                    <div className={`form-group `}>
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="Temperature"
                        name="Temperature"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Temperature!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          onKeyDown={(e) => {
                            if (
                              (formValues?.Temperature?.length >= 6 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          step="any"
                          min={95}
                          max={104}
                          autoComplete="off"
                          type="number"
                          className={`${style.inputbox}`}
                          name="Temperature"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div
                      className={`${style.lbl_slash}`}
                      style={{ alignItems: "flex-start" }}
                    >
                      <label style={{ marginTop: 20 }}>(F)</label>
                    </div>
                  </div>
                </div>
                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                <div className={`form-group `}>
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Notes"
                    name="tempnotes"
                  >
                    <TextArea
                      rows={4}
                      autoComplete="off"
                      className={`${style.inputbox}`}
                      name="tempnotes"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding temperature info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        {modalForWhich == "Respiratory Rate" ? (
          <Form
            form={formRes}
            validateTrigger="onSubmit"
            onFinish={handleSubmitRes}
            onFinishFailed={handleFailedSubmitRes}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div className="row">
                  <div className="col-2">
                    <div className={`${style.div_icon_white}`}>
                      <img
                        style={{ marginBottom: 20 }}
                        height="30"
                        src="../Images/lungs.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-5 pr-0">
                    <div className={`form-group `}>
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="Respiratory Rate"
                        name="RespiratoryRate"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Respiratory Rate!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          onKeyDown={(e) => {
                            if (
                              (formValues?.RespiratoryRate?.length >= 3 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          min={12}
                          max={25}
                          autoComplete="off"
                          type="number"
                          className={`${style.inputbox}`}
                          name="RespiratoryRate"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div
                      className={`${style.lbl_slash}`}
                      style={{ alignItems: "flex-start" }}
                    >
                      <label style={{ marginTop: 20 }}>Rpm</label>
                    </div>
                  </div>
                </div>

                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                <div className={`form-group `}>
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Notes"
                    name="resnotes"
                  >
                    <TextArea
                      rows={4}
                      autoComplete="off"
                      className={`${style.inputbox}`}
                      name="resnotes"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding respiratory rate info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        {modalForWhich == "Glucose Levels" ? (
          <Form
            form={formGlucose}
            validateTrigger="onSubmit"
            onFinish={handleSubmitGlucose}
            onFinishFailed={handleFailedSubmitGlucose}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div className="row">
                  <div className="col-2">
                    <div className={`${style.div_icon_white}`}>
                      <img
                        style={{ marginBottom: 20 }}
                        height="40"
                        src="../Images/glucose.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-5 pr-0">
                    <div className="form-group">
                      <Form.Item
                        className={`${style.inputboxContainer}`}
                        label="Glucose Levels"
                        name="GlucoseLevels"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Glucose Levels!",
                          },
                        ]}
                        help=""
                      >
                        <Input
                          onKeyDown={(e) => {
                            if (
                              (formValues?.GlucoseLevels?.length >= 6 &&
                                e?.code != "Backspace") ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          step="any"
                          min={70}
                          max={199}
                          type="number"
                          className={`${style.inputbox}`}
                          name="GlucoseLevels"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div
                      className={`${style.lbl_slash}`}
                      style={{ alignItems: "flex-start" }}
                    >
                      <label style={{ marginTop: 20 }}>mmol/L</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Current Status"
                    name="cstatus"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter current status!",
                      },
                    ]}
                    help=""
                  >
                    <Select
                      showSearch
                      placeholder="Select current status"
                      className={style.selectMember}
                      optionFilterProp="children"
                      size="large"
                      style={{ width: "100%" }}
                      onChange={handleChangeGlucose}
                    >
                      <>
                        <Option key={"fasting"} value={"fasting"}>
                          Fasting
                        </Option>
                        <Option key={"premeal"} value={"premeal"}>
                          Pre-meal
                        </Option>
                        <Option key={"postmeal"} value={"postmeal"}>
                          Post-meal
                        </Option>
                      </>
                    </Select>
                  </Form.Item>
                </div>
                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}
                <div className={`form-group `}>
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Notes"
                    name="glunotes"
                  >
                    <TextArea
                      rows={4}
                      autoComplete="off"
                      className={`${style.inputbox}`}
                      name="glunotes"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding glucose levels info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        {modalForWhich == "emoji" ? (
          <Form
            form={formRate}
            validateTrigger="onSubmit"
            onFinish={handleSubmitMood}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-xl-12 ">
                <div className="row">
                  <div className="col-12 text-center">
                    <img
                      src={"../Images/" + (rateCount) + ".png"}
                      height="50"
                      alt=""
                      style={{ marginBottom: 15 }}
                    />
                    <div className={`${style.div_flex_column}`}>
                      <Form.Item name="rating" style={{ marginBottom: 0 }}>
                        <Radio.Group value={moodValue} onChange={onChange}>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <div className={`${style.div_flex_row}`}>
                        <label
                          className={`${style.lbl_sa}`}
                          style={{ paddingLeft: 5 }}
                        >
                          {lowestValue}
                        </label>
                        <label className={`${style.lbl_sa}`}>
                          {highestValue}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {changed != true ? (
                  <>
                    {errors?.map((item, index) => {
                      return (
                        <div className={`${style.divError}`}>
                          <label>{item}</label>
                        </div>
                      );
                    })}
                  </>
                ) : null}

                <div className={`form-group `}>
                  <Form.Item
                    className={`${style.inputboxContainer}`}
                    label="Notes"
                    name="emojinotes"
                  >
                    <TextArea
                      rows={4}
                      autoComplete="off"
                      className={`${style.inputbox}`}
                      name="emojinotes"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>

                <div className={style.div_btn}>
                  {loader ? (
                    <div className="spinner">
                      <Spin tip="Adding info.."></Spin>
                    </div>
                  ) : (
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        ) : null}
      </Modal>
      <div className="footer">
              <Footer/>
            </div>
    </div>
  );
};
export default Vitals;
