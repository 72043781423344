import { Modal, Tabs, Button, notification, Spin } from "antd";
import style from "./style.module.scss";
import {
  VideoCameraFilled,
  PhoneFilled,
  CustomerServiceFilled,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useState, useEffect } from "react";
import { UPDATEDGET, UPDATEDPOST, UPDATEDPUT } from "../../services/common.api";
// import { Editor } from 'primereact/editor'
import { sanitize } from "dompurify";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faHistory,
  faNotesMedical,
  faUser,
  faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import { faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { base64toBlob } from "../../utils/common";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { IMG01 } from "./img";
import { s3GetPrivate } from "services/s3fileUpload";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const PatientDetail = ({ title, open, close, appointmentId, type }) => {
  const { TabPane } = Tabs;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [doctorNoteEditMode, setDoctorNoteEditMode] = useState(true);
  const [patientNoteEditMode, setPatientNoteEditMode] = useState(true);
  const [todayDoctorNote, setTodayDoctorNote] = useState([]);
  const [todayPatientNote, setTodayPatientNote] = useState([]);
  const [doctorNote, setDoctorNote] = useState();
  const [patientNote, setPatientNote] = useState();
  const [patientData, setPatientData] = useState([] as any);
  const [prescription, setPrescription] = useState([] as any);
  const [doctorPreviousNotes, setDoctorPreviousNotes] = useState([]);
  const [patientPreviousNotes, setPatientPreviousNotes] = useState([]);
  const [doctorDetails, setDoctorDetails] = useState([] as any);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [insuranceData, setInsuranceData] = useState(undefined);
  const [prescribedTest, setPrescribedTest] = useState(undefined);
  const [prescribedNotes, setPrescribedNotes] = useState(undefined);
  const [loaderForInvoice, setLoaderForInvoice] = useState(false);
  const [loaderForPN, setLoaderForPN] = useState(false);
  const [loaderForPT, setLoaderForPT] = useState(false);
  const [loaderForPH, setLoaderForPH] = useState(false);
  const [itemIndex,setItemIndex] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null)
  const [isOpenPdfModal, setIsOpenPdfModal] = useState(false)
  const [testUrl, setTestUr] = useState(null)

  const userData = useSelector((state: any) => state.userReducer.user);
  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );
  const [PdfString, setPdfString] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userAgent = navigator.userAgent;

  const { id } = useParams();

  console.log("appointmentIdappointmentId",appointmentId);
  
  console.log("prescribedNotesprescribedNotes", prescribedNotes);


  const getPatientDetails = async () => {

		try {

      let aid = currentAppointmentInfo?.appoinment_id || appointmentId?.appoinment_id || appointmentId?.appointment_id || appointmentId?.id

      console.log("PAODSKHDJHS_)_)_",aid, currentAppointmentInfo);
      

			const appointPatient = await UPDATEDGET(`appointmentbyid/${aid}`);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Patient Detail Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "apiendpoint": `appointmentbyid/${aid}`,
        "ipAddress": localStorage.getItem('ipAddress'),
        "requestParameters": {},
        "responsestatus": appointPatient?.status,
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Fetch Appointment Detail By Appointment ID - Patient Detail Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
      }
  
      logEvents(logBody);
      
      

			console.log(
				appointPatient?.data?.body,
				'patientdetatattatattatattaattatatatatatatattatatatatat',
			)
			await setPatientData(appointPatient?.data?.body)
			
		} catch (error) {
			console.log('error', error)
		}
	}

  const saveDoctorNote = (noteType, noteId) => {

    

    let noteData;
    if (noteType === "DOCTOR") {
      noteData = doctorNote;
    } else if (noteType === "PATIENT") {
      noteData = patientNote;
    }
    if (doctorNote || patientNote) {
      if (noteId) {
        const updatePaylod = {
          notes: noteData?.notes || "<p>Please enter note</p>",
          id: noteId,
        };
        try {
          UPDATEDPUT("note", updatePaylod).then((response) => {

            let rawLogs = {
              "userIdentity": {
                "loggedinuser": `${userData?.Email}`,
                "role":`${userData?.role}`,
                "createdate":new Date().toISOString()
              },
              "action": "UPDATE",
              "source": "Patient Detail Component",
              "sourceIPAddress": "3.99.184.26",
              "userAgent": userAgent,
              "ipAddress": localStorage.getItem('ipAddress'),
              "apiendpoint": "note",
              "requestParameters": updatePaylod,
              "responsestatus": response?.status
            }
        
            let logBody = {
              // message: `User - ${userData?.sub} - Save Note - Patient Detail Component`,
              message: rawLogs,
              logGroupName: "ONRx-Patient-Portal",
              logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
            }
        
            logEvents(logBody);

            
            

            if (response?.data?.statusCode === 200) {
              if (noteType === "DOCTOR") {
                setDoctorNoteEditMode(false);
              } else {
                setPatientNoteEditMode(false);
              }
              getPreiviousNotes();
              // notification.success({
              //   message: 'Note has been updated successfully.',
              // })
            } else {
              // notification.error({
              //   message: 'Error while update note.',
              // })
            }
          });
        } catch (error) {
          // notification.error({
          //   message: 'Error while update note.',
          // })
        }
      } else {
        const paylod = {
          notetype: noteType,
          createdate: Date.now(),
          createuserid: 1,
          notes: noteData?.notes || "<p>Test</p>",
          appointmentid: id,
        };
        try {

          

          UPDATEDPOST("/note", paylod).then((response) => {

            let rawLogs = {
              "userIdentity": {
                "loggedinuser": `${userData?.Email}`,
                "role":`${userData?.role}`,
                "createdate":new Date().toISOString()
              },
              "action": "INSERT",
              "source": "Patient Detail Component",
              "sourceIPAddress": "3.99.184.26",
              "userAgent": userAgent,
              "ipAddress": localStorage.getItem('ipAddress'),
              "apiendpoint": "/note",
              "requestParameters": paylod,
              "responsestatus": response.status
            }
  
            let logBody = {
              // message: `User - ${userData?.sub} - Create Note - Patient Detail Component`,
              message: rawLogs,
              logGroupName: "ONRx-Patient-Portal",
              logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
            }
        
            logEvents(logBody);

            if (response?.data?.statusCode === 200) {
              if (noteType === "DOCTOR") {
                setDoctorNoteEditMode(false);
              } else {
                setPatientNoteEditMode(false);
              }
              getPreiviousNotes();
              // notification.success({
              //   message: 'Note has been saved successfully.',
              // })
            } else {
              // notification.error({
              //   message: 'Error while save note.',
              // })
            }
          });
        } catch (error) {
          // notification.error({
          //   message: 'Error while save note.',
          // })
        }
      }
    } else {
      // notification.error({
      //   message: 'Please enter note.',
      // })
    }
  };

  const selectedDoctorDetails = async () => {

    

    console.log(appointmentId, "-=-=-=-=-=-=-=-=-=-===-=-=-=- in selected Detail doctor");
    console.log(appointmentId?.providerid);
    console.log(appointmentId?.doctor_id);
    console.log(appointmentId?.doctorid, "doctorid");

    if (appointmentId?.providerid !== undefined || appointmentId?.doctorid !== undefined) {
      try {
        setLoader(true);
        const data = await UPDATEDGET(`companyemployee/${appointmentId?.providerid || appointmentId?.doctorid}`);

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${userData?.role}`,
            "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "Patient Detail Component",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `companyemployee/${appointmentId?.providerid || appointmentId?.doctorid}`,
          "requestParameters": {},
          "responsestatus": data?.status
        }
    
        let logBody = {
          // message: `User - ${userData?.sub} - Fetch Doctor Detail of Appointment - Patient Detail Component`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
        }
    
        logEvents(logBody);

        console.log(data?.data?.[0], "doctor Details");
        setDoctorDetails(data?.data?.[0]);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        notification.warning({
          message: error.message,
        });
      }
    } else {
      try {
        setLoader(true);
        const data = await UPDATEDGET(`companyemployee/${appointmentId?.doctor_id || appointmentId?.doctorid}`);
        console.log(data?.data?.[0], "doctor Details");
        setDoctorDetails(data?.data?.[0]);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        notification.warning({
          message: error.message,
        });
      }
    }
  };

  const getPreiviousNotes = async () => {

    

    try {
      setTodayDoctorNote([]);
      setTodayPatientNote([]);

      console.log(appointmentId, "appointmentId")


      let aid;
      if (type === 'prescription') {
        aid = appointmentId?.appoinment_id || appointmentId?.appointment_id || appointmentId?.id
      } else {
        aid = appointmentId?.id
      }


      console.log(aid, "aiddidiidididd", type)

      const preiviousNotesData = await UPDATEDGET(
        `note?appoinmentId=${aid}`
      );

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Patient Detail Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `note?appoinmentId=${aid}`,
        "requestParameters": {},
        "responsestatus": preiviousNotesData?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Get Previous Note - Patient Detail Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
      }
  
      logEvents(logBody);

      console.log(preiviousNotesData.data?.body, "preiviousNotesData");
      const todayDate = moment(new Date()).format("MM/DD/YYYY");
      const notes = preiviousNotesData?.data?.body;
      const previousDoctorNotes = [];
      const previousPatientNotes = [];

      const todayDoctorNote = [];
      const todayPatientNote = [];
      // if (notes.toString() === 'Note type is required!') {
      notes.forEach(async (note) => {
        const noteDate = moment(note.createdate).format("MM/DD/YYYY");
        if (note.notetype === "DOCTOR") {
          if (todayDate === noteDate) {
            // await setTodayDoctorNote(note)
            await todayDoctorNote.push(note);

          } else {
            previousDoctorNotes.push(note);
          }
        } else if (note.notetype === "PATIENT") {
          if (todayDate === noteDate) {
            await todayPatientNote.push(note);
          } else {
            previousPatientNotes.push(note);
          }
        }
      });

      setTodayDoctorNote(todayDoctorNote);
      setTodayPatientNote(todayPatientNote);

      setDoctorPreviousNotes(previousDoctorNotes);
      setPatientPreviousNotes(previousPatientNotes);
      console.log(previousDoctorNotes, "doc note");
      console.log(previousPatientNotes, "PATIENT note");


      // }
    } catch (error) {
      // notification.warning({
      //   message: error.message,
      // })
    }
  };

  const changeTab = (noteType) => {
    if (noteType === "DOCTOR" || noteType === "PATIENT") {
      if (id) {
        // getPreiviousNotes(noteType)
      }
    }
  };

  // const getPatientDetails = async () => {
  //   try {
  //     const appointPatient = await UPDATEDGET(`appointment/${appointmentId}`)
  //     console.log('appointPatient: ', appointPatient)
  //     setPatientData(appointPatient?.data?.body)
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   getPreiviousNotes()
  // }

  const getInsuranceDetails = async () => {

    

    console.log(appointmentId, "appoint*-*-*-**-*-***-")
    try {
      const res = await UPDATEDGET(`insurance/${appointmentId?.patientid || appointmentId?.patient_id}`);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Patient Detail Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `insurance/${appointmentId?.patientid || appointmentId?.patient_id}`,
        "requestParameters": {},
        "responsestatus": res?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Fetch Insuarance Details - Patient Detail Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
      }
  
      logEvents(logBody);

      

      console.log(res?.data[0], "getInsuranceInfo");
      setInsuranceData(res?.data[0]);
    } catch (err) {
      console.log("error", err);
    }
  };
  const getPrescribedtest = async () => {

    

    console.log(appointmentId, "appoint*-*-*-**-*-***-")
    try {
      const res = await UPDATEDGET(`prescribedtest/${appointmentId.appoinment_id || appointmentId.appointment_id || appointmentId.id}`);


      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Patient Detail Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `prescribedtest/${appointmentId.appoinment_id || appointmentId.appointment_id || appointmentId?.id}`,
        "requestParameters": {},
        "responsestatus": res?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - Fetch Prescribed Test - Patient Detail Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
      }
  
      logEvents(logBody);
      

      setPrescribedTest(res?.data?.data);

      console.log(res?.data?.data, "prescribed note  data")
    } catch (err) {
      console.log("error", err);
    }
  };

  const getPrescription = () => {

    

    console.log(appointmentId, "appoint*-*-*-**-*-***- prescription", type)

    async function fetchMyAPI() {
      if (type == 'prescription') {
        let aidd;
        if (appointmentId.appoinment_id) {
          aidd = appointmentId.appoinment_id || appointmentId.id;
        } else {
          aidd = appointmentId.appointment_id || appointmentId.id;
        }

        if (aidd != null) {
          let prescription = await UPDATEDGET(
            `prescriptions?appoinmentId=` + aidd
          );

          let status = prescription.status


          prescription =  prescription?.data?.result || prescription?.data?.body  || []
 

          let rawLogs = {
            "userIdentity": {
              "loggedinuser": `${userData?.Email}`,
              "role":`${userData?.role}`,
              "createdate":new Date().toISOString()
            },
            "action": "ACCESS",
            "source": "Patient Detail Component",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": `prescriptions?appoinmentId=` + aidd,
            "requestParameters": {},
            "responsestatus": status
          }
      
          let logBody = {
            // message: `User - ${userData?.sub} - Fetch Prescription - Patient Detail Component`,
            message: rawLogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
          }
      
          logEvents(logBody);

      
          console.log(prescription, "prescription data ");

          if (prescription?.data?.body?.length == 0) {
            setPrescription([]);
          } else {
            let prescriptionData = [];
            let newData = prescription;
            newData?.forEach((element, index) => {
              element?.drugs.forEach((elementDrug) => {
                prescriptionData.push(elementDrug);
              });
              if (index == (newData?.length - 1)) {
                setPrescription(prescriptionData);
              }
            });
          }
        }
      } else {
        if (appointmentId?.id != null) {
          let prescription = await UPDATEDGET(
            `prescriptions?appoinmentId=` + appointmentId?.id
          );

          let status = prescription.status

          console.log(prescription, "=======================")

          prescription =  prescription?.data?.result || prescription?.data?.body  || []

          let rawLogs = {
            "userIdentity": {
              "loggedinuser": `${userData?.Email}`,
              "role":`${userData?.role}`,
              "createdate":new Date().toISOString()
            },
            "action": "ACCESS",
            "source": "Patient Detail Component",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": `prescriptions?appoinmentId=` + appointmentId?.id,
            "requestParameters": {},
            "responsestatus": status
          }
      
          let logBody = {
            // message: `User - ${userData?.sub} - Fetch Prescription - Patient Detail Component`,
            message: rawLogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
          }
      
          logEvents(logBody);

          console.log(prescription, "prescription data");
          if (prescription?.data?.body?.length == 0) {
            setPrescription([]);
          } else {
            let prescriptionData = [];
            let newData = prescription;
            newData?.forEach((element, index) => {
              element?.drugs.forEach((elementDrug) => {
                prescriptionData.push(elementDrug);
              });
              if (index == (newData?.length - 1)) {
                setPrescription(prescriptionData);
              }
            });
          }
        }
      }
    }
    fetchMyAPI();
  };

  const getPrescribedNotes = async (item) => {

    
    try {
      const paylod = {
        appointmentId: item.appoinment_id || item.appointment_id || item?.id,
      }
      UPDATEDPOST('generatenotespdf', paylod).then((response) => {

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${userData?.role}`,
            "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "Patient Detail Component",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": "generatenotespdf",
          "requestParameters": paylod,
          "responsestatus": response?.status
        }
    
        let logBody = {
          // message: `User - ${userData?.sub} - Fetch Prescribed Notes - Patient Detail Component`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
        }
    
        logEvents(logBody);

        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body))
          setPrescribedNotes(url)
          // setPdfString(url);
          // setIsModalVisible(true);
        } else if (response?.data?.statusCode === 401) {
          console.log("No note found for this appointment.");
          setPrescribedNotes(undefined)
        } else {
          notification.error({
            message: 'Error while save prescription.',
          })
        }
      })
    } catch (error) {
      notification.error({
        message: error.message,
      })
    }
  }

  const logEvents = async (data) => {
    console.log(data, "LOG EVENTTT")
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {

    if (open == true) {

      selectedDoctorDetails();
      getPreiviousNotes();

      getPatientDetails();

      // if (currentAppointmentInfo?.appointmentRequestType == 'familyMember') {
      //   setPatientData(currentAppointmentInfo?.familyMember);
      // } else {
      //   setPatientData(userData);
      // }

      getPrescription();
      getInsuranceDetails();
      getPrescribedtest();
      getPrescribedNotes(appointmentId);
    }
  }, [open]);

  const showPdf = async (item) => {

    

    console.log("preId: ", item);
    console.log("preId: ", item.prescription_id);
    let preId = item.prescription_id;
    setLoaderForPH(true);
    try {
      const paylod = {
        prescriptionId: preId,
      };
      UPDATEDPOST("generatepdf", paylod).then((response) => {

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${userData?.role}`,
            "createdate":new Date().toISOString()
          },
          "action": "INSERT",
          "source": "Patient Detail Component",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": "generatepdf",
          "requestParameters": paylod,
          "responsestatus": response?.status
        }
    
        let logBody = {
          // message: `User - ${userData?.sub} - Generate Prescription PDF - Patient Detail Component`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
        }
    
        logEvents(logBody);

        console.log("response: ", response);
        setLoaderForPH(false);
        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body));
          console.log(url, "url pdf");
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error while save prescription.",
          });
        }
      });
    } catch (error) {
      setLoaderForPH(false);
      notification.error({
        message: error.message,
      });
    }
  };

  const showPdfNotes = async (item) => {

    

    setLoaderForPN(true);
    try {
      const paylod = {
        appointmentId: item.appoinment_id || item.appointment_id || item?.id,
      }
      UPDATEDPOST('generatenotespdf', paylod).then((response) => {

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${userData?.role}`,
            "createdate":new Date().toISOString()
          },
          "action": "INSERT",
          "source": "Patient Detail Component",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": "generatenotespdf",
          "requestParameters": paylod,
          "responsestatus": response.status
        }
    
        let logBody = {
          // message: `User - ${userData?.sub} - Generate Notes PDF - Patient Detail Component`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
        }
    
        logEvents(logBody);

        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body))
          setLoaderForPN(false);
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          setLoaderForPN(false);
          notification.error({
            message: 'Error while save prescription.',
          })
        }
      })
    } catch (error) {
      setLoaderForPN(false);
      notification.error({
        message: error.message,
      })
    }
  };

  const openFile = async (path) => {
    console.log(path, 'pathhhh')
    setLoaderForPT(true)
    try {
      const url = await s3GetPrivate(path);

      setTestUr(url);

      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      setLoaderForPT(false)
      // if (newWindow) newWindow.opener = null
    } catch (error) {
      setLoaderForPT(false)
      console.log('error: ', error)
      notification.warning({
        message: error.message,
      })
    }
  }


  const viewInvoice = async (dataa) => {

    

    console.log(dataa, "DATATATA");
    setLoaderForInvoice(true);
    // setLoaderId(index.claimid)
    const requestData = {
      claimId: dataa.claimid,
    }
    try {
      const claimData = await UPDATEDPOST(`invoice`, requestData)

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "INSERT",
        "source": "Patient Detail Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": "invoice",
        "requestParameters": requestData,
        "responsestatus": claimData?.status
      }
  
      let logBody = {
        // message: `User - ${userData?.sub} - View Invoice - Patient Detail Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Patient Detail Component`,
      }
  
      logEvents(logBody);

      const fileUrl = URL.createObjectURL(base64toBlob(claimData.data.pdf))
      setPdfUrl(fileUrl);
      setLoaderForInvoice(false);
      setIsOpenPdfModal(true)
      // setLoader(true)

    } catch (error) {
      setLoaderForInvoice(false);
      notification.warning({
        message: error.message,
      })
    } finally {
      setLoaderForInvoice(false);
      // setLoader(false)
    }
  }

  console.log('patientData ahiya aave che', patientData);
  console.log('userdata ahiya aave che', userData);


  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 10) {
      return phoneNumber; // Return as is if not a valid 10-digit phone number
    }

    const areaCode = phoneNumber.substring(0, 3);
    const firstPart = phoneNumber.substring(3, 6);
    const secondPart = phoneNumber.substring(6, 10);

    return `${areaCode} ${firstPart} ${secondPart}`;
  }

  return (
    <div>
      <Modal
        title={title}
        visible={open}
        footer={null}
        onOk={close}
        onCancel={close}
        width={1300}
        centered
      >
        <div className="content" style={{ paddingTop: 0, }}>
          <div className="p-4">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                  <FontAwesomeIcon icon={faUser} className="mr-3" />
                  <h4 className="m-0 text-white" >Patient Details</h4>
                </div>
              </div>
            </div>
            <div className="row mt-2 ml-1">
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Name : </span>
                    {patientData?.patientData?.FirstName}
                    {/* {currentAppointmentInfo?.appointmentRequestType ==
                      "familyMember" ? (
                      <span>{patientData?.userName}</span>
                    ) : (
                      <span>
                        {patientData?.FirstName} {patientData?.LastName}
                      </span>
                    )} */}
                  </p>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>
                      Insurance Service Number :{" "}
                    </span>
                    <span>{patientData?.Insurancesvcnum || "NA"}</span>
                  </p>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>DOB : </span>
                    {/* {currentAppointmentInfo?.appointmentRequestType !=
                      "familyMember" ? (
                      <span>
                        {moment(patientData?.DOB).format("YYYY-MM-DD") || ""}{" "}
                      </span>
                    ) : (
                      <span>
                        {moment(patientData?.dateOfBirth).format(
                          "YYYY-MM-DD"
                        ) || ""}{" "}
                      </span>
                    )} */}
                    {patientData?.patientData?.DOB
                      ? moment(patientData?.patientData?.DOB)
                        .utc()
                        .format('YYYY-MM-DD')
                      : 'N/A'}{' '}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Gender : </span>
                    <span style={{ textTransform: 'capitalize' }}>
                      {patientData?.patientData?.gender || 'N/A'}
                    </span>
                    {/* <span>{patientData?.gender || "Male"}</span> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="row ml-1">
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Family Physician : </span>
                    {/* <span>{patientData?.familyphysician || "NA"}</span> */}
                    <span>{patientData?.patientData?.familyphysician || 'N/A'}</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Phone Number : </span>
                    <span>
                      {/* {'+1 ' + formatPhoneNumber(patientData?.Phone ||
                        userData?.phoneNumber ||
                        "+1 11255455")}{" "} */}
                      {/* {patientData?.Phone
																			? '+1' +' '+ userData?.phoneNumber
																			: '+1 11255455'}{' '} */}

                      {patientData?.patientData?.phoneNumber
                        ? '+1' + ' ' + patientData?.patientData?.phoneNumber
                        : 'N/A'}{' '}

                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Province : </span>
                    <span>{patientData?.patientData?.State || 'N/A'}</span>
                    {/* <span>
                      {patientData?.province ||
                        userData?.State}
                    </span> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="row ml-1">
              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>Address : </span>
                    {/* {currentAppointmentInfo?.appointmentRequestType !=
                      "familyMember" ? (
                      <span>
                        {patientData?.Address1 || "N/A"}
                      </span>
                    ) : (
                      <span>{patientData?.address || "N/A"}</span>
                    )} */}

                  <span>{patientData?.patientData?.Address1}</span>

                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ml-0">
                  <p>
                    <span className={style.text_bold}>OHIP : </span>

                    {/* <span>{currentAppointmentInfo?.Insurance?.insurance_number || "NA"} - {currentAppointmentInfo?.Insurance?.insurance_vc || "NA"} </span> */}
                    {insuranceData ? (
                      <span>
                        {insuranceData?.insurance_number} -{" "}
                        {insuranceData?.insurance_vc}{" "}
                      </span>
                    ) : (
                      "NA"
                    )}

                    {/* <span>{currentAppointmentInfo?.Insurance?.ohipNumber || "NA"} - {currentAppointmentInfo?.Insurance?.insurance_vc || "NA"} </span> */}
                    {/* <span>{currentAppointmentInfo?.Insurance?.insurance_number || "NA"} - {currentAppointmentInfo?.Insurance?.insurance_vc || "NA"} </span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Doctor List */}

        {loader ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            <div className="content" style={{ paddingTop: 0 }}>
              <div className="p-4">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                      <FontAwesomeIcon icon={faUser} className="mr-3" />
                      <h4 className="m-0" style={{ color: 'white' }}>Doctor Details</h4>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 ml-1">
                  {doctorDetails?.firstname == null &&
                    doctorDetails?.middlename == null &&
                    doctorDetails?.lastname == null ? null : (
                    <div className="col-md-4">
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Name : </span>
                          <span>
                            {doctorDetails?.firstname}{" "}
                            {doctorDetails?.middlename}{" "}
                            {doctorDetails?.lastname}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="col-md-4">
                    {doctorDetails?.gender == null ||
                      doctorDetails?.gender == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Gender : </span>
                          <span>{doctorDetails?.gender}</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="ml-0">
                      {doctorDetails?.department == null ? null : (
                        <p>
                          <span className={style.text_bold}>Department : </span>
                          <span>{doctorDetails?.department}</span>
                        </p>
                      )}
                    </div>
                  </div>


                  {/* <div className="col-md-4">
                    {doctorDetails?.postalcode == null ||
                      doctorDetails?.postalcode == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>
                            Postal code :{" "}
                          </span>
                          <span>{doctorDetails?.postalcode} </span>
                        </p>
                      </div>
                    )}
                  </div> */}
                </div>
                <div className="row ml-1">
                  {/* <div className="col-md-4">
                    {doctorDetails?.email == null ||
                      doctorDetails?.email == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Email : </span>
                          <span>{doctorDetails?.email}</span>
                        </p>
                      </div>
                    )}
                  </div> */}

                  <div className="col-md-4">
                    {doctorDetails?.address1 == null ||
                      doctorDetails?.address1 == "" ? null : (
                      <div className="ml-0">
                        <p>
                          <span className={style.text_bold}>Address : </span>
                          <span>
                            {doctorDetails?.address1}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>


                  <div className="col-md-4">
                    <div className="ml-0">
                      {doctorDetails?.phone == null ||
                        doctorDetails?.phone == "" ? null : (
                        <p>
                          <span className={style.text_bold}>
                            Phone Number :{" "}
                          </span>
                          <span>{'+1 ' + formatPhoneNumber(doctorDetails?.phone)} </span>

                          {/* {doctorDetails?.phone
																			? '+1' +' '+ doctorDetails?.phone
																			: '+1 11255455'}{' '} */}

                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ml-0">
                      {doctorDetails?.fax == null ? null : (
                        <p>
                          <span className={style.text_bold}>Fax : </span>
                          <span>{doctorDetails?.fax},</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-6">
              <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                <FontAwesomeIcon icon={faNotesMedical} className="mr-3" />
                <h4 className="m-0" style={{ color: 'white' }}>Patient Notes</h4>
              </div>
              {todayPatientNote.length ? (
                todayPatientNote.map((note) => (
                  <>
                    {(note?.notes != "null" && note?.notes != null) || (note?.document != 'null' && note?.document != null) ? (
                      <div className={`${style.previous_notes} ml-3`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(note?.notes != "null" && note?.notes != null ? note?.notes : ''),
                          }}
                          className={style.border_btm}
                        />


                        {note?.document && note?.document != 'null' && note.document != undefined ? (
                          <a
                            onClick={(e) => openFile(note?.document)}
                          >
                            <img
                              style={{ width: 20, marginRight: 15, marginBottom: 10 }}
                              src={IMG01}
                            />
                          </a>
                        ) : null}


                        <p className={style.mb_0}>
                          last update :{" "}
                          {moment(note?.createdate).format(
                            "YYYY-MM-DD H:mm:ss"
                          )}
                        </p>
                      </div>
                    ) : (
                      <>
                        {todayPatientNote.length == 1 ? (
                          <div className={`${style.icon_div} ml-2`}>
                            <p>No doctor notes found</p>
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ))
              ) : (
                <div className={`${style.icon_div} ml-2`}>
                  <p>No doctor notes found.</p>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                    <FontAwesomeIcon icon={faHistory} className="mr-3" />
                    <h4 className="m-0" style={{ color: 'white' }}>Prescription History</h4>
                  </div>
                  <div className="table-responsive">

                    {prescription?.length ? (
                      <table className="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Drug Name</th>
                            <th>Date</th>
                            <th className="text-center">View </th>
                          </tr>
                        </thead>
                        {prescription?.length > 0 ? (
                          <tbody>
                            {prescription?.map((data,index:any) => {
                              return (
                                <tr>
                                  <td>{data?.drug_name}</td>
                                  {/* <td>{item.drugs[0]?.drug_name}</td> */}
                                  <td>
                                    {moment(data?.createdate).format(
                                      `YYYY - MM - DD`
                                    )}
                                  </td>
                                  <td
                                    className="text-center"
                                    onClick={() => {
                                      setItemIndex(index)
                                      showPdf(data)}}
                                  >
                                    {
                                      loaderForPH && itemIndex === index ? (
                                        <div className="spinner">
                                          <Spin />
                                        </div>
                                      )
                                        : (
                                          <FontAwesomeIcon
                                            className="mr-1 iconn"
                                            icon={faEye}
                                          />
                                        )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : null}
                      </table>
                    ) : (
                      <div className={style.icon_div}>
                        <p>No prescription history found</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                    <FontAwesomeIcon icon={faHistory} className="mr-3" />
                    <h4 className="m-0" style={{ color: 'white' }}>Prescribed Tests</h4>
                  </div>
                  <div className="table-responsive">

                    {prescribedTest?.length ? (
                      <table className="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th className="text-center">View </th>
                          </tr>
                        </thead>
                        {prescribedTest?.length > 0 ? (
                          <tbody>
                            {prescribedTest?.map((data,index:any) => {
                              return (
                                <tr>
                                  <td>{data?.name}</td>
                                  <td
                                    className="text-center"
                                    onClick={() => 
                                      {
                                        setItemIndex(index)
                                        openFile(data?.pdf_url)
                                      }}
                                  >
                                    {
                                      loaderForPT && itemIndex === index ? (
                                        <div className="spinner">
                                          <Spin />
                                        </div>
                                      )
                                        : (
                                          <FontAwesomeIcon
                                            className="mr-1 iconn"
                                            icon={faEye}
                                          />
                                        )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : null}
                      </table>
                    ) : (
                      <div className={style.icon_div}>
                        <p>No prescribed test found.</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                    <FontAwesomeIcon icon={faNotesMedical} className="mr-3" />
                    <h4 className="m-0" style={{ color: 'white' }}>Prescribed Note</h4>
                  </div>
                  <div className="table-responsive">

                    {prescribedNotes !== undefined ? (
                      <table className="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Note</th>
                            <th className="text-center">View </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Prescribed Note</td>
                            {/* <td>{appointmentId?.note || appointmentId.appoinment_id?.note || appointmentId.appointment_id?.note }</td> */}
                            <td
                              className="text-center"
                              onClick={() => {
                                showPdfNotes(appointmentId)
                              }}
                            >
                              {
                                loaderForPN ? (
                                  <div className="spinner">
                                    <Spin />
                                  </div>
                                )
                                  : (
                                    <FontAwesomeIcon
                                      className="mr-1 iconn"
                                      icon={faEye}
                                    />
                                  )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <div className={style.icon_div}>
                        <p>No Prescribed note found.</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="pl-4 pr-4 pb-4">
          <div className="row">
            <div className="col-md-6">
              <div className={`${style.div_title_decorate_patient}`} style={{ backgroundColor: "var(--primary-bg-color-light)", color: 'white' }}>
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="mr-3" />
                <h4 className="m-0" style={{ color: 'white' }}>Invoice</h4>
              </div>
              <div className="table-responsive">

                {appointmentId.claimid != null && appointmentId?.isinvoicegen != 0 && appointmentId?.isinvoicegen != null ? (
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Invoice</th>
                        <th className="text-center">View </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#invoice {appointmentId.claimid}</td>
                        <td
                          className="text-center"
                          onClick={() => viewInvoice(appointmentId)}
                        >
                          {
                            loaderForInvoice ? (
                              <div className="spinner">
                                <Spin />
                              </div>
                            )
                              : (
                                <FontAwesomeIcon
                                  className="mr-1 iconn"
                                  icon={faEye}
                                />
                              )
                          }


                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div className={style.icon_div}>
                    <p>No Invoice geberated</p>
                  </div>
                )}

              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className={`${style.div_title_decorate_patient} text-white`} style={{ backgroundColor: "var(--primary-bg-color-light)" }}>
                    <FontAwesomeIcon icon={faHistory} className="mr-3" />
                    <h4 className="m-0" style={{ color: 'white' }}>Vitals Notes</h4>
                  </div>
                  <div className="table-responsive">

                    {patientData.vitalsnote ? (
                      <>
                      <div>
                        {patientData.vitalsnote}
                      </div>
                      </>
                    ) : (
                      <div className={style.icon_div}>
                        <p>No vitals notes found</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Modal>

      <Modal
        centered
        visible={isModalVisible}
        width={1000}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
        {/* <div style={{ height: "100%", width: '100%' }}> */}
        {/* <Viewer fileUrl={PdfString} /> */}
        {/* </div> */}
        {/* </Worker> */}

        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl={PdfString}
              plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>

      <Modal
        centered
        visible={pdfUrl?.length > 0 && isOpenPdfModal}
        width={1000}
        onCancel={() => {
          setPdfUrl(null)
          setIsOpenPdfModal(false)
        }}
      >
        <Worker
          workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
        // className={style.pdf_viever_padding}
        >
          <div style={{ height: '750px' }}>
            <Viewer fileUrl={pdfUrl} plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]} />
          </div>
        </Worker>
      </Modal>


      <Modal
        centered
        visible={testUrl}
        width={1000}
        onOk={() => setTestUr(null)}
        onCancel={() => {
          setTestUr(null)
        }}
      >
        <Worker
          workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
        // className={style.pdf_viever_padding}
        >
          <div style={{ height: '750px' }}>
            <Viewer fileUrl={testUrl} plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]} />
          </div>
        </Worker>
      </Modal>

    </div>
  );
};

export default PatientDetail;
